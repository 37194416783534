<template>
<GalleryOverView/>
</template>

<script>
import { GalleryOverView } from '@scantrust/st-gallery'
import '@scantrust/st-gallery/dist/index.css'

export default {
  name: 'Gallery',
  components: {
    GalleryOverView
  }
}
</script>

<style scoped lang="sass">

</style>
