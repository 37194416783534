import { render, staticRenderFns } from "./SimulatorSidebar.vue?vue&type=template&id=67c870c0&scoped=true"
import script from "./SimulatorSidebar.vue?vue&type=script&lang=js"
export * from "./SimulatorSidebar.vue?vue&type=script&lang=js"
import style0 from "./SimulatorSidebar.vue?vue&type=style&index=0&id=67c870c0&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67c870c0",
  null
  
)

export default component.exports