var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "8",
      "height": "16",
      "viewBox": "0 0 8 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "opacity": "0.8",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M4 0H0V16H4V10.6666H8V5.66663H4V0Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }