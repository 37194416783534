var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "14",
      "height": "11",
      "viewBox": "0 0 14 11",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "opacity": "0.8",
      "d": "M7 11L0.937823 0.499999L13.0622 0.5L7 11Z",
      "fill": "#F22A42"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }