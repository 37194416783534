var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', [!_vm.navbarHidden ? _c('router-view', {
    attrs: {
      "name": "navigation"
    }
  }) : _vm._e(), _c('v-main', [_c('div', {
    staticClass: "main-ctn",
    class: {
      'hide-nav': _vm.navbarHidden
    }
  }, [_c('router-multi-view', {
    staticClass: "wrapper",
    attrs: {
      "view-name": "tab"
    }
  })], 1)]), _c('v-snackbar', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function (_ref) {
        var attrs = _ref.attrs;
        return [_c('v-btn', _vm._b({
          attrs: {
            "color": "primary",
            "text": ""
          },
          on: {
            "click": function ($event) {
              return _vm.dissmissSnackBar();
            }
          }
        }, 'v-btn', attrs, false), [_vm._v(" " + _vm._s(_vm.$t('dismiss')) + " ")])];
      }
    }]),
    model: {
      value: _vm.snackBar.show,
      callback: function ($$v) {
        _vm.$set(_vm.snackBar, "show", $$v);
      },
      expression: "snackBar.show"
    }
  }, [_vm._v(" " + _vm._s(_vm.snackBar.message) + " ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }