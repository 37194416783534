<template>
  <v-text-field :value="color" @input="debounceUpdate" v-mask="mask" hide-details class="color-input ma-0 pa-0" solo>
    <template v-slot:append>
      <v-menu v-model="menu" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
        <template v-slot:activator="{ on }">
          <div :style="swatchStyle" v-on="on" />
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker @input="debounceUpdate" class="no-alpha" :value="color" flat />
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>

import utils from '@/utils/utils'

export default {
  name: 'ColorInput',
  props: ['color'],
  data () {
    return {
      mask: '!#XXXXXX',
      menu: false
    }
  },
  computed: {
    swatchStyle () {
      return {
        backgroundColor: this.color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        border: '1px solid black',
        borderRadius: this.menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    }
  },
  created () {
    this.debounceUpdate = utils.debounce(($event) => {
      if (!$event) return
      this.$emit('update:color', $event)
    }, 400, true)
  }
}

</script>

<style lang="sass" scoped>

.color-input
  width: 200px
  .no-alpha
    .v-color-picker__controls
      .v-color-picker__preview
        .v-color-picker__sliders
          .v-color-picker__alpha
            display: none
      .v-color-picker__edit
        .v-color-picker__input:last-child
          display: none

</style>
