var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "44",
      "height": "48",
      "viewBox": "0 0 44 48",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M38.4 4.8H28.356C27.372 2.016 24.732 0 21.6 0C18.468 0 15.828 2.016 14.844 4.8H4.8C2.148 4.8 0 6.948 0 9.6V43.2C0 45.852 2.148 48 4.8 48H38.4C41.052 48 43.2 45.852 43.2 43.2V9.6C43.2 6.948 41.052 4.8 38.4 4.8ZM21.6 4.8C22.92 4.8 24 5.868 24 7.2C24 8.532 22.92 9.6 21.6 9.6C20.28 9.6 19.2 8.532 19.2 7.2C19.2 5.868 20.28 4.8 21.6 4.8ZM21.6 14.4C25.572 14.4 28.8 17.628 28.8 21.6C28.8 25.584 25.572 28.8 21.6 28.8C17.628 28.8 14.4 25.584 14.4 21.6C14.4 17.628 17.628 14.4 21.6 14.4ZM36 43.2H7.2V39.84C7.2 35.04 16.8 32.4 21.6 32.4C26.4 32.4 36 35.04 36 39.84V43.2Z",
      "fill": "#FF7F00"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }