var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "759px"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.modalTitle))]), _c('div', {
    staticClass: "ma-4 mx-8 mb-0"
  }, [!_vm.hideQrCodeTypeSelection && !_vm.isCodeEditing ? _c('div', [_c('div', {
    staticClass: "title font-bold mb-2 text-uppercase"
  }, [_vm._v(" " + _vm._s(_vm.$t("qrcode_select_type")) + " ")]), _c('v-row', {
    staticClass: "pb-0"
  }, [_c('v-col', {
    staticClass: "mb-0 pb-0"
  }, [_c('v-select', {
    attrs: {
      "small": "",
      "dense": "",
      "items": _vm.codeTypeOptions,
      "items-text": "text",
      "items-value": "value",
      "outlined": ""
    },
    model: {
      value: _vm.qrCodeType,
      callback: function ($$v) {
        _vm.qrCodeType = $$v;
      },
      expression: "qrCodeType"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.isStandardCodeFormVisible && !_vm.isCodeEditing ? _c('v-card-text', {
    staticClass: "pt-0"
  }, [_c('div', {
    staticClass: "mb-6"
  }, [_vm._v(" " + _vm._s(_vm.$t("qrcode_how_many_codes")))]), _c('v-form', {
    staticClass: "mt-4",
    model: {
      value: _vm.isStandardCodeFormValid,
      callback: function ($$v) {
        _vm.isStandardCodeFormValid = $$v;
      },
      expression: "isStandardCodeFormValid"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "Quantity",
      "type": "number",
      "counter": "true",
      "counter-value": _vm.remainingQuota,
      "rules": _vm.quantityRules,
      "required": ""
    },
    model: {
      value: _vm.quantity,
      callback: function ($$v) {
        _vm.quantity = $$v;
      },
      expression: "quantity"
    }
  })], 1)], 1) : _vm._e(), _vm.isGS1CodeFormVisible || _vm.isCodeEditing ? _c('v-form', {
    ref: "form",
    staticClass: "pt-0",
    model: {
      value: _vm.isGs1CodeFormValid,
      callback: function ($$v) {
        _vm.isGs1CodeFormValid = $$v;
      },
      expression: "isGs1CodeFormValid"
    }
  }, [_c('v-row', {
    staticClass: "mt-0"
  }, [_c('v-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('v-label', {
    staticClass: "black--text"
  }, [_c('span', {
    staticClass: "font-weight-bold mr-2"
  }, [_vm._v(_vm._s(_vm.$t('qrcode_gtin')) + "*")]), _c('span', {
    staticClass: "caption"
  }, [_vm._v(_vm._s(_vm.$t('qrcode_products_gtin')))])]), _c('v-text-field', {
    attrs: {
      "small": "",
      "dense": "",
      "outlined": "",
      "counter": 14,
      "rules": _vm.gtinRules,
      "required": "",
      "disabled": _vm.disableFormFields,
      "validate-on-blur": ""
    },
    model: {
      value: _vm.form.gtin,
      callback: function ($$v) {
        _vm.$set(_vm.form, "gtin", $$v);
      },
      expression: "form.gtin"
    }
  })], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('v-label', {
    staticClass: "black--text"
  }, [_c('span', {
    staticClass: "font-weight-bold mr-2"
  }, [_vm._v(_vm._s(_vm.$t('qrcode_lot')) + "*")]), _c('span', {
    staticClass: "caption"
  }, [_vm._v(_vm._s(_vm.$t('qrcode_lot_batch_number')))])]), _c('v-text-field', {
    attrs: {
      "small": "",
      "dense": "",
      "outlined": "",
      "persistent-hint": "",
      "counter": 20,
      "rules": _vm.fieldRules,
      "required": false,
      "disabled": _vm.disableFormFields,
      "validate-on-blur": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var on = _ref.on,
                attrs = _ref.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                attrs: {
                  "disabled": _vm.disableFormFields
                }
              }, 'v-icon', attrs, false), Object.assign({}, on, {
                click: _vm.handleRefreshIconClick
              })), [_vm._v(" mdi-refresh ")])];
            }
          }], null, false, 2328002978)
        }, [_c('span', {
          staticClass: "black--text body-2"
        }, [_vm._v(_vm._s(_vm.$t('qrcode_generate_batch_number')))])])];
      },
      proxy: true
    }], null, false, 1831129240),
    model: {
      value: _vm.form.lot,
      callback: function ($$v) {
        _vm.$set(_vm.form, "lot", $$v);
      },
      expression: "form.lot"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('v-label', {
    staticClass: "black--text"
  }, [_c('span', {
    staticClass: "font-weight-bold mr-2"
  }, [_vm._v(_vm._s(_vm.$t('qrcode_cpv')) + "*")]), _c('span', {
    staticClass: "caption"
  }, [_vm._v(_vm._s(_vm.$t('qrcode_consumer_product_variant_vintage_year')))])]), _c('v-text-field', {
    attrs: {
      "small": "",
      "dense": "",
      "outlined": "",
      "persistent-hint": "",
      "counter": 20,
      "rules": _vm.fieldRules,
      "required": false,
      "disabled": _vm.disableFormFields,
      "validate-on-blur": ""
    },
    model: {
      value: _vm.form.cpv,
      callback: function ($$v) {
        _vm.$set(_vm.form, "cpv", $$v);
      },
      expression: "form.cpv"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-4"
  }, [_vm.gs1Code ? _c('v-col', {
    staticClass: "border border-primary d-flex flex-column",
    attrs: {
      "align-center": ""
    }
  }, [_c('div', {
    staticClass: "d-flex flex-row fill-height"
  }, [_c('v-chip', {
    staticClass: "align-self-center pa-0 px-1 mr-1",
    attrs: {
      "label": "",
      "color": "#002C6C",
      "text-color": "white",
      "small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('qrcode_gs1')) + " ")]), _c('span', {
    class: [!_vm.isGs1CodeFormValid ? 'gs1-error-message' : 'gs1-label-text', 'align-self-center']
  }, [_vm._v(" " + _vm._s(_vm.gs1Code) + " ")])], 1), _vm.isRequestInvalid && _vm.requestErrorMessage.length ? _c('div', {
    staticClass: "mt-2"
  }, _vm._l(_vm.requestErrorMessage, function (message, idx) {
    return _c('div', {
      key: idx,
      staticClass: "gs1-error-message mb-1"
    }, [_vm._v(" " + _vm._s(message) + " ")]);
  }), 0) : _vm._e()]) : _vm._e()], 1), _c('v-row', {
    staticClass: "mt-2 mb-4"
  }, [_c('v-col', [_c('div', {
    staticClass: "info-text"
  }, [_c('span', {
    staticClass: "font-weight-bold mr-2"
  }, [_vm._v(_vm._s(_vm.$t('qrcode_tip')) + ":")]), _c('i18n', {
    staticClass: "font-weight-regular",
    attrs: {
      "path": "qrcode_info_text",
      "tag": "label",
      "scope": "global"
    },
    scopedSlots: _vm._u([{
      key: "qrcode_ingestion_feature",
      fn: function () {
        return [_c('a', {
          staticClass: "text-decoration-underline info-text font-weight-regular",
          attrs: {
            "target": "_blank",
            "rel": "noopener noreferrer"
          },
          on: {
            "click": function ($event) {
              return _vm.goToHelpCenter(_vm.helpCenterLink);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('qrcode_ingestion_feature')) + " ")])];
      },
      proxy: true
    }, {
      key: "product_field_below",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.$t('product_field_below')) + " ")];
      },
      proxy: true
    }], null, false, 364270692)
  })], 1)])], 1)], 1) : _vm._e()], 1), _c('v-card-actions', {
    staticClass: "pa-4 px-8 mt-3 pb-6"
  }, [_vm.displayBackButton ? _c('v-btn', {
    staticClass: "pl-0",
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.handleBackButtonClick
    }
  }, [_c('v-icon', {
    staticClass: "mr-1"
  }, [_vm._v("mdi-arrow-left")]), _vm._v(_vm._s(_vm.$t('back')) + " ")], 1) : _vm._e(), _c('v-spacer'), _vm.displayCancelButton ? _c('v-btn', {
    staticClass: "mr-16",
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.closeModal
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('cancel')) + " ")]) : _vm._e(), _c('v-btn', {
    class: _vm.disableNextButton ? 'grey' : 'primary',
    attrs: {
      "disabled": _vm.disableNextButton,
      "dark": !_vm.disableNextButton,
      "loading": _vm.isCreatingCode
    },
    on: {
      "click": _vm.handleActionButtonClick
    }
  }, [_vm._v(" " + _vm._s(_vm.actionButtonText) + " ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }