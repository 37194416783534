import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueApexCharts from 'vue-apexcharts'
import VueRouterMultiView from 'vue-router-multi-view'
import VueTheMask from 'vue-the-mask'
import VueI18n from 'vue-i18n'
import messages from './translations/main'
import * as Sentry from '@sentry/vue'
import STGallery from '@scantrust/st-gallery'
import { default as axios }  from './utils/axios.instances' // eslint-disable-line

const axiosInstance = axios.getSTAxios() // eslint-disable-line

Vue.use(VueTheMask)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.use(VueRouterMultiView)
Vue.use(VueI18n)
Vue.use(STGallery)

Vue.prototype.$axios = axiosInstance
Vue.config.productionTip = false

const i18n = new VueI18n({
  locale: 'en',
  ...messages
})

Sentry.init({
  Vue,
  dsn: 'https://b5128ea52ffa2aa0cab9c188158b9c7e@o26661.ingest.us.sentry.io/4508216023252992',
  integrations: []
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
