var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card-label",
    style: {
      background: _vm.background
    }
  }, [_c('v-icon', {
    staticClass: "icon flex-grow-0",
    style: {
      fill: `#${_vm.color}`
    }
  }, [_vm._v("$tetris")]), _c('span', {
    staticClass: "mono text flex-grow-0 font-weight-bold",
    style: {
      color: `#${_vm.color}`
    }
  }, [_vm._v(_vm._s(_vm.content))])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }