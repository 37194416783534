import axios from 'axios'
import Api from '@/api'

const QrAxios = axios.create({
  baseURL: Api.qr_service_url
})

export default {

  async generateQrZip (options) {
    const params = {
      options: {
        quiet_zone: 1,
        scale: options.scale ? options.scale : 10,
        color: options.color ? options.color : '#000000',
        background: options.background ? options.background : '#FFFFFF',
        error_correction: 'q',
        image: options.image ? options.image : null,
        dpi: options.dpi ? options.dpi : 300,
        text: options.text ? options.text : {}
      },
      codes: []
    }

    params.format = options.format ? options.format : 'png'
    params.codes = options.codes

    return new Promise((resolve, reject) => {
      QrAxios.post('/qr/json/', params, { responseType: 'blob' }).then((res) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([res.data], { type: res.data.type }))
        const link = document.createElement('a')

        link.href = downloadUrl
        link.setAttribute('download', 'qr-codes.zip')
        document.body.appendChild(link)
        link.click()
        link.remove()
        resolve(res.data)
      }).catch(async (err) => {
        // reads the content of the blob as text
        const errorJson = await err.response.data.text()
        const errorMessage = JSON.parse(errorJson)
        const message = errorMessage?.details.message || 'Unable to generate a ZIP file. Please try again.'
        reject(message)
      })
    })
  }

}
