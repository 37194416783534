var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "15",
      "height": "15",
      "viewBox": "0 0 15 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "id": "date_range"
    }
  }, [_c('path', {
    attrs: {
      "id": "Vector",
      "d": "M5.48779 7.04175H4.32113V8.20841H5.48779V7.04175ZM7.82113 7.04175H6.65446V8.20841H7.82113V7.04175ZM10.1545 7.04175H8.98779V8.20841H10.1545V7.04175ZM11.3211 2.95841H10.7378V1.79175H9.57113V2.95841H4.90446V1.79175H3.73779V2.95841H3.15446C2.50696 2.95841 1.99363 3.48341 1.99363 4.12508L1.98779 12.2917C1.98779 12.9334 2.50696 13.4584 3.15446 13.4584H11.3211C11.9628 13.4584 12.4878 12.9334 12.4878 12.2917V4.12508C12.4878 3.48341 11.9628 2.95841 11.3211 2.95841ZM11.3211 12.2917H3.15446V5.87508H11.3211V12.2917Z",
      "fill": "#777777"
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }