import de from './de.json'
import en from './en.json'
import es from './es.json'
import fr from './fr.json'
import it from './it.json'
import nl from './nl.json'
import zh from './zh.json'
import { messages } from '@scantrust/st-gallery'

export default {
  messages: {
    en: {
      ...en,
      ...messages.en
    },
    fr: {
      ...fr,
      ...messages.fr
    },
    de: {
      ...de,
      ...messages.de
    },
    es: {
      ...es,
      ...messages.es
    },
    it: {
      ...it,
      ...messages.it
    },
    nl: {
      ...nl,
      ...messages.nl
    },
    zh: {
      ...zh,
      ...messages.zh
    }
  }
}
