var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.icon ? _c('v-btn', {
    attrs: {
      "color": "accent",
      "icon": ""
    },
    on: {
      "click": _vm.selectFile
    }
  }, [_c('v-icon', [_vm._v("mdi-upload")])], 1) : _vm._e(), !_vm.icon ? _c('v-btn', {
    class: 'inset',
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.selectFile
    }
  }, [_vm._v(_vm._s(_vm.label ? _vm.label : _vm.$t('upload_image')))]) : _vm._e(), _c('input', {
    ref: `input-${_vm.inputKey}`,
    attrs: {
      "id": _vm.inputKey,
      "type": "file",
      "name": "image",
      "accept": "image/png, image/jpeg, image/jpg, image/gif, image/tiff, image/tif, image/svg+xml"
    },
    on: {
      "click": _vm.resetFileSelector,
      "change": _vm.setImage
    }
  }), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "width": "700"
    },
    model: {
      value: _vm.openModal,
      callback: function ($$v) {
        _vm.openModal = $$v;
      },
      expression: "openModal"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v(_vm._s(_vm.$t('image_upload')))]), _c('v-card-text', [_c('vue-cropper', {
    ref: `cropper-${_vm.inputKey}`,
    attrs: {
      "src": this.src,
      "viewMode": 1,
      "containerStyle": _vm.containerStyle,
      "zoomable": false,
      "aspect-ratio": _vm.ratio ? _vm.ratio : NaN
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(_vm._s(_vm.$t('close')))]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(_vm._s(_vm.$t('save')))])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }