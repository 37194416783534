var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "19",
      "height": "24",
      "viewBox": "0 0 19 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M10.355 6.33576V13.5686C10.355 14.2879 10.0693 14.9778 9.5606 15.4865C9.05194 15.9951 8.36205 16.2809 7.6427 16.2809H5.68079C5.46751 16.8844 5.04774 17.393 4.49569 17.717C3.94363 18.0409 3.29484 18.1592 2.66399 18.051C2.03314 17.9428 1.46085 17.615 1.04828 17.1257C0.635707 16.6363 0.409424 16.0169 0.409424 15.3768C0.409424 14.7367 0.635707 14.1173 1.04828 13.6279C1.46085 13.1386 2.03314 12.8108 2.66399 12.7026C3.29484 12.5944 3.94363 12.7127 4.49569 13.0367C5.04774 13.3606 5.46751 13.8692 5.68079 14.4727H7.6427C7.88248 14.4727 8.11244 14.3774 8.282 14.2079C8.45155 14.0383 8.5468 13.8084 8.5468 13.5686V6.33576C8.5468 5.61641 8.83257 4.92652 9.34122 4.41786C9.84988 3.90921 10.5398 3.62345 11.2591 3.62345H13.9714V0.911133L18.492 4.52755L13.9714 8.14397V5.43165H11.2591C11.0193 5.43165 10.7894 5.52691 10.6198 5.69646C10.4503 5.86601 10.355 6.09598 10.355 6.33576ZM3.12218 16.2809C3.36196 16.2809 3.59192 16.1857 3.76148 16.0161C3.93103 15.8465 4.02628 15.6166 4.02628 15.3768C4.02628 15.137 3.93103 14.9071 3.76148 14.7375C3.59192 14.568 3.36196 14.4727 3.12218 14.4727C2.88239 14.4727 2.65243 14.568 2.48288 14.7375C2.31333 14.9071 2.21807 15.137 2.21807 15.3768C2.21807 15.6166 2.31333 15.8465 2.48288 16.0161C2.65243 16.1857 2.88239 16.2809 3.12218 16.2809Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M8.56038 16.6753H10.3599V18.4748H8.56038V16.6753Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "d": "M10.3599 18.5404H12.1594V20.3399H10.3599V18.5404Z",
      "fill": "black"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M18.4919 19.4871L14.0445 23.0889V20.3399H13.1448V18.5404H14.0445V15.7914L18.4919 19.4871ZM15.4291 20.1858V18.7423L16.3089 19.4733L15.4291 20.1858Z",
      "fill": "black"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }