var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "landing-page"
  }, [_c('v-progress-linear', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "indeterminate": "",
      "color": "primary"
    }
  }), _vm.loadIframe ? _c('iframe', {
    attrs: {
      "id": "stc-frame",
      "frameborder": "0",
      "src": this.editorUrl
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }