var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: _vm.mask,
      expression: "mask"
    }],
    staticClass: "color-input ma-0 pa-0",
    attrs: {
      "value": _vm.color,
      "hide-details": "",
      "solo": ""
    },
    on: {
      "input": _vm.debounceUpdate
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('v-menu', {
          attrs: {
            "top": "",
            "nudge-bottom": "105",
            "nudge-left": "16",
            "close-on-content-click": false
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref) {
              var on = _ref.on;
              return [_c('div', _vm._g({
                style: _vm.swatchStyle
              }, on))];
            }
          }]),
          model: {
            value: _vm.menu,
            callback: function ($$v) {
              _vm.menu = $$v;
            },
            expression: "menu"
          }
        }, [_c('v-card', [_c('v-card-text', {
          staticClass: "pa-0"
        }, [_c('v-color-picker', {
          staticClass: "no-alpha",
          attrs: {
            "value": _vm.color,
            "flat": ""
          },
          on: {
            "input": _vm.debounceUpdate
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }