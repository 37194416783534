<template>
  <div>
    <div class="py-5">
      <div class="title">{{$t('text_around_qr_title')}}</div>
      <div class="description">
        {{$t('text_around_qr_hint')}}
        <a
          href="https://help.scantrust.com/hc/en-us/articles/11085111089180-e-label-Editor-QR-Codes-Downloading-e-label-QR-codes-in-Bulk-Enterprise"
          target="_blank"
          >
          {{ $t('learn_more') }}
        </a>
      </div>
      <div v-if="filteredTexts.length">
        <div class="label font-weight-bold mt-2 pb-1">{{$t('text_color')}}:</div>
        <color-input class="px-1" :color.sync="textSettings.text.color"></color-input>
      </div>

      <div :key="`renderKey-${renderKey}`">
        <v-row class="ma-0 mt-5 my-2" v-for="(item) in filteredTexts" :key="`textfield-${item.key}`">
          <v-row class="text-row px-1 ma-0">
            <v-col cols="3" class="pa-0 mr-2">
              <v-select
                solo
                class="text-select"
                :value="item.key"
                :items="textPositions"
                :label="$t('position')"
                item-text="description"
                item-value="key"
                hide-details
                dense
                @change="onTextPositionChange($event, item.key)"
              >
              </v-select>
            </v-col>
            <v-col class="pa-0 mr-2">
              <v-combobox @input="handleTextInput($event, item.key)" maxlength="33" class="ma-0" solo hide-details dense :items="dataFields" v-model="textSettings.selectedTexts[item.key]" item-text="description" return-object></v-combobox>
            </v-col>
            <v-col cols="1" class="pa-0">
              <v-btn color="red" @click="deleteTextField(item.key)" icon><v-icon>mdi-close-circle</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-row>
      </div>
      <v-btn class="mt-3" text color="primary" v-if="hasAvailableTextPosition" @click="addTextField()">
        <v-icon>$plus</v-icon>
        {{$t('add_data')}}
      </v-btn>
    </div>

    <div>
      <div class="title">{{$t('icon_options_title')}}</div>
      <div class="description">{{$t('icon_options_hint')}}</div>
      <v-radio-group hide-details v-model="textSettings.iconSetting" class="pa-2">
        <v-radio value="none" :label="$t('no_icon')"></v-radio>
        <v-radio value="inside" :label="$t('icon_inside_of_qr')"></v-radio>
        <v-radio value="outside" :label="$t('icon_outside_of_qr')"></v-radio>
      </v-radio-group>

      <div v-if="textSettings.iconSetting === 'outside'" class="outside-icon-options mt-2 ml-7 mb-5">
        <v-select
          solo
          class="text-select"
          v-model="iconPosition"
          :items="textPositions"
          :label="$t('position')"
          item-text="description"
          item-value="key"
          hide-details
          dense
        >
        </v-select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ColorInput from '@/components/utils/ColorInput'

export default {
  name: 'TextAroundQrCodeSettings',
  components: {
    ColorInput
  },
  props: ['codes', 'product', 'elabelData', 'selectedTemplate', 'textPositions'],
  data () {
    return {
      textSettings: {
        text: {},
        selectedTexts: {},
        iconSetting: 'none'
      },
      kj: 0,
      kcal: 0,
      iconPosition: '',
      dataFields: [
        { key: 'energy', description: this.$t('energy'), text: '{energy}' },
        { key: 'gtin', description: this.$t('gtin'), text: '{gtin}' },
        { key: 'sku', description: this.$t('sku'), text: '{sku}' }
      ],
      renderKey: 1
    }
  },
  watch: {
    selectedTemplate (newVal) {
      if (newVal) {
        this.iconPosition = ''
        this.textSettings.iconSetting = newVal.image ? 'inside' : 'none'
        this.textSettings.selectedTexts = {}
        const { text } = newVal
        this.textSettings.text = { color: text?.color }

        Object.entries(text).forEach(([key, value]) => {
          if (value.indexOf('ⓘ ') > -1) {
            this.textSettings.iconSetting = 'outside'
            this.iconPosition = key
          }

          if (key === 'color') {
            return
          }
          const matchedField = this.dataFields.find((field) => {
            return value.indexOf(field.text) > -1
          })
          if (value) {
            this.$set(this.textSettings.selectedTexts, key, { text: value, description: matchedField?.description ? matchedField.description : value })
          }
        })
      }
    },

    'textSettings.iconSetting' (newVal, oldVal) {
      if (oldVal === 'outside' && newVal !== 'outside') {
        for (const position in this.textSettings.selectedTexts) {
          if (this.textSettings.selectedTexts[position].text.indexOf('ⓘ ') > -1) {
            this.textSettings.selectedTexts[position].text = this.textSettings.selectedTexts[position].text.substr(2)
            if (!this.textSettings.selectedTexts[position].text.length) {
              this.$delete(this.textSettings.selectedTexts, position)
              this.$delete(this.textSettings.text, position)
            }
          }
        }
        this.iconPosition = ''
      }
    },

    iconPosition (newVal, oldVal) {
      if (this.textSettings.iconSetting === 'outside') {
        if (oldVal && this.textSettings.selectedTexts[oldVal]) {
          if (this.textSettings.selectedTexts[oldVal]?.text?.indexOf('ⓘ ') > -1) {
            this.textSettings.selectedTexts[oldVal].text = this.textSettings.selectedTexts[oldVal].text.substr(2)
          }
          if (!this.textSettings.selectedTexts[oldVal].text) {
            this.$delete(this.textSettings.selectedTexts, oldVal)
            this.$delete(this.textSettings.text, oldVal)
          }
        }

        if (!this.textSettings.selectedTexts[newVal] && this.textSettings.selectedTexts[newVal] !== null) {
          this.$set(this.textSettings.selectedTexts, newVal, { description: '', text: '' })
        }

        if (this.textSettings.selectedTexts[newVal].text.indexOf('ⓘ ') === -1) {
          this.textSettings.selectedTexts[newVal].text = `ⓘ ${this.textSettings.selectedTexts[newVal].text ? this.textSettings.selectedTexts[newVal].text : ''}`
        }
      }
    },
    computedSelectedTextsToBeWatched: {
      handler (newVal, oldVal) {
        for (const position in newVal) {
          if (oldVal[position]?.text?.indexOf('ⓘ ') > -1 && newVal[position]?.text?.indexOf('ⓘ ') === -1 && this.iconPosition && this.iconPosition === position) {
            newVal[position].text = `ⓘ ${newVal[position].text}`
          }
          this.$set(this.textSettings.text, position, this.convertToValue(newVal[position]?.text))
        }
      },
      deep: true
    },
    textSettings: {
      handler (newVal) {
        if (newVal) {
          const data = { ...newVal }
          if (this.filteredTexts.length) {
            data.filteredTexts = this.filteredTexts
          }
          this.$emit('text-data-updated', data)
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters(['products']),

    computedSelectedTextsToBeWatched () {
      return Object.assign({}, this.textSettings.selectedTexts)
    },

    filteredTexts () {
      const array = []
      for (const k in this.textSettings.text) {
        if (k !== 'color') {
          array.push({ key: k, value: this.textSettings.text[k] })
        }
      }
      return array
    },

    hasAvailableTextPosition () {
      return this.textPositions.filter((c) => {
        return !(Object.prototype.hasOwnProperty.call(this.textSettings.text, c.key))
      }).length
    }
  },
  methods: {
    forceReRender () {
      // Increment renderKey to force a refresh
      this.renderKey += 1
    },

    // goToHelpCenter (link) {
    //   parent.postMessage({ type: 'helpcenter', data: link }, '*')
    // },

    updateIconSetting (value) {
      this.textSettings.iconSetting = value
    },

    handleTextInput (e, key) {
      if (e === null) {
        this.textSettings.selectedTexts[key] = { text: '', description: '' }
      } else if (typeof e === 'string') {
        this.textSettings.selectedTexts[key] = { text: e, description: e }
      } else {
        this.textSettings.selectedTexts[key] = JSON.parse(JSON.stringify(e))
      }
    },

    replaceVariables (string, replacements) {
      if (!string) return ''
      return string.replace(/\{(.*?)\}/g, (match, p1) => replacements[p1] || match)
    },

    convertToValue (string) {
      if (!string) {
        return ''
      }

      const variablesReplacements = {
        energy: `E(100ml)=${this.kj}kJ/${this.kcal}kcal`,
        gtin: this.codes[0].scm_data.gs1_gtin,
        sku: this.codes[0].product.sku
      }

      return this.replaceVariables(string, variablesReplacements)
    },

    onTextPositionChange (newKey, oldKey) {
      if (newKey) {
        if (this.textSettings.selectedTexts[oldKey]) {
          if (this.textSettings.selectedTexts[oldKey].text.indexOf('ⓘ ') > -1) {
            this.$set(this.textSettings.selectedTexts, newKey, { ...this.textSettings.selectedTexts[oldKey], text: this.textSettings.selectedTexts[oldKey].text.split('ⓘ ')[1] })
          } else {
            this.$set(this.textSettings.selectedTexts, newKey, this.textSettings.selectedTexts[oldKey])
          }
        }
        if (oldKey) {
          if (this.textSettings.selectedTexts[oldKey]?.text?.indexOf('ⓘ ') > -1) {
            this.$set(this.textSettings.selectedTexts, oldKey, { key: '', description: '', text: 'ⓘ ' })
          } else {
            this.$delete(this.textSettings.selectedTexts, oldKey)
            this.$delete(this.textSettings.text, oldKey)
          }
        }
        // Hack to make sure the value of the textPosition select is properly updated
        this.forceReRender()
      }
    },

    addTextField () {
      const availablePositions = this.textPositions.filter((c) => {
        return !(Object.prototype.hasOwnProperty.call(this.textSettings.text, c.key))
      })
      if (availablePositions[0]) {
        this.$set(this.textSettings.text, availablePositions[0].key, '')
        this.$set(this.textSettings.selectedTexts, availablePositions[0].key, { description: '', text: '' })
      }

      this.$forceUpdate()
    },

    deleteTextField (key) {
      if (this.textSettings.iconSetting === 'outside' && this.iconPosition === key) {
        this.textSettings.iconSetting = 'none'
        this.$nextTick(() => {
          this.iconPosition = ''
        })
      }

      this.$delete(this.textSettings.selectedTexts, key)
      this.$delete(this.textSettings.text, key)
      this.$forceUpdate()
    }
  },
  async created () {
    this.$set(this.textSettings.text, 'color', '#000000')
    if (this.elabelData?.data.published) {
      this.kj = this.elabelData.data.published.nutrition.table[0].per_100ml
      this.kcal = this.elabelData.data.published.nutrition.table[0].children[0].per_100ml
    } else if (this.product) {
      // load Elabel data for 'Codes' (All Qr Codes) page
      const product = this.products.find((product) => {
        return product.id === this.product
      })
      if (product?.elabel?.has_data) {
        const elabelData = await this.$store.dispatch('loadElabel', { id: product.id, sku: product.sku })
        if (elabelData?.data?.published) {
          this.kj = elabelData.data.published.nutrition.table[0].per_100ml
          this.kcal = elabelData.data.published.nutrition.table[0].children[0].per_100ml
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.label
  text-transform: uppercase
  color: #999999
  font-size: 16px
</style>
