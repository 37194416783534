var render = function render(){
  var _vm$textSettings, _this$textSettings, _this$textSettings$se, _this$textSettings$se2, _this$textSettings2, _this$textSettings2$s, _this$textSettings2$s2, _this$textSettings3, _this$textSettings3$s, _this$textSettings3$s2, _this$textSettings4, _this$textSettings4$s, _this$textSettings4$s2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "download-ctn"
  }, [_vm.mode == 'codes' ? _c('v-btn', {
    attrs: {
      "color": "primary",
      "raised": "",
      "disabled": !_vm.count
    },
    on: {
      "click": _vm.open
    }
  }, [_vm._v(_vm._s(_vm.$t('download')))]) : _vm._e(), _vm.mode == 'single' ? _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "color": "primary",
            "icon": ""
          },
          on: {
            "click": _vm.open
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "color": "#15bbf0"
          }
        }, [_vm._v("mdi-qrcode")])], 1)];
      }
    }], null, false, 37656637)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('preview_and_download')))])]) : _vm._e(), _vm.mode == 'product' ? _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "disabled": _vm.isDisabled,
            "color": "primary",
            "icon": ""
          },
          on: {
            "click": _vm.open
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "color": "#15bbf0"
          }
        }, [_vm._v("mdi-download")])], 1)];
      }
    }], null, false, 1613264683)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('download')))])]) : _vm._e(), _c('v-dialog', {
    attrs: {
      "width": "600"
    },
    model: {
      value: _vm.deleteQrDialog,
      callback: function ($$v) {
        _vm.deleteQrDialog = $$v;
      },
      expression: "deleteQrDialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('delete_confirmation')) + " ")]), _c('v-card-text', {
    staticClass: "my-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('qr_template_delete_confirmation')) + " ")]), _c('v-card-actions', [_c('div', {
    staticClass: "mr-auto"
  }), _c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.deleteQrDialog = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('cancel')))]), _c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "color": "red",
      "loading": _vm.deletingTemplate,
      "text": ""
    },
    on: {
      "click": _vm.deleteQrTemplate
    }
  }, [_vm._v(_vm._s(_vm.$t('delete')))])], 1)], 1)], 1), _vm.dialog ? _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "width": _vm.mode === 'single' || _vm.codes.length === 1 ? 800 : 650,
      "persistent": ""
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    staticClass: "pb-2"
  }, [_c('v-card-title', [_vm.mode != 'single' ? _c('span', [_vm._v(_vm._s(_vm.$t('download_format')))]) : _vm._e(), _vm.mode == 'single' ? _c('span', [_vm._v(_vm._s(_vm.$t('preview_and_download')))]) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(_vm._s(_vm.$t('cancel')))])], 1), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-stepper', {
    model: {
      value: _vm.step,
      callback: function ($$v) {
        _vm.step = $$v;
      },
      expression: "step"
    }
  }, [_c('v-stepper-header', [_vm.hasPrefixOptions ? _c('v-stepper-step', {
    attrs: {
      "step": "1"
    }
  }, [_vm._v(_vm._s(_vm.$t('select_url_prefix')))]) : _vm._e(), _vm.hasPrefixOptions ? _c('v-divider') : _vm._e(), _c('v-stepper-step', {
    attrs: {
      "step": _vm.hasPrefixOptions ? 2 : 1
    }
  }, [_vm._v(_vm._s(_vm.$t('designing_your_qr_code')))]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "step": _vm.hasPrefixOptions ? 3 : 2
    }
  }, [_vm._v(_vm._s(_vm.$t('file_format')))])], 1), _c('v-stepper-items', [_vm.hasPrefixOptions ? _c('v-stepper-content', {
    staticClass: "prefixes-ctn",
    attrs: {
      "step": "1"
    }
  }, [_c('v-row', {
    staticClass: "ma-0"
  }, [_c('v-col', [_c('div', {
    staticClass: "d-flex flex-column justify-center"
  }, [_c('v-select', {
    attrs: {
      "items": _vm.formattedPrefixes,
      "label": _vm.$t('all_url_prefixes'),
      "item-text": "displayText",
      "item-value": "prefix",
      "persistent-hint": "",
      "hint": _vm.hasStoredPrefix ? _vm.$t('prefix_used_last_download') : _vm.$t('prefix_will_be_associated_with_codes_selected')
    },
    on: {
      "change": _vm.onPrefixChange
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "prefix"
        }, [_vm._v(_vm._s(item.displayText))])];
      }
    }], null, false, 3836696457),
    model: {
      value: _vm.selectedPrefix,
      callback: function ($$v) {
        _vm.selectedPrefix = $$v;
      },
      expression: "selectedPrefix"
    }
  }), _vm.showPrefixConflictMessage && !_vm.hasObsoletePrefix ? _c('span', {
    staticClass: "color-error mt-2"
  }, [_vm._v(_vm._s(_vm.$t('prefix_conflict_message')))]) : _vm._e(), _vm.hasObsoletePrefix && _vm.codes.length === 1 ? _c('span', {
    staticClass: "color-error mt-2"
  }, [_vm._v(_vm._s(_vm.$t('has_deleted_prefix')))]) : _vm._e(), _vm.hasObsoletePrefix && _vm.codes.length > 1 ? _c('span', {
    staticClass: "color-error mt-2"
  }, [_vm._v(_vm._s(_vm.$t('have_deleted_prefix')))]) : _vm._e(), _vm.showPrefixRequiredMessage ? _c('span', {
    staticClass: "color-error mt-2"
  }, [_vm._v(_vm._s(_vm.$t('prefix_required')))]) : _vm._e()], 1)])], 1)], 1) : _vm._e(), _c('v-stepper-content', {
    staticClass: "pb-0",
    attrs: {
      "step": _vm.hasPrefixOptions ? 2 : 1
    }
  }, [_c('v-row', {
    staticClass: "ma-0",
    attrs: {
      "align": "start"
    }
  }, [_c('v-col', {
    staticClass: "pr-0"
  }, [_c('div', {
    staticClass: "scrollable-content"
  }, [_vm.qrTemplates.length ? _c('div', [_c('div', {
    staticClass: "label font-weight-bold pb-1"
  }, [_vm._v(_vm._s(_vm.$t('qr_code_templates')))]), _c('v-select', {
    attrs: {
      "items": _vm.qrTemplates,
      "item-value": "id",
      "return-object": "",
      "clearable": "",
      "solo": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "select-qr-preview mr-3"
        }, [_c('img', {
          staticClass: "template-qr-preview",
          attrs: {
            "src": _vm.getTemplatePreviewUrl(item)
          }
        }), item.image ? _c('img', {
          staticClass: "template-qr-logo",
          style: {
            backgroundColor: item.background
          },
          attrs: {
            "src": _vm.toDataUrl(item.image)
          }
        }) : _vm._e()]), _c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(item.filename)
          }
        }), _c('v-list-item-subtitle', {
          domProps: {
            "innerHTML": _vm._s(`${item.color} - ${item.background}`)
          }
        })], 1)];
      }
    }, {
      key: "item",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "select-qr-preview mr-3"
        }, [_c('img', {
          staticClass: "template-qr-preview",
          attrs: {
            "src": _vm.getTemplatePreviewUrl(item)
          }
        }), item.image ? _c('img', {
          staticClass: "template-qr-logo",
          style: {
            backgroundColor: item.background
          },
          attrs: {
            "src": _vm.toDataUrl(item.image)
          }
        }) : _vm._e()]), _c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(item.filename)
          }
        }), _c('v-list-item-subtitle', {
          domProps: {
            "innerHTML": _vm._s(`${item.color} - ${item.background}`)
          }
        })], 1), _c('v-list-item-action', [_c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function ($event) {
              return _vm.deleteQrTemplateConfirm(item);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "color": "red lighten-1"
          }
        }, [_vm._v("mdi-delete")])], 1)], 1)];
      }
    }], null, false, 3090673912),
    model: {
      value: _vm.selectedTemplate,
      callback: function ($$v) {
        _vm.selectedTemplate = $$v;
      },
      expression: "selectedTemplate"
    }
  })], 1) : _vm._e(), _c('v-row', [_c('v-col', [_c('div', {
    staticClass: "label font-weight-bold pb-1"
  }, [_vm._v(_vm._s(_vm.$t('qr_color')) + ":")]), _c('color-input', {
    attrs: {
      "color": _vm.form.color
    },
    on: {
      "update:color": function ($event) {
        return _vm.$set(_vm.form, "color", $event);
      }
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "label font-weight-bold pb-1"
  }, [_vm._v(_vm._s(_vm.$t('background_color')) + ":")]), _c('color-input', {
    attrs: {
      "color": _vm.form.background
    },
    on: {
      "update:color": function ($event) {
        return _vm.$set(_vm.form, "background", $event);
      }
    }
  })], 1)], 1), _vm.hasContrastIssue ? _c('div', {
    staticClass: "color-error pt-5 d-flex"
  }, [_c('v-icon', {
    staticClass: "mr-3",
    attrs: {
      "color": "red"
    }
  }, [_vm._v("mdi-alert")]), _c('div', {
    staticClass: "font-weight-bold",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('qr_contrast_warning'))
    }
  })], 1) : _vm._e(), _vm.enableQrTextSettings ? _c('text-around-qr-code-settings', {
    ref: "text-around-qr-code-settings",
    attrs: {
      "codes": _vm.codes,
      "product": _vm.product,
      "elabel-data": _vm.elabelData,
      "selected-template": _vm.selectedTemplate,
      "text-positions": _vm.textPositions
    },
    on: {
      "text-data-updated": _vm.onTextSettingsUpdated
    }
  }) : _vm._e()], 1)]), _vm.mode === 'single' || _vm.codes.length === 1 ? _c('v-col', {
    staticClass: "flex-grow-0 preview-col pr-0",
    attrs: {
      "align-self": "center"
    }
  }, [_c('div', {
    staticClass: "label font-weight-bold pb-1 text-center"
  }, [_vm._v(_vm._s(_vm.$t('qr_preview')))]), _c('div', {
    staticClass: "qr-preview d-flex flex-column justify-center"
  }, [_c('div', {
    staticClass: "qr-preview-ctn"
  }, [_c('img', {
    staticClass: "qr",
    attrs: {
      "src": _vm.previewUrl
    }
  }), _vm.form.image && ((_vm$textSettings = _vm.textSettings) === null || _vm$textSettings === void 0 ? void 0 : _vm$textSettings.iconSetting) === 'inside' ? _c('img', {
    staticClass: "logo",
    class: {
      'top': (_this$textSettings = this.textSettings) === null || _this$textSettings === void 0 ? void 0 : (_this$textSettings$se = _this$textSettings.selectedTexts['top']) === null || _this$textSettings$se === void 0 ? void 0 : (_this$textSettings$se2 = _this$textSettings$se.text) === null || _this$textSettings$se2 === void 0 ? void 0 : _this$textSettings$se2.length,
      'bottom': (_this$textSettings2 = this.textSettings) === null || _this$textSettings2 === void 0 ? void 0 : (_this$textSettings2$s = _this$textSettings2.selectedTexts['bottom']) === null || _this$textSettings2$s === void 0 ? void 0 : (_this$textSettings2$s2 = _this$textSettings2$s.text) === null || _this$textSettings2$s2 === void 0 ? void 0 : _this$textSettings2$s2.length,
      'left': (_this$textSettings3 = this.textSettings) === null || _this$textSettings3 === void 0 ? void 0 : (_this$textSettings3$s = _this$textSettings3.selectedTexts['left']) === null || _this$textSettings3$s === void 0 ? void 0 : (_this$textSettings3$s2 = _this$textSettings3$s.text) === null || _this$textSettings3$s2 === void 0 ? void 0 : _this$textSettings3$s2.length,
      'right': (_this$textSettings4 = this.textSettings) === null || _this$textSettings4 === void 0 ? void 0 : (_this$textSettings4$s = _this$textSettings4.selectedTexts['right']) === null || _this$textSettings4$s === void 0 ? void 0 : (_this$textSettings4$s2 = _this$textSettings4$s.text) === null || _this$textSettings4$s2 === void 0 ? void 0 : _this$textSettings4$s2.length
    },
    style: {
      backgroundColor: _vm.form.background
    },
    attrs: {
      "src": _vm.toDataUrl(_vm.form.image)
    }
  }) : _vm._e()]), _c('div', {
    staticClass: "mt-auto mb-2"
  }), _c('ImageCropper', {
    attrs: {
      "ratio": 1,
      "label": _vm.$t('upload_logo'),
      "input-key": "qr-logo"
    },
    on: {
      "image-selected": _vm.onImageSelect
    }
  }), _c('div', [_vm.form.image ? _c('v-btn', {
    staticClass: "inset mt-3",
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.deleteLogo
    }
  }, [_vm._v(_vm._s(_vm.$t('remove_logo')))]) : _vm._e(), !_vm.form.image ? _c('v-btn', {
    staticClass: "inset mt-3",
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.addDefaultLogo
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('qrcode_add_default_logo')) + " ")]) : _vm._e()], 1)], 1)]) : _vm._e()], 1)], 1), _c('v-stepper-content', {
    attrs: {
      "step": _vm.hasPrefixOptions ? 3 : 2
    }
  }, [_c('v-row', [_c('v-col', [_c('div', {
    staticClass: "mb-5"
  }, [_c('div', {
    staticClass: "label font-weight-bold pb-1"
  }, [_vm._v(_vm._s(_vm.$t('choose_file_format')) + ":")]), _c('div', _vm._l(_vm.filteredFormats, function (format) {
    return _c('span', {
      key: format,
      staticClass: "pill font-weight-bold mr-2",
      class: {
        'selected': format === _vm.form.format
      },
      on: {
        "click": function ($event) {
          return _vm.selectFormat(format);
        }
      }
    }, [_vm._v(" " + _vm._s(format) + " ")]);
  }), 0)]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasDPI,
      expression: "hasDPI"
    }],
    staticClass: "mb-5"
  }, [_c('div', {
    staticClass: "label font-weight-bold pb-1"
  }, [_vm._v(_vm._s(_vm.$t('choose_dpi')) + ":")]), _c('div', _vm._l(_vm.dpis, function (dpi) {
    return _c('span', {
      key: dpi,
      staticClass: "pill font-weight-bold mr-2",
      class: {
        'selected': dpi === _vm.form.dpi
      },
      on: {
        "click": function ($event) {
          return _vm.selectDPI(dpi);
        }
      }
    }, [_vm._v(" " + _vm._s(dpi) + "dpi ")]);
  }), 0)]), _c('div', {
    staticClass: "mb-5"
  }, [_c('div', {
    staticClass: "label font-weight-bold pb-1"
  }, [_vm._v(_vm._s(_vm.$t('qr_dimensions_mm')))]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(_vm._s(_vm.$t('qr_dimensions_hint')))]), _c('v-slider', {
    staticClass: "pt-10 px-2",
    attrs: {
      "step": "5",
      "max": "30",
      "min": "10",
      "thumb-label": "always",
      "ticks": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.qrSize,
      callback: function ($$v) {
        _vm.qrSize = $$v;
      },
      expression: "qrSize"
    }
  }), _c('v-row', {
    staticClass: "ma-0",
    attrs: {
      "justify": "space-between"
    }
  }, [_c('div', {
    staticClass: "size-hint font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('small'))), _c('br'), _vm._v("(10mm) ")]), _c('div', {
    staticClass: "size-hint font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('medium'))), _c('br'), _vm._v("(20mm) ")]), _c('div', {
    staticClass: "size-hint font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('large'))), _c('br'), _vm._v("(30mm) ")])])], 1), _c('div', {
    staticClass: "mb-5"
  }, [_c('div', {
    staticClass: "label font-weight-bold pb-1"
  }, [_vm._v(_vm._s(_vm.$t('naming_convention')) + ":")]), _c('v-text-field', {
    ref: "filename",
    attrs: {
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('span', [_vm._v(" ." + _vm._s(_vm.form.format) + " ")])];
      },
      proxy: true
    }], null, false, 1076406608),
    model: {
      value: _vm.form.filename,
      callback: function ($$v) {
        _vm.$set(_vm.form, "filename", $$v);
      },
      expression: "form.filename"
    }
  }), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" " + _vm._s(_vm.$t('naming_convention_hint')) + " ")]), _c('div', _vm._l(_vm.generalFields, function (field) {
    return _c('span', {
      key: `general-field-${field}`,
      staticClass: "pill font-weight-bold mr-2 mb-2",
      class: {
        'selected': _vm.isGeneralFieldSelected(field)
      },
      on: {
        "click": function ($event) {
          return _vm.toggleGeneralField(field);
        }
      }
    }, [_vm._v(_vm._s(field))]);
  }), 0), _c('div', {
    staticClass: "hint"
  }, [_vm._v(_vm._s(_vm.$t('tags')) + ":")]), _c('div', _vm._l(_vm.activeScmFields, function (field) {
    return _c('span', {
      key: field.key,
      staticClass: "pill font-weight-bold mr-2 mb-2",
      class: {
        'selected': _vm.isTagSelected(field)
      },
      on: {
        "click": function ($event) {
          return _vm.toggleTag(field);
        }
      }
    }, [_vm._v(_vm._s(field.key === 'scantrust_upload_batch' ? _vm.$t('upload_batch') : field.key))]);
  }), 0)], 1)])], 1)], 1)], 1)], 1)], 1), _c('v-card-actions', [_vm.step === 1 ? _c('v-row', {
    staticClass: "dialog-footer pa-3"
  }, [_c('div', {
    staticClass: "mr-auto"
  }), _c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(_vm._s(_vm.$t('cancel')))]), _c('v-btn', {
    staticClass: "mr-2",
    on: {
      "click": _vm.handleFirstStepNext
    }
  }, [_vm._v(_vm._s(_vm.$t('next')))])], 1) : _vm._e(), _vm.hasPrefixOptions && _vm.step === 2 ? _c('v-row', {
    staticClass: "dialog-footer pa-3"
  }, [_c('div', {
    staticClass: "mr-auto"
  }), _c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.step = 1;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('back')))]), _c('v-btn', {
    staticClass: "mr-2",
    on: {
      "click": function ($event) {
        _vm.step = 3;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('next')))])], 1) : _vm._e(), _vm.hasPrefixOptions && _vm.step === 3 || !_vm.hasPrefixOptions && _vm.step === 2 ? _c('v-row', {
    staticClass: "dialog-footer pa-3 align-center"
  }, [_vm.templateToUpdate || _vm.templateToCreate ? _c('v-checkbox', {
    staticClass: "ml-2",
    attrs: {
      "hide-details": "",
      "dense": "",
      "label": _vm.$t('save_as_qr_template')
    },
    model: {
      value: _vm.createTemplateToggle,
      callback: function ($$v) {
        _vm.createTemplateToggle = $$v;
      },
      expression: "createTemplateToggle"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "mr-auto"
  }), _c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.hasPrefixOptions ? _vm.step = 2 : _vm.step = 1;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('back')))]), _c('v-btn', {
    staticClass: "mr-2",
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.count > 1000 || _vm.codes.length > 1000
    },
    on: {
      "click": _vm.download
    }
  }, [_vm._v(_vm._s(_vm.$t('download')))])], 1) : _vm._e()], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }