import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VCard, {
    attrs: {
      "scrollable": ""
    }
  }, [_c(VRow, {
    staticClass: "pa-5 ma-0"
  }, [_c(VCol, {
    staticClass: "flex-grow-0 pa-0 ma-0"
  }, [_c('div', {
    staticClass: "image-upload-ctn pr-5"
  }, [_vm.productImage ? _c('div', {
    staticClass: "d-flex flex-row align-center mb-3 img-ctn"
  }, [_c('img', {
    attrs: {
      "src": _vm.productImage
    }
  }), _vm.productForm.image ? _c(VBtn, {
    staticClass: "delete-btn",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.removeProductPicture();
      }
    }
  }, [_c(VIcon, {
    attrs: {
      "color": "red"
    }
  }, [_vm._v("mdi-minus-circle-outline")])], 1) : _vm._e()], 1) : _vm._e(), _c('ImageCropper', {
    attrs: {
      "input-key": "logo"
    },
    on: {
      "image-selected": _vm.onImageSelect
    }
  })], 1), _c('div', {
    staticClass: "font-weight-light hint pt-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('product_supported_format'))), _c('br'), _vm._v(" " + _vm._s(_vm.$t('product_file_size')) + " ")])]), _c(VCol, {
    staticClass: "py-0"
  }, [_c(VForm, {
    ref: "product-form",
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c(VRow, [_c(VCol, {
    staticClass: "pt-0",
    attrs: {
      "cols": _vm.product.id ? 6 : 12
    }
  }, [_c(VTextField, {
    attrs: {
      "name": "name",
      "persistent-hint": "",
      "label": _vm.$t('product_name'),
      "hint": _vm.$t('product_name_hint'),
      "rules": _vm.rules.name,
      "error-messages": _vm.errorMessages.name,
      "required": ""
    },
    on: {
      "focus": function focus($event) {
        return _vm.clearErrors('name');
      }
    },
    model: {
      value: _vm.productForm.name,
      callback: function callback($$v) {
        _vm.$set(_vm.productForm, "name", $$v);
      },
      expression: "productForm.name"
    }
  })], 1), _c(VCol, {
    staticClass: "pt-0",
    attrs: {
      "cols": _vm.product.id ? 6 : 12
    }
  }, [_c(VTextField, {
    attrs: {
      "name": "sku",
      "persistent-hint": "",
      "label": _vm.$t('product_sku'),
      "hint": _vm.$t('product_sku_hint'),
      "rules": _vm.rules.sku,
      "error-messages": _vm.errorMessages.sku,
      "required": ""
    },
    on: {
      "focus": function focus($event) {
        return _vm.clearErrors('sku');
      }
    },
    model: {
      value: _vm.productForm.sku,
      callback: function callback($$v) {
        _vm.$set(_vm.productForm, "sku", $$v);
      },
      expression: "productForm.sku"
    }
  })], 1), _c(VCol, {
    staticClass: "pt-0",
    attrs: {
      "cols": "11"
    }
  }, [_c(VTextField, {
    ref: "product-url",
    attrs: {
      "validate-on-blur": "",
      "name": "url",
      "persistent-hint": "",
      "rules": _vm.rules.client_url,
      "hint": _vm.$t('product_url_hint'),
      "label": _vm.$t('product_url')
    },
    on: {
      "blur": _vm.onBlurUrl,
      "focus": _vm.onFocusUrl
    },
    model: {
      value: _vm.productForm.client_url,
      callback: function callback($$v) {
        _vm.$set(_vm.productForm, "client_url", $$v);
      },
      expression: "productForm.client_url"
    }
  })], 1), _c(VCol, {
    staticClass: "pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c(VAutocomplete, {
    attrs: {
      "items": _vm.brands,
      "item-text": "name",
      "item-value": "id",
      "label": "Brand",
      "rules": _vm.rules.brand
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', [_vm._v(" [" + _vm._s(item.reference) + "] " + _vm._s(item.name) + " ")])];
      }
    }, {
      key: "item",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', [_vm._v(" [" + _vm._s(item.reference) + "] " + _vm._s(item.name) + " ")])];
      }
    }]),
    model: {
      value: _vm.productForm.brand,
      callback: function callback($$v) {
        _vm.$set(_vm.productForm, "brand", $$v);
      },
      expression: "productForm.brand"
    }
  })], 1), _vm.isAvailable('qrmanager:product:description') && _vm.product.id ? _c(VCol, {
    staticClass: "pt-0",
    attrs: {
      "cols": "12"
    }
  }, [_c(VTextarea, {
    attrs: {
      "counter": "500",
      "label": _vm.$t('description'),
      "persistent-hint": "",
      "hint": _vm.$t('description_hint'),
      "rules": _vm.rules.description
    },
    model: {
      value: _vm.productForm.description,
      callback: function callback($$v) {
        _vm.$set(_vm.productForm, "description", $$v);
      },
      expression: "productForm.description"
    }
  })], 1) : _vm._e()], 1)], 1)], 1)], 1), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.handleClose();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('close')))]), _c(VBtn, {
    attrs: {
      "text": "",
      "color": "primary",
      "loading": _vm.savingProduct
    },
    on: {
      "click": function click($event) {
        return _vm.handleSave();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('save')))])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }