var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "759px"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h6 grey lighten-2"
  }, [_vm._v(_vm._s(_vm.$t('qrcode_edit_gs1_link')))]), _c('v-card-text', {
    staticClass: "mt-4 black--text"
  }, [_vm._v(_vm._s(_vm.$t('qrcode_modal_content')))]), _c('v-card-text', {
    staticClass: "font-bold red--text"
  }, [_vm._v(_vm._s(_vm.$t('qrcode_modal_sub_content')))]), _c('v-card-actions', {
    staticClass: "pa-4 px-8 mt-5 pb-6"
  }, [_c('v-spacer'), _c('v-btn', {
    staticClass: "blue--text mr-16",
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeModal
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('cancel')) + " ")]), _c('v-btn', {
    staticClass: "primary white--text",
    on: {
      "click": _vm.handleConfirmBtnClick
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('qrcode_s1_modal_understand_change')) + " ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }