var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tooltip",
    on: {
      "mouseover": function ($event) {
        _vm.active = true;
      },
      "mouseleave": function ($event) {
        _vm.active = false;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "#ccc200"
    }
  }, [_vm._v("mdi-information")]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.active,
      expression: "active"
    }],
    staticClass: "tooltip-ctn pa-4",
    class: this.position
  }, [_c('span', {
    ref: "tooltip-content",
    domProps: {
      "innerHTML": _vm._s(_vm.content)
    }
  })])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }