import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {
  var _this$selectedTexts$t, _this$selectedTexts$t2, _this$selectedTexts$b, _this$selectedTexts$b2, _this$selectedTexts$l, _this$selectedTexts$l2, _this$selectedTexts$r, _this$selectedTexts$r2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "download-ctn"
  }, [_vm.mode == 'codes' ? _c(VBtn, {
    attrs: {
      "color": "primary",
      "raised": "",
      "disabled": !_vm.count
    },
    on: {
      "click": _vm.open
    }
  }, [_vm._v(_vm._s(_vm.$t('download')))]) : _vm._e(), _vm.mode == 'single' ? _c(VTooltip, {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c(VBtn, _vm._g(_vm._b({
          attrs: {
            "color": "primary",
            "icon": ""
          },
          on: {
            "click": _vm.open
          }
        }, 'v-btn', attrs, false), on), [_c(VIcon, {
          attrs: {
            "color": "#15bbf0"
          }
        }, [_vm._v("mdi-qrcode")])], 1)];
      }
    }], null, false, 37656637)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('preview_and_download')))])]) : _vm._e(), _vm.mode == 'product' ? _c(VTooltip, {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c(VBtn, _vm._g(_vm._b({
          attrs: {
            "disabled": _vm.isDisabled,
            "color": "primary",
            "icon": ""
          },
          on: {
            "click": _vm.open
          }
        }, 'v-btn', attrs, false), on), [_c(VIcon, {
          attrs: {
            "color": "#15bbf0"
          }
        }, [_vm._v("mdi-download")])], 1)];
      }
    }], null, false, 1613264683)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('download')))])]) : _vm._e(), _c(VDialog, {
    attrs: {
      "width": "600"
    },
    model: {
      value: _vm.deleteQrDialog,
      callback: function callback($$v) {
        _vm.deleteQrDialog = $$v;
      },
      expression: "deleteQrDialog"
    }
  }, [_c(VCard, [_c(VCardTitle, [_vm._v(" " + _vm._s(_vm.$t('delete_confirmation')) + " ")]), _c(VCardText, {
    staticClass: "my-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('qr_template_delete_confirmation')) + " ")]), _c(VCardActions, [_c('div', {
    staticClass: "mr-auto"
  }), _c(VBtn, {
    staticClass: "mr-2",
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.deleteQrDialog = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('cancel')))]), _c(VBtn, {
    staticClass: "mr-2",
    attrs: {
      "color": "red",
      "loading": _vm.deletingTemplate,
      "text": ""
    },
    on: {
      "click": _vm.deleteQrTemplate
    }
  }, [_vm._v(_vm._s(_vm.$t('delete')))])], 1)], 1)], 1), _vm.dialog ? _c(VDialog, {
    attrs: {
      "scrollable": "",
      "width": _vm.mode === 'single' || _vm.codes.length === 1 ? 800 : 650,
      "persistent": ""
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c(VCard, {
    staticClass: "pb-2"
  }, [_c(VCardTitle, [_vm.mode != 'single' ? _c('span', [_vm._v(_vm._s(_vm.$t('download_format')))]) : _vm._e(), _vm.mode == 'single' ? _c('span', [_vm._v(_vm._s(_vm.$t('preview_and_download')))]) : _vm._e(), _c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(_vm._s(_vm.$t('cancel')))])], 1), _c(VCardText, {
    staticClass: "pa-0"
  }, [_c(VStepper, {
    model: {
      value: _vm.step,
      callback: function callback($$v) {
        _vm.step = $$v;
      },
      expression: "step"
    }
  }, [_c(VStepperHeader, [_vm.hasPrefixOptions ? _c(VStepperStep, {
    attrs: {
      "step": "1"
    }
  }, [_vm._v(_vm._s(_vm.$t('select_url_prefix')))]) : _vm._e(), _vm.hasPrefixOptions ? _c(VDivider) : _vm._e(), _c(VStepperStep, {
    attrs: {
      "step": _vm.hasPrefixOptions ? 2 : 1
    }
  }, [_vm._v(_vm._s(_vm.$t('designing_your_qr_code')))]), _c(VDivider), _c(VStepperStep, {
    attrs: {
      "step": _vm.hasPrefixOptions ? 3 : 2
    }
  }, [_vm._v(_vm._s(_vm.$t('file_format')))])], 1), _c(VStepperItems, [_vm.hasPrefixOptions ? _c(VStepperContent, {
    staticClass: "prefixes-ctn",
    attrs: {
      "step": "1"
    }
  }, [_c(VRow, {
    staticClass: "ma-0"
  }, [_c(VCol, [_c('div', {
    staticClass: "d-flex flex-column justify-center"
  }, [_c(VSelect, {
    attrs: {
      "items": _vm.formattedPrefixes,
      "label": _vm.$t('all_url_prefixes'),
      "item-text": "displayText",
      "item-value": "prefix",
      "persistent-hint": "",
      "hint": _vm.hasStoredPrefix ? _vm.$t('prefix_used_last_download') : _vm.$t('prefix_will_be_associated_with_codes_selected')
    },
    on: {
      "change": _vm.onPrefixChange
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "prefix"
        }, [_vm._v(_vm._s(item.displayText))])];
      }
    }], null, false, 3836696457),
    model: {
      value: _vm.selectedPrefix,
      callback: function callback($$v) {
        _vm.selectedPrefix = $$v;
      },
      expression: "selectedPrefix"
    }
  }), _vm.showPrefixConflictMessage && !_vm.hasObsoletePrefix ? _c('span', {
    staticClass: "color-error mt-2"
  }, [_vm._v(_vm._s(_vm.$t('prefix_conflict_message')))]) : _vm._e(), _vm.hasObsoletePrefix && _vm.codes.length === 1 ? _c('span', {
    staticClass: "color-error mt-2"
  }, [_vm._v(_vm._s(_vm.$t('has_deleted_prefix')))]) : _vm._e(), _vm.hasObsoletePrefix && _vm.codes.length > 1 ? _c('span', {
    staticClass: "color-error mt-2"
  }, [_vm._v(_vm._s(_vm.$t('have_deleted_prefix')))]) : _vm._e(), _vm.showPrefixRequiredMessage ? _c('span', {
    staticClass: "color-error mt-2"
  }, [_vm._v(_vm._s(_vm.$t('prefix_required')))]) : _vm._e()], 1)])], 1)], 1) : _vm._e(), _c(VStepperContent, {
    staticClass: "pb-0",
    attrs: {
      "step": _vm.hasPrefixOptions ? 2 : 1
    }
  }, [_c(VRow, {
    staticClass: "ma-0",
    attrs: {
      "align": "start"
    }
  }, [_c(VCol, {
    staticClass: "pr-0"
  }, [_c('div', {
    staticClass: "scrollable-content"
  }, [_vm.qrTemplates.length ? _c('div', [_c('div', {
    staticClass: "label font-weight-bold pb-1"
  }, [_vm._v(_vm._s(_vm.$t('qr_code_templates')))]), _c(VSelect, {
    attrs: {
      "items": _vm.qrTemplates,
      "item-value": "id",
      "return-object": "",
      "clearable": "",
      "solo": ""
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "select-qr-preview mr-3"
        }, [_c('img', {
          staticClass: "template-qr-preview",
          attrs: {
            "src": _vm.getTemplatePreviewUrl(item)
          }
        }), item.image ? _c('img', {
          staticClass: "template-qr-logo",
          style: {
            backgroundColor: item.background
          },
          attrs: {
            "src": _vm.toDataUrl(item.image)
          }
        }) : _vm._e()]), _c(VListItemContent, [_c(VListItemTitle, {
          domProps: {
            "innerHTML": _vm._s(item.filename)
          }
        }), _c(VListItemSubtitle, {
          domProps: {
            "innerHTML": _vm._s("".concat(item.color, " - ").concat(item.background))
          }
        })], 1)];
      }
    }, {
      key: "item",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "select-qr-preview mr-3"
        }, [_c('img', {
          staticClass: "template-qr-preview",
          attrs: {
            "src": _vm.getTemplatePreviewUrl(item)
          }
        }), item.image ? _c('img', {
          staticClass: "template-qr-logo",
          style: {
            backgroundColor: item.background
          },
          attrs: {
            "src": _vm.toDataUrl(item.image)
          }
        }) : _vm._e()]), _c(VListItemContent, [_c(VListItemTitle, {
          domProps: {
            "innerHTML": _vm._s(item.filename)
          }
        }), _c(VListItemSubtitle, {
          domProps: {
            "innerHTML": _vm._s("".concat(item.color, " - ").concat(item.background))
          }
        })], 1), _c(VListItemAction, [_c(VBtn, {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.deleteQrTemplateConfirm(item);
            }
          }
        }, [_c(VIcon, {
          attrs: {
            "color": "red lighten-1"
          }
        }, [_vm._v("mdi-delete")])], 1)], 1)];
      }
    }], null, false, 3090673912),
    model: {
      value: _vm.selectedTemplate,
      callback: function callback($$v) {
        _vm.selectedTemplate = $$v;
      },
      expression: "selectedTemplate"
    }
  })], 1) : _vm._e(), _c(VRow, [_c(VCol, [_c('div', {
    staticClass: "label font-weight-bold pb-1"
  }, [_vm._v(_vm._s(_vm.$t('qr_color')) + ":")]), _c('color-input', {
    attrs: {
      "color": _vm.form.color
    },
    on: {
      "update:color": function updateColor($event) {
        return _vm.$set(_vm.form, "color", $event);
      }
    }
  })], 1), _c(VCol, [_c('div', {
    staticClass: "label font-weight-bold pb-1"
  }, [_vm._v(_vm._s(_vm.$t('background_color')) + ":")]), _c('color-input', {
    attrs: {
      "color": _vm.form.background
    },
    on: {
      "update:color": function updateColor($event) {
        return _vm.$set(_vm.form, "background", $event);
      }
    }
  })], 1)], 1), _vm.hasConstrastIssue ? _c('div', {
    staticClass: "color-error pt-5 d-flex"
  }, [_c(VIcon, {
    staticClass: "mr-3",
    attrs: {
      "color": "red"
    }
  }, [_vm._v("mdi-alert")]), _c('div', {
    staticClass: "font-weight-bold",
    domProps: {
      "innerHTML": _vm._s(_vm.$t('qr_contrast_warning'))
    }
  })], 1) : _vm._e(), _vm.enableQrTextSettings ? _c('div', [_c('div', {
    staticClass: "py-5"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t('text_around_qr_title')))]), _c('div', {
    staticClass: "description"
  }, [_vm._v(" " + _vm._s(_vm.$t('text_around_qr_hint')) + " "), _c('a', {
    on: {
      "click": function click($event) {
        return _vm.goToHelpCenter('https://help.scantrust.com/hc/en-us/articles/11085111089180-e-label-Editor-QR-Codes-Downloading-e-label-QR-codes-in-Bulk-Enterprise');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('learn_more')))])]), _vm.filteredTexts.length ? _c('div', [_c('div', {
    staticClass: "label font-weight-bold mt-2 pb-1"
  }, [_vm._v(_vm._s(_vm.$t('text_color')) + ":")]), _c('color-input', {
    staticClass: "px-1",
    attrs: {
      "color": _vm.form.text.color
    },
    on: {
      "update:color": function updateColor($event) {
        return _vm.$set(_vm.form.text, "color", $event);
      }
    }
  })], 1) : _vm._e(), _c('div', {
    key: "renderKey-".concat(_vm.renderKey)
  }, _vm._l(_vm.filteredTexts, function (item) {
    return _c(VRow, {
      key: "textfield-".concat(item.key),
      staticClass: "ma-0 mt-5 my-2"
    }, [_c(VRow, {
      staticClass: "text-row px-1 ma-0"
    }, [_c(VCol, {
      staticClass: "pa-0 mr-2",
      attrs: {
        "cols": "3"
      }
    }, [_c(VSelect, {
      staticClass: "text-select",
      attrs: {
        "solo": "",
        "value": item.key,
        "items": _vm.textPositions,
        "label": _vm.$t('position'),
        "item-text": "description",
        "item-value": "key",
        "hide-details": "",
        "dense": ""
      },
      on: {
        "change": function change($event) {
          return _vm.onTextPositionChange($event, item.key);
        }
      }
    })], 1), _c(VCol, {
      staticClass: "pa-0 mr-2"
    }, [_c(VCombobox, {
      staticClass: "ma-0",
      attrs: {
        "maxlength": "50",
        "solo": "",
        "hide-details": "",
        "dense": "",
        "items": _vm.dataFields,
        "item-text": "description",
        "return-object": ""
      },
      on: {
        "input": function input($event) {
          return _vm.handleTextInput($event, item.key);
        }
      },
      model: {
        value: _vm.selectedTexts[item.key],
        callback: function callback($$v) {
          _vm.$set(_vm.selectedTexts, item.key, $$v);
        },
        expression: "selectedTexts[item.key]"
      }
    })], 1), _c(VCol, {
      staticClass: "pa-0",
      attrs: {
        "cols": "1"
      }
    }, [_c(VBtn, {
      attrs: {
        "color": "red",
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.deleteTextField(item.key);
        }
      }
    }, [_c(VIcon, [_vm._v("mdi-close-circle")])], 1)], 1)], 1)], 1);
  }), 1), _vm.hasAvailableTextPosition ? _c(VBtn, {
    staticClass: "mt-3",
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.addTextField();
      }
    }
  }, [_c(VIcon, [_vm._v("$plus")]), _vm._v(" " + _vm._s(_vm.$t('add_data')) + " ")], 1) : _vm._e()], 1), _c('div', [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t('icon_options_title')))]), _c('div', {
    staticClass: "description"
  }, [_vm._v(_vm._s(_vm.$t('icon_options_hint')))]), _c(VRadioGroup, {
    staticClass: "pa-2",
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.iconSetting,
      callback: function callback($$v) {
        _vm.iconSetting = $$v;
      },
      expression: "iconSetting"
    }
  }, [_c(VRadio, {
    attrs: {
      "value": "none",
      "label": _vm.$t('no_icon')
    }
  }), _c(VRadio, {
    attrs: {
      "value": "inside",
      "label": _vm.$t('icon_inside_of_qr')
    }
  }), _c(VRadio, {
    attrs: {
      "value": "outside",
      "label": _vm.$t('icon_outside_of_qr')
    }
  })], 1), _vm.iconSetting === 'outside' ? _c('div', {
    staticClass: "outside-icon-options mt-2 ml-7 mb-5"
  }, [_c(VSelect, {
    staticClass: "text-select",
    attrs: {
      "solo": "",
      "items": _vm.textPositions,
      "label": _vm.$t('position'),
      "item-text": "description",
      "item-value": "key",
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.iconPosition,
      callback: function callback($$v) {
        _vm.iconPosition = $$v;
      },
      expression: "iconPosition"
    }
  })], 1) : _vm._e()], 1)]) : _vm._e()], 1)]), _vm.mode === 'single' || _vm.codes.length === 1 ? _c(VCol, {
    staticClass: "flex-grow-0 preview-col pr-0",
    attrs: {
      "align-self": "center"
    }
  }, [_c('div', {
    staticClass: "label font-weight-bold pb-1 text-center"
  }, [_vm._v(_vm._s(_vm.$t('qr_preview')))]), _c('div', {
    staticClass: "qr-preview d-flex flex-column justify-center"
  }, [_c('div', {
    staticClass: "qr-preview-ctn"
  }, [_c('img', {
    staticClass: "qr",
    attrs: {
      "src": _vm.previewUrl
    }
  }), _vm.form.image && _vm.iconSetting === 'inside' ? _c('img', {
    staticClass: "logo",
    class: {
      'top': (_this$selectedTexts$t = this.selectedTexts['top']) === null || _this$selectedTexts$t === void 0 ? void 0 : (_this$selectedTexts$t2 = _this$selectedTexts$t.text) === null || _this$selectedTexts$t2 === void 0 ? void 0 : _this$selectedTexts$t2.length,
      'bottom': (_this$selectedTexts$b = this.selectedTexts['bottom']) === null || _this$selectedTexts$b === void 0 ? void 0 : (_this$selectedTexts$b2 = _this$selectedTexts$b.text) === null || _this$selectedTexts$b2 === void 0 ? void 0 : _this$selectedTexts$b2.length,
      'left': (_this$selectedTexts$l = this.selectedTexts['left']) === null || _this$selectedTexts$l === void 0 ? void 0 : (_this$selectedTexts$l2 = _this$selectedTexts$l.text) === null || _this$selectedTexts$l2 === void 0 ? void 0 : _this$selectedTexts$l2.length,
      'right': (_this$selectedTexts$r = this.selectedTexts['right']) === null || _this$selectedTexts$r === void 0 ? void 0 : (_this$selectedTexts$r2 = _this$selectedTexts$r.text) === null || _this$selectedTexts$r2 === void 0 ? void 0 : _this$selectedTexts$r2.length
    },
    style: {
      backgroundColor: _vm.form.background
    },
    attrs: {
      "src": _vm.toDataUrl(_vm.form.image)
    }
  }) : _vm._e()]), _c('div', {
    staticClass: "mt-auto mb-2"
  }), _c('ImageCropper', {
    attrs: {
      "ratio": 1,
      "label": _vm.$t('upload_logo'),
      "input-key": "qr-logo"
    },
    on: {
      "image-selected": _vm.onImageSelect
    }
  }), _c('div', [_vm.form.image ? _c(VBtn, {
    staticClass: "inset mt-3",
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.deleteLogo
    }
  }, [_vm._v(_vm._s(_vm.$t('remove_logo')))]) : _vm._e(), !_vm.form.image ? _c(VBtn, {
    staticClass: "inset mt-3",
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.addDefaultLogo
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('qrcode_add_default_logo')) + " ")]) : _vm._e()], 1)], 1)]) : _vm._e()], 1)], 1), _c(VStepperContent, {
    attrs: {
      "step": _vm.hasPrefixOptions ? 3 : 2
    }
  }, [_c(VRow, [_c(VCol, [_c('div', {
    staticClass: "mb-5"
  }, [_c('div', {
    staticClass: "label font-weight-bold pb-1"
  }, [_vm._v(_vm._s(_vm.$t('choose_file_format')) + ":")]), _c('div', _vm._l(_vm.filteredFormats, function (format) {
    return _c('span', {
      key: format,
      staticClass: "pill font-weight-bold mr-2",
      class: {
        'selected': format === _vm.form.format
      },
      on: {
        "click": function click($event) {
          return _vm.selectFormat(format);
        }
      }
    }, [_vm._v(" " + _vm._s(format) + " ")]);
  }), 0)]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasDPI,
      expression: "hasDPI"
    }],
    staticClass: "mb-5"
  }, [_c('div', {
    staticClass: "label font-weight-bold pb-1"
  }, [_vm._v(_vm._s(_vm.$t('choose_dpi')) + ":")]), _c('div', _vm._l(_vm.dpis, function (dpi) {
    return _c('span', {
      key: dpi,
      staticClass: "pill font-weight-bold mr-2",
      class: {
        'selected': dpi === _vm.form.dpi
      },
      on: {
        "click": function click($event) {
          return _vm.selectDPI(dpi);
        }
      }
    }, [_vm._v(" " + _vm._s(dpi) + "dpi ")]);
  }), 0)]), _c('div', {
    staticClass: "mb-5"
  }, [_c('div', {
    staticClass: "label font-weight-bold pb-1"
  }, [_vm._v(_vm._s(_vm.$t('qr_dimensions_mm')))]), _c('div', {
    staticClass: "hint"
  }, [_vm._v(_vm._s(_vm.$t('qr_dimensions_hint')))]), _c(VSlider, {
    staticClass: "pt-10 px-2",
    attrs: {
      "step": "5",
      "max": "30",
      "min": "10",
      "thumb-label": "always",
      "ticks": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.qrSize,
      callback: function callback($$v) {
        _vm.qrSize = $$v;
      },
      expression: "qrSize"
    }
  }), _c(VRow, {
    staticClass: "ma-0",
    attrs: {
      "justify": "space-between"
    }
  }, [_c('div', {
    staticClass: "size-hint font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('small'))), _c('br'), _vm._v("(10mm) ")]), _c('div', {
    staticClass: "size-hint font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('medium'))), _c('br'), _vm._v("(20mm) ")]), _c('div', {
    staticClass: "size-hint font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('large'))), _c('br'), _vm._v("(30mm) ")])])], 1), _c('div', {
    staticClass: "mb-5"
  }, [_c('div', {
    staticClass: "label font-weight-bold pb-1"
  }, [_vm._v(_vm._s(_vm.$t('naming_convention')) + ":")]), _c(VTextField, {
    ref: "filename",
    attrs: {
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('span', [_vm._v(" ." + _vm._s(_vm.form.format) + " ")])];
      },
      proxy: true
    }], null, false, 1076406608),
    model: {
      value: _vm.form.filename,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "filename", $$v);
      },
      expression: "form.filename"
    }
  }), _c('div', {
    staticClass: "hint"
  }, [_vm._v(" " + _vm._s(_vm.$t('naming_convention_hint')) + " ")]), _c('div', _vm._l(_vm.generalFields, function (field) {
    return _c('span', {
      key: "general-field-".concat(field),
      staticClass: "pill font-weight-bold mr-2 mb-2",
      class: {
        'selected': _vm.isGeneralFieldSelected(field)
      },
      on: {
        "click": function click($event) {
          return _vm.toggleGeneralField(field);
        }
      }
    }, [_vm._v(_vm._s(field))]);
  }), 0), _c('div', {
    staticClass: "hint"
  }, [_vm._v(_vm._s(_vm.$t('tags')) + ":")]), _c('div', _vm._l(_vm.activeScmFields, function (field) {
    return _c('span', {
      key: field.key,
      staticClass: "pill font-weight-bold mr-2 mb-2",
      class: {
        'selected': _vm.isTagSelected(field)
      },
      on: {
        "click": function click($event) {
          return _vm.toggleTag(field);
        }
      }
    }, [_vm._v(_vm._s(field.key === 'scantrust_upload_batch' ? _vm.$t('upload_batch') : field.key))]);
  }), 0)], 1)])], 1)], 1)], 1)], 1)], 1), _c(VCardActions, [_vm.step === 1 ? _c(VRow, {
    staticClass: "dialog-footer pa-3"
  }, [_c('div', {
    staticClass: "mr-auto"
  }), _c(VBtn, {
    staticClass: "mr-2",
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(_vm._s(_vm.$t('cancel')))]), _c(VBtn, {
    staticClass: "mr-2",
    on: {
      "click": _vm.handleFirstStepNext
    }
  }, [_vm._v(_vm._s(_vm.$t('next')))])], 1) : _vm._e(), _vm.hasPrefixOptions && _vm.step === 2 ? _c(VRow, {
    staticClass: "dialog-footer pa-3"
  }, [_c('div', {
    staticClass: "mr-auto"
  }), _c(VBtn, {
    staticClass: "mr-2",
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.step = 1;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('back')))]), _c(VBtn, {
    staticClass: "mr-2",
    on: {
      "click": function click($event) {
        _vm.step = 3;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('next')))])], 1) : _vm._e(), _vm.hasPrefixOptions && _vm.step === 3 || !_vm.hasPrefixOptions && _vm.step === 2 ? _c(VRow, {
    staticClass: "dialog-footer pa-3 align-center"
  }, [_vm.templateToUpdate || _vm.templateToCreate ? _c(VCheckbox, {
    staticClass: "ml-2",
    attrs: {
      "hide-details": "",
      "dense": "",
      "label": _vm.$t('save_as_qr_template')
    },
    model: {
      value: _vm.createTemplateToggle,
      callback: function callback($$v) {
        _vm.createTemplateToggle = $$v;
      },
      expression: "createTemplateToggle"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "mr-auto"
  }), _c(VBtn, {
    staticClass: "mr-2",
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.hasPrefixOptions ? _vm.step = 2 : _vm.step = 1;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('back')))]), _c(VBtn, {
    staticClass: "mr-2",
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.count > 1000 || _vm.codes.length > 1000
    },
    on: {
      "click": _vm.download
    }
  }, [_vm._v(_vm._s(_vm.$t('download')))])], 1) : _vm._e()], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }