<template>
  <div class="simulation-parameter">
    <span class="param" v-if="type === 'location_country_code'">
      {{name}} - {{getCountry(value)?.name}}
    </span>

    <span class="param" v-else-if="type === 'device_language'">
      {{name}} - {{getLanguage(value)?.name}}
    </span>

    <span class="param" v-else-if="type === 'scan_activation_status'">
      {{name}} - {{getScanActivationStatus(value)?.description}}
    </span>

    <span class="param" v-else-if="type === 'calculated_auth_result'">
      {{name}} - {{getCalculatedAuthResult(value)?.description}}
    </span>

    <span class="param" v-else-if="type === 'scan_app_slug'">
      {{name}} - {{getScanApplication(value)?.name}}
    </span>

    <span class="param" v-else-if="type === 'scan_region_key'">
      {{name}} - {{getCustomRegion(value)?.name}}
    </span>

    <span class="param" v-else-if="type === 'scan_blacklist_reason'">
      {{name}} - {{getBlacklistReason(value)?.description}}
    </span>

    <span class="param" v-else>
      {{name}} - {{value}}
    </span>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import Countries from '@/utils/countries'
import Languages from '@/utils/languages'

export default {
  name: 'SimulationParams',
  props: ['type', 'value', 'name'],
  computed: {
    ...mapGetters(['activationStatuses', 'apps', 'customRegions', 'scanResults', 'computedAuthResults', 'scanReasons', 'blacklistReasons'])
  },
  watch: {

    type (newVal, oldVal) {
      this.search = ''
      if (this.operator === 'in' && oldVal) {
        this.$emit('update:value', [])
      } else if (oldVal) {
        this.$emit('update:value', '')
      }
    },

    value (newVal, oldVal) {
      if (this.mode === 'preview') {
        this.autocompleteValue = newVal
      }
      this.$forceUpdate()
    }

  },

  data () {
    return {
      autocompleteValue: null,
      comboBoxValue: null,
      countries: Countries,
      languages: Languages,
      products: [],
      brands: []
    }
  },
  methods: {

    getCountry (key) {
      return this.countries.find((country) => {
        return country.code === key
      })
    },

    getLanguage (key) {
      return this.languages.find((lang) => {
        return lang.code === key
      })
    },

    getScanApplication (key) {
      return this.apps.find((app) => {
        return app.slug === key
      })
    },

    getCustomRegion (key) {
      return this.customRegions.find((region) => {
        return region.key === key
      })
    },

    getCalculatedAuthResult (key) {
      return this.computedAuthResults.find((result) => {
        return result.value === key
      })
    },

    getScanActivationStatus (key) {
      return this.activationStatuses.find((status) => {
        return parseInt(status.value) === parseInt(key)
      })
    },

    getBlacklistReason (key) {
      return this.blacklistReasons.find((reason) => {
        return parseInt(reason.value) === parseInt(key)
      })
    },

    async loadProducts () {
      this.products = await this.$store.dispatch('searchProducts', { offset: 0, limit: 10, search: this.search })
    },

    async loadBrands () {
      this.brands = await this.$store.dispatch('searchBrands', { offset: 0, limit: 10, search: this.search })
    }
  },
  created () {
    if (!this.activationStatuses.length) {
      this.$store.dispatch('loadActivationStatuses')
    }

    if (!this.scanResults.length) {
      this.$store.dispatch('loadScanResults')
    }

    if (!this.apps.length) {
      this.$store.dispatch('loadAppsList')
    }

    if (!this.customRegions.length) {
      this.$store.dispatch('loadCustomRegions')
    }

    if (!this.computedAuthResults.length) {
      this.$store.dispatch('loadComputedAuthResults')
    }

    if (!this.blacklistReasons.length) {
      this.$store.dispatch('loadBlacklistReasons')
    }

    if (this.value) {
      this.autocompleteValue = this.value
      this.comboBoxValue = this.value
    }
  }
}

</script>

<style lang="sass" scoped>

.param
  background: white
  border-radius: 17px
  padding: 4px 8px
  text-align: left
  font-size: 13px
  border: 1px solid rgba(0, 0, 0, 0.5)

</style>
