var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "scans-search"
  }, [_c('v-menu', {
    attrs: {
      "bottom": "",
      "full-width": "",
      "close-on-content-click": false
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('button', _vm._g(_vm._b({
          staticClass: "open-search-btn",
          attrs: {
            "color": "primary",
            "dark": ""
          },
          on: {
            "click": function ($event) {
              return _vm.open();
            }
          }
        }, 'button', attrs, false), on), [_c('div', {
          staticClass: "d-flex"
        }, [_vm._v(" Filter by product, tags, ID...etc "), _c('v-spacer'), _c('v-icon', [_vm._v("mdi-magnify")])], 1)])];
      }
    }]),
    model: {
      value: _vm.searchMenu,
      callback: function ($$v) {
        _vm.searchMenu = $$v;
      },
      expression: "searchMenu"
    }
  }, [_c('div', {
    staticClass: "search-input"
  }, [_c('v-form', {
    attrs: {
      "autocomplete": "off"
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.initSearch.apply(null, arguments);
      }
    }
  }, [_c('v-text-field', {
    ref: "scan-search-input",
    attrs: {
      "label": _vm.searchLabel,
      "solo": "",
      "shaped": "",
      "hide-details": "",
      "autocomplete": "off",
      "append-icon": "mdi-magnify"
    },
    on: {
      "keyup": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "delete", [8, 46], $event.key, ["Backspace", "Delete", "Del"])) return null;
        return _vm.onDelete.apply(null, arguments);
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])) return null;
        return _vm.onArrowUp.apply(null, arguments);
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "down", 40, $event.key, ["Down", "ArrowDown"])) return null;
        return _vm.onArrowDown.apply(null, arguments);
      }]
    },
    scopedSlots: _vm._u([{
      key: "prepend-inner",
      fn: function () {
        return [_vm.selectedTag.name ? _c('div', [_c('v-chip', {
          attrs: {
            "text-color": "#0789b2"
          }
        }, [_vm._v(_vm._s(_vm.selectedTag.name) + ":")])], 1) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.searchText,
      callback: function ($$v) {
        _vm.searchText = $$v;
      },
      expression: "searchText"
    }
  })], 1)], 1), _c('div', {
    staticClass: "search-results-ctn"
  }, [!_vm.selectedTag.name ? _c('div', [_vm.filteredScmFields.length > 0 ? _c('div', [_c('div', {
    staticClass: "search-hint"
  }, [_vm._v("narrow your search:")]), _c('div', {
    staticClass: "tags-ctn"
  }, _vm._l(_vm.filteredScmFields, function (tag, index) {
    return _c('v-chip', {
      key: tag.key,
      staticClass: "tag ml-2 my-1",
      class: {
        'focussed': _vm.focussedIndex == index
      },
      attrs: {
        "text-color": "#0789b2"
      },
      on: {
        "click": function ($event) {
          return _vm.selectTag(tag);
        }
      }
    }, [_vm._v(_vm._s(tag.name))]);
  }), 1)]) : _vm._e(), _vm.filteredProducts.length > 0 ? _c('div', [_c('div', {
    staticClass: "search-hint"
  }, [_vm._v("Products:")]), _c('div', {
    staticClass: "tags-ctn"
  }, [_vm._l(_vm.filteredProducts, function (product, index) {
    return _c('v-chip', {
      key: product.id,
      staticClass: "tag ml-2 my-1",
      class: {
        'focussed': _vm.focussedIndex === _vm.filteredScmFields.length + index
      },
      attrs: {
        "text-color": "#0789b2"
      },
      on: {
        "click": function ($event) {
          return _vm.setProductFilter(product);
        }
      }
    }, [_vm._v(_vm._s(product.name) + " - " + _vm._s(product.sku))]);
  }), _vm.filteredProductCount > 5 ? _c('span', {
    staticClass: "pl-2 more-label"
  }, [_vm._v("And " + _vm._s(_vm.filteredProductCount - 5) + " more products")]) : _vm._e()], 2)]) : _vm._e()]) : _vm._e(), _vm.selectedTag.name || _vm.filteredProducts.length === 0 && _vm.filteredScmFields.length === 0 ? _c('div', {
    staticClass: "search-hint"
  }, [_vm._v(" Press enter to search ")]) : _vm._e()])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }