<template>
  <v-card class="no-radius">
    <v-card-text class="pa-2 ma-0">
      <!-- Rule Campaigns -->
      <div class="mt-3 mb-5">
        <div class="section-title">
          <v-icon class="icon flex-grow-0">$bullseye</v-icon>
          <span>Active for campaigns:</span>
        </div>
        <div>
          <div v-if="!rule.campaigns.length">All Campaigns</div>
          <span :class="{ 'text-red': redirectionSimulator && !hasMatchingCampaign(rule) }" v-for="(campaign, index) in rule.campaigns" :key="campaign">
            {{getCampaign(campaign)?.name ? getCampaign(campaign)?.name : `Archived Campaign (ID ${campaign})`}}{{ index < rule.campaigns.length - 1 ? ', ' : '' }}
          </span>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Rule Calendar -->
      <div class="my-3">
        <div class="section-title">
          <v-icon class="icon flex-grow-0">$calendar</v-icon>
          <span>Active period:</span>
        </div>
        <div :class="{ 'text-red' : redirectionSimulator && getRuleStatus(rule) !== 'live' }">
          <span v-if="rule.active_from && rule.active_to">
            Start: {{showLocalTime(rule.active_from)}}<br>End: {{showLocalTime(rule.active_to)}}
          </span>
          <span v-else-if="rule.active_from">
            Active since {{showLocalTime(rule.active_from)}}.
          </span>
          <span v-else-if="rule.active_to && getRuleStatus(rule) == 'expiring'">
            Expiring on {{showLocalTime(rule.active_from)}}.
          </span>
          <span v-else-if="rule.active_to && (getRuleStatus(rule) == 'expired' || status == 'paused')">
            Expired on {{showLocalTime(rule.active_from)}}.
          </span>
          <span v-else>
            Active all the time.
          </span>
          <div v-if="rule.active_from">
            Timezone: {{ getTimezone(rule.timezone).label }}
          </div>
        </div>
      </div>
      <v-divider></v-divider>
      <div class="destinations-ctn my-3">
        <div class="section-title">Destinations:</div>
        <div class="call-to-action" v-if="(!destinations || !destinations.length)">
          <div class="title">Action required:</div>
          <div class="description">
            A functioning rule must define at least 1 Scan Destination. You can add multiple destinations here for the scans that meet with different conditions.
          </div>
          <v-btn @click="newDestination()" color="primary" class="my-2" text large><v-icon class="mr-2" color="primary">mdi-plus-circle-outline</v-icon>Add Destination</v-btn>
        </div>
        <div class="destination-list">
          <draggable class="drop-area" v-model="destinations" group="a">
            <div  v-for="destination in destinations" :key="destination.id">
              <destination-card :selected="isSelected(destination.id)" class="my-4" :rule="rule" :destination="destination" :is-only-destination="destinations && destinations.length == 1"></destination-card>
            </div>
          </draggable>
          <v-btn v-if="destinations && destinations.length" @click="newDestination()" color="primary" class="my-4" text large><v-icon class="mr-2" color="primary">mdi-plus-circle-outline</v-icon>Add Destination</v-btn>
        </div>
      </div>
    </v-card-text>
    <v-dialog v-model="destinationDialog" width="900" scrollable :persistent="true">
      <destination-logic
        :rule="rule"
        :destination="{}"
        @cancel="onDestinationCancel"
        @destination-created="onDestinationCreated"
        >
      </destination-logic>
      <!-- <v-card>
        <v-card-title>Add destination</v-card-title>
        <destination-logic
          :rule="rule"
          :destination="{}"
          @cancel="onDestinationCancel"
          @destination-created="onDestinationCreated"
          >
        </destination-logic>
      </v-card> -->
    </v-dialog>
  </v-card>
</template>

<script>

import Timezones from '@/utils/timezones'
import Utils from '@/utils/utils'
import draggable from 'vuedraggable'
import DestinationLogic from '@/components/redirection/DestinationLogic.vue'
import DestinationCard from '@/components/redirection/DestinationCard'
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'

export default {
  name: 'RulePreview',
  props: ['rule'],
  computed: {
    ...mapGetters(['campaigns', 'simulationResults', 'redirectionSimulator', 'simulationCode']),

    destinations: {
      get () {
        if (this.rule.id) {
          return this.$store.getters.redirectionRuleDestinations(this.rule.id)
        }
        return []
      },
      set (destinations) {
        this.$store.dispatch('updateRulesDestinationPositions', { ruleId: this.rule.id, destinations })
      }
    }
  },
  components: {
    draggable,
    DestinationCard,
    DestinationLogic
  },
  data () {
    return {
      timezones: Timezones,
      destinationDialog: false
    }
  },
  watch: {
    rule: {
      handler (newVal, oldVal) {
        // Reloads destinations if filters are cleared
        if (oldVal?.destinations?.length && !newVal?.destinations?.length) {
          this.loadDestinations()
        }
      },
      deep: true
    }
  },
  methods: {

    hasMatchingCampaign (rule) {
      if (!rule.campaigns) {
        return true
      }
      if (this.simulationResults && this.simulationResults.params) {
        return rule.campaigns.indexOf(this.simulationCode.campaign) > -1
      }
    },

    isSelected (id) {
      return id === parseInt(this.$route.query.destination)
    },

    getCampaign (id) {
      return this.campaigns.find((c) => c.id === id)
    },

    getTimezone (id) {
      return this.timezones.find((t) => t.value === id) || { label: 'No timezone' }
    },

    onDestinationCancel () {
      this.destinationDialog = false
    },

    onDestinationCreated (destination) {
      this.destinationDialog = false
    },

    getRuleStatus (rule) {
      const now = new Date()
      if (rule.is_archived) {
        return 'archived'
      }
      if (rule.is_paused) {
        return 'paused'
      }
      if (rule.active_from) {
        const start = new Date(rule.active_from)
        if (now.getTime() > start.getTime()) {
          return 'expiring'
        } else {
          return 'planned'
        }
      }
      if (rule.active_to) {
        const end = new Date(rule.active_to)
        if (now.getTime() > end.getTime()) {
          return 'expired'
        }
      } else {
        return 'live'
      }
    },

    formatDate (date) {
      return Utils.formatDate(date, false)
    },

    showLocalTime (date) {
      const dateTime = DateTime.fromISO(date, { zone: this.rule.timezone })
      return dateTime.toLocaleString(DateTime.DATETIME_SHORT)
    },

    newDestination () {
      this.destinationDialog = true
      // this.$router.push({ name: 'destination.new', params: { id: this.rule.id, rule: this.rule } })
    },

    async loadDestinations () {
      await this.$store.dispatch('loadRuleDestinations', { ruleId: this.rule.id })
      this.$forceUpdate()
    }
  },
  created () {
    if (this.rule.id) {
      this.loadDestinations()
    }
  }
}

</script>

<style lang="sass" scoped>

.no-radius
  border-radius: 0px 0px 15px 15px
  border: 1px solid #bebebe8c
  border-top: none
  box-shadow: none

.destinations-ctn
  color: black
  .call-to-action
    width: 300px
    text-align: center
    margin-left: auto
    margin-right: auto
  .destination-list
    text-align: center

.text-red
  color: red

.icon
  width: 18px
  margin-top: -2px
  margin-right: 4px
</style>
