import Vue from 'vue'
import VueRouter from 'vue-router'

import BulkUpload from '@/views/BulkUpload'
import Codes from '@/views/Codes'
import Dashboard from '@/views/Dashboard'
import ICCube from '@/views/ICCube'
import LandingPage from '@/views/LandingPage'
import Navigation from '@/views/Navigation'
import ProductCodes from '@/views/ProductCodes'
import ProductElabel from '@/views/ProductElabel'
import Products from '@/views/Products'
import Upgrade from '@/views/Upgrade'
import Redirection from '@/views/Redirection'
import Gallery from '@/views/Gallery.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'dashboard',
    components: {
      tab: Dashboard,
      navigation: Navigation
    },
    props: { navigation: { active: 'DASHBOARD' } }
  },
  {
    path: '/products',
    name: 'products',
    components: {
      tab: Products,
      navigation: Navigation
    },
    props: { navigation: { active: 'PRODUCTS' } },
    children: [
      {
        name: 'products.edit',
        path: ':id',
        components: {
          tab: Products,
          navigation: Navigation
        },
        props: { navigation: { active: 'PRODUCTS' } }
      },
      {
        name: 'products.new',
        path: 'new',
        components: {
          tab: Products,
          navigation: Navigation
        },
        props: { navigation: { active: 'PRODUCTS' } }
      }
    ]
  },
  {
    path: '/bulk-upload',
    name: 'bulk-upload',
    components: {
      tab: BulkUpload,
      navigation: Navigation
    },
    props: { navigation: { active: 'PRODUCTS' } }
  },
  {
    name: 'products.codes',
    path: '/products/:id/codes',
    components: {
      tab: ProductCodes,
      navigation: Navigation
    },
    props: { navigation: { active: 'PRODUCTS' } }
  },
  {
    name: 'products.elabel',
    path: '/products/:id/elabel',
    components: {
      tab: ProductElabel,
      navigation: Navigation
    },
    props: { navigation: { active: 'PRODUCTS' } }
  },
  {
    path: '/codes',
    name: 'codes',
    components: {
      tab: Codes,
      navigation: Navigation
    },
    props: { navigation: { active: 'PRODUCTS' } }
  },
  {
    path: '/landing-page',
    name: 'landing-page',
    components: {
      tab: LandingPage,
      navigation: Navigation
    },
    props: { navigation: { active: 'LANDING-PAGE' } }
  },
  {
    path: '/redirections',
    name: 'redirection',
    components: {
      tab: Redirection,
      navigation: Navigation
    },
    props: { navigation: { active: 'REDIRECTION' } },
    children: [
      {
        name: 'redirection.edit',
        path: ':id',
        components: {
          tab: Redirection,
          navigation: Navigation
        },
        props: { navigation: { active: 'REDIRECTION' } }
      }, {
        name: 'redirection.new',
        path: 'new',
        components: {
          tab: Redirection,
          navigation: Navigation
        },
        props: { navigation: { active: 'REDIRECTION' } }
      }
    ]
  },
  {
    path: '/upgrade',
    name: 'upgrade',
    components: {
      tab: Upgrade,
      navigation: Navigation
    },
    props: { navigation: { active: 'UPGRADE' } }
  },
  {
    path: '/iccube',
    name: 'iccube',
    components: {
      tab: ICCube,
      navigation: Navigation
    }
    // props: { navigation: { active: 'ICCUBE' } }
  },
  {
    path: '/gallery',
    name: 'gallery',
    components: {
      tab: Gallery,
      navigation: Navigation
    },
    props: { navigation: { active: 'GALLERY' } }
  }
]

const router = new VueRouter({
  routes
})

export default router
