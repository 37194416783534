var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.dialog = true;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("$csv")]), _vm._v(_vm._s(_vm.$t('create_more')))], 1), _c('v-dialog', {
    attrs: {
      "width": "900"
    },
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('div', {
    staticClass: "title d-flex align-center"
  }, [_c('div', [_vm._v(_vm._s(_vm.$t('create_codes_via_file')))]), _c('info-tooltip', {
    staticClass: "ml-3",
    attrs: {
      "position": "bottom",
      "content": _vm.$t('code_upload_tooltip')
    }
  })], 1), _c('v-stepper', {
    attrs: {
      "value": _vm.step
    }
  }, [_c('v-stepper-header', [_c('v-stepper-step', {
    attrs: {
      "step": "1"
    }
  }, [_vm._v(_vm._s(_vm.$t('prepare')))]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "step": "2"
    }
  }, [_vm._v(_vm._s(_vm.$t('upload_batch_id')))]), _c('v-divider'), _c('v-stepper-step', {
    attrs: {
      "step": "3"
    }
  }, [_vm._v(_vm._s(_vm.$t('upload')))])], 1), _c('v-stepper-items', [_c('v-stepper-content', {
    attrs: {
      "step": "1"
    }
  }, [_c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "important font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('file_header_must_contain')) + ":")]), _c('v-layout', [_c('v-flex', {
    staticClass: "infos font-weight-medium pr-2"
  }, [_vm._v(" - sku"), _c('br'), _c('br'), _vm._v(" " + _vm._s(_vm.$t('other_supported_columns')) + ":"), _c('br'), _vm._v(" - tags keys "), _c('info-tooltip', {
    attrs: {
      "content": _vm.$t('download_file_hint')
    }
  }), _c('br'), _c('br'), _vm._v(" " + _vm._s(_vm.$t('file_format_hint_1')) + " " + _vm._s(_vm.$t('file_format_hint_2')) + " ")], 1), _c('v-flex', [_c('div', {
    staticClass: "file-upload-ctn"
  }, [_c('v-layout', {
    staticClass: "upload-btn",
    attrs: {
      "align-center": "",
      "justify-center": "",
      "column": ""
    },
    on: {
      "click": function ($event) {
        return _vm.clickOnFileInput($event, 'csv-file');
      }
    }
  }, [!_vm.file || !_vm.file.name ? _c('div', {
    staticClass: "text-xs-center"
  }, [_c('v-icon', {
    attrs: {
      "x-large": "",
      "color": "primary"
    }
  }, [_vm._v("mdi-cloud-upload")]), _c('div', {
    staticClass: "text-uppercase font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('choose_file')))]), _c('div', {
    staticClass: "format-hint"
  }, [_vm._v(" " + _vm._s(_vm.$t('supported_formats')) + ":"), _c('br'), _vm._v(" CSV, XLS, XLSX ")])], 1) : _vm._e(), _vm.file && _vm.file.name ? _c('div', {
    staticClass: "text-xs-center"
  }, [_c('v-icon', {
    attrs: {
      "x-large": "",
      "color": "primary"
    }
  }, [_vm._v("mdi-cloud-upload")]), _c('div', {
    staticClass: "text-uppercase font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('change_file')))]), _c('div', {
    staticClass: "format-hint"
  }, [_vm._v(" " + _vm._s(_vm.file.name) + " ")])], 1) : _vm._e()]), _c('input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "csv-file",
    attrs: {
      "accept": ".csv,.xls,.xlsx",
      "type": "file"
    },
    on: {
      "change": function ($event) {
        return _vm.onFileChange($event);
      }
    }
  })], 1)])], 1), _vm.sheetNames.length > 1 ? _c('v-layout', [_c('v-flex', [_c('v-select', {
    staticClass: "my-4",
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "label": _vm.$t('select_sheet_to_upload'),
      "items": _vm.sheetNames
    },
    on: {
      "change": _vm.findSheetIndex
    },
    model: {
      value: _vm.selectedSheet,
      callback: function ($$v) {
        _vm.selectedSheet = $$v;
      },
      expression: "selectedSheet"
    }
  })], 1)], 1) : _vm._e()], 1), _c('v-layout', {
    staticClass: "footer"
  }, [_c('v-flex', {
    attrs: {
      "grow": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.downloadTemplate('csv');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('download_file_template', {
    format: 'CSV'
  }))), _c('v-icon', {
    staticClass: "ml-2"
  }, [_vm._v("mdi-cloud-download")])], 1), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.downloadTemplate('xlsx');
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('download_file_template', {
    format: 'XLSX'
  }))), _c('v-icon', {
    staticClass: "ml-2"
  }, [_vm._v("mdi-cloud-download")])], 1)], 1), _c('v-flex', {
    attrs: {
      "shrink": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.close();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('cancel')))]), _c('v-btn', {
    attrs: {
      "raised": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.goToBatchStep();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('next')))])], 1)], 1)], 1), _c('v-stepper-content', {
    attrs: {
      "step": "2"
    }
  }, [_c('div', {
    staticClass: "important font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('upload_batch_optional')) + ":")]), _c('v-layout', {
    staticClass: "content"
  }, [_c('v-flex', {
    staticClass: "infos font-weight-medium"
  }, [_vm._v(" " + _vm._s(_vm.$t('upload_batch_hint')) + " "), _c('v-text-field', {
    staticClass: "mt-3 mb-6",
    attrs: {
      "label": _vm.$t('upload_batch_label'),
      "counter": "50",
      "rules": _vm.batchRules
    },
    model: {
      value: _vm.batchName,
      callback: function ($$v) {
        _vm.batchName = $$v;
      },
      expression: "batchName"
    }
  })], 1)], 1), _c('v-layout', {
    staticClass: "footer"
  }, [_c('v-spacer'), _c('v-flex', {
    attrs: {
      "shrink": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.step = 1;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('back')))]), _c('v-btn', {
    attrs: {
      "raised": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.parseCSV();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('next')))])], 1)], 1)], 1), _c('v-stepper-content', {
    staticClass: "custom-stepper",
    attrs: {
      "step": "3"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.completed,
      expression: "!completed"
    }],
    staticClass: "loading"
  }, [_c('v-progress-linear', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.completed,
      expression: "!completed"
    }],
    attrs: {
      "indeterminate": true
    }
  }), _c('v-layout', {
    attrs: {
      "align-center": "",
      "justify-center": "",
      "row": "",
      "fill-height": ""
    }
  }, [_c('v-flex', {
    staticClass: "text-center infos font-weight-bold",
    attrs: {
      "shrink": ""
    }
  }, [_vm._v(_vm._s(_vm.$t('uploading')) + " (" + _vm._s(this.uploadCount) + " / " + _vm._s(this.uploadData.length) + ")"), _c('br'), this.throttling ? _c('div', [_vm._v(_vm._s(_vm.$t('upload_in_progress')))]) : _vm._e()])], 1)], 1), _vm.completed && _vm.uploadErrors.length ? _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "important font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('warning')) + ":")]), !_vm.quotaError ? _c('div', {
    staticClass: "infos font-weight-medium"
  }, [_vm._v(_vm._s(_vm.$t('upload_file_error')) + ":"), _c('br'), _c('br')]) : _vm._e(), _vm._l(_vm.uploadErrors, function (line, index) {
    return _c('div', {
      key: `errors-${index}`,
      staticClass: "infos font-weight-medium"
    }, [_vm._v(" " + _vm._s(line) + " ")]);
  }), _vm.hasWarning && !_vm.quotaError ? _c('div', {
    staticClass: "infos mt-5 font-weight-medium"
  }, [_vm._v(_vm._s(_vm.$t('upload_error_proceed_warning'))), _c('br'), _c('br')]) : _vm._e(), _vm.hasWarning ? _c('v-btn', {
    staticClass: "mt-5",
    attrs: {
      "raised": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.forceUpload();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('upload_anyways')))]) : _vm._e()], 2) : _vm._e(), _vm.completed && !_vm.uploadErrors.length ? _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "important font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('upload_complete')))]), _vm.createCount > 0 ? _c('div', {
    staticClass: "infos font-weight-medium"
  }, [_vm._v(_vm._s(_vm.$t('code_count_created', {
    count: this.createCount
  })))]) : _vm._e()]) : _vm._e(), _c('v-layout', {
    staticClass: "footer",
    attrs: {
      "row": ""
    }
  }, [_c('v-spacer'), _vm.completed && _vm.uploadErrors.length ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.step = 1;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('back')))]) : _vm._e(), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.close(true);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('close')))]), _vm.completed && !_vm.uploadErrors.length && this.batchName ? _c('v-btn', {
    attrs: {
      "raised": "",
      "color": "primary"
    },
    on: {
      "click": _vm.addBatchFilter
    }
  }, [_vm._v(_vm._s(_vm.$t('download_this_batch')))]) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }