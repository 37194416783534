<template>
  <div class="redirection">
    <div v-if="!loaded" class="d-flex my-6 align-center justify-center">
      <v-progress-circular size="50" width="3" color="primary" indeterminate></v-progress-circular>
    </div>
    <div v-if="loaded" class="d-flex loaded-ctn">
      <div v-show="!redirectionSimulator" class="rules-filter-ctn flex-column">
        <rules-filters></rules-filters>
        <v-btn @click="toggleSimulation()" raised class="mt-auto">
          <v-icon class="mr-2">$redirection</v-icon>
          Simulate scan
        </v-btn>
      </div>
      <div v-show="redirectionSimulator" class="simulator-ctn flex-column">
        <simulator-sidebar @on-simulation-end="onSimulationEnd"></simulator-sidebar>
        <v-btn @click="toggleSimulation()" raised class="mt-auto">
          <v-icon class="mr-2">mdi-close</v-icon>
          Quit Simulator
        </v-btn>
      </div>
      <div class="rule-wrapper">
        <div v-if="loaded" class="rules-ctn">
          <div v-if="!redirectionRules.length && (ruleActiveFilters.is_archived === null || ruleActiveFilters.is_archived === false)" class="no-rule-ctn">
            <div class="title">All scans are currently following the default rule.</div>
            <div class="description">To redirect scans to other destinations, create your own rule below:</div>
          </div>
          <div class="new-rule-ctn" v-if="!redirectionSimulator">
            <v-btn class="my-2" color="primary" large @click="createRule()">
              <v-icon class="mr-2">mdi-plus-circle-outline</v-icon> Create a new Rule
            </v-btn>
            <div class="connector"></div>
          </div>
          <draggable class="drop-area" :class="{ 'mt-10': redirectionSimulator }" v-model="redirectionRules" group="a">
            <div :class="{ 'simulator': redirectionSimulator }" v-for="rule in redirectionRules" :id="`rule-${rule.id}`" :key="`rule-${rule.id}`">
              <div :class="[{'selected': !simulating && redirectionSimulator && simulationResults?.matched_rule?.id === rule.id, 'expanded': rule.id === selectedRuleId }, getRuleStatus(rule)]" @click="toggleRule(rule)" class="rule d-flex align-center py-3 px-1">
                <v-icon class="grab">mdi-drag-vertical</v-icon>
                <div class="flex-grow-1 ml-2">
                  <div>{{rule.name}}</div>
                  <div v-show="rule.id !== selectedRuleId">
                    <span v-for="(campaign, index) in rule.campaigns" :key="`rule-${rule.id}-campaign-${campaign}`">
                      {{getCampaign(campaign)?.name ? getCampaign(campaign)?.name : `Archived Campaign (ID ${campaign})`}}{{ index < rule.campaigns.length - 1 ? ',' : ''}}
                    </span>
                  </div>
                </div>
                <div class="rule-status pr-2 font-weight-bold">
                  <div v-if="getRuleStatus(rule) === 'archived'">Archived</div>
                  <div v-if="getRuleStatus(rule) === 'live'">Live now</div>
                  <div v-if="getRuleStatus(rule) === 'paused'">Paused</div>
                  <div v-if="getRuleStatus(rule) === 'expired'">Expired on {{formatDate(rule.active_to)}}</div>
                  <div v-if="getRuleStatus(rule) === 'expiring'">Expiring on {{formatDate(rule.active_to)}}</div>
                  <div v-if="getRuleStatus(rule) === 'planned'">Going live on {{formatDate(rule.active_from)}}</div>
                </div>

                <v-btn v-show="rule.id !== selectedRuleId" icon><v-icon>mdi-unfold-more-horizontal</v-icon></v-btn>
                <v-btn v-show="rule.id === selectedRuleId" icon><v-icon>mdi-unfold-less-horizontal</v-icon></v-btn>
                <div v-if="redirectionSimulator">
                  <v-progress-circular v-if="simulating" indeterminate color="primary" size="16" width="2"></v-progress-circular>
                  <v-icon v-else-if="getRuleSimulationStatus(rule) === 'fail'" color="red">mdi-close</v-icon>
                  <v-icon v-else-if="getRuleSimulationStatus(rule) === 'success'" color="green">mdi-flag</v-icon>
                </div>
                <v-menu v-if="!redirectionSimulator" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon><v-icon>mdi-dots-vertical</v-icon></v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="editRule(rule)">
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="startRule(rule)" v-if="rule.is_paused && !rule.is_archived">
                      <v-list-item-title>Go Live</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="pauseRule(rule)" v-if="!rule.is_paused && !rule.is_archived">
                      <v-list-item-title>Pause</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="toggleArchiveRule(rule)">
                      <v-list-item-title v-if="rule.is_archived">Unarchive</v-list-item-title>
                      <v-list-item-title v-else>Archive</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!-- <v-btn @click="editRule(rule)">
                  Edit
                </v-btn> -->
              </div>
              <div v-show="selectedRuleId === rule.id" class="rule-content" :class="{'selected': !simulating && redirectionSimulator && simulationResults?.matched_rule?.id === rule.id}">
                <!-- <rule-form :rule="rule" :rule-id="rule.id" mode="preview"></rule-form> -->
                <rule-preview :rule="rule"></rule-preview>
              </div>
              <div class="connector"></div>
            </div>
          </draggable>
          <div class="default-rule-ctn mb-5">
            <div class="default-rule-header" :class="{'simulator': redirectionSimulator, 'selected': simulationResults?.is_fallback && !simulationResults?.matched_destination?.id}">
              Default rule
            </div>
            <div class="default-rule-content">
              <div class="d-flex align-center justify-center ">
                <div class="outlined-pill">If none of the above</div>
                <v-icon>mdi-arrow-right</v-icon>
                <div v-if="!redirectionSimulator || !simulationResults.elapsed_ms || simulationResults?.matched_destination?.id" class="outlined-pill">Product URL</div>
                <div v-else-if="simulationResults.elapsed_ms" class="outlined-pill selected">
                  <span v-if="simulationResults.fallback_type === 'product_url'">Product URL</span>
                  <span v-if="simulationResults.fallback_type === 'brand_url'">Brand URL</span>
                  <span v-if="simulationResults.fallback_type === 'company_url'">Company URL</span>
                </div>
              </div>
              <div class="d-flex mt-5 align-start justify-center">
                <v-icon class="mr-2">mdi-information-outline</v-icon>
                <div class="hint">
                  If the Product URL is empty, the <span class="font-medium">Brand URL</span> will be used.<br>
                  If the Brand URL is empty, the <span class="font-medium">Company URL</span> will be used.<br>
                  If the company URL is empty, the final destination will be <span class="font-medium">www.scantrust.com</span><br>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="ruleDialog" width="900" :persistent="true">
      <rule-form
        :rule="this.$route.params && this.$route.params.rule ? this.$route.params.rule : {}"
        :rule-id="this.$route.params && this.$route.params.id ? this.$route.params.id : null"
        @on-rule-created="onRuleCreated"
        @on-close-dialog="handleCloseDialog()">
      </rule-form>
    </v-dialog>
  </div>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex'
import draggable from 'vuedraggable'
import RulesFilters from '@/components/redirection/RuleFilters'
import RuleForm from '@/components/redirection/RuleForm'
import RulePreview from '@/components/redirection/RulePreview'
import SimulatorSidebar from '@/components/redirection/SimulatorSidebar'

import Utils from '@/utils/utils'

export default {
  name: 'redirection',
  computed: {
    ...mapGetters(['campaigns', 'redirectionVariables', 'landingPages', 'landingPagesLoaded', 'ruleActiveFilters', 'redirectionSimulator', 'simulating', 'simulationResults']),
    redirectionRules: {
      get () {
        return this.$store.getters.redirectionRules
      },
      set (rules) {
        this.$store.dispatch('updateRulesPositions', { rules })
      }
    }
  },
  components: {
    draggable,
    RuleForm,
    RulePreview,
    RulesFilters,
    SimulatorSidebar
  },
  watch: {
    '$route.params' (newVal) {
      if (this.$route.name === 'redirection.edit' || this.$route.name === 'redirection.new') {
        this.ruleDialog = true
      } else {
        this.ruleDialog = false
      }
    },

    redirectionSimulator (newVal) {
      if (newVal) {
        this.selectedRuleId = null
      }
    }
  },
  data () {
    return {
      loaded: false,
      ruleDialog: false,
      selectedRuleId: null,
      time: {
        start: null,
        end: null
      },
      form: {
        name: '',
        campaigns: [],
        active_from: null,
        active_to: null,
        timezone: ''
      },
      menu: false
    }
  },
  methods: {

    ...mapMutations({
      setFilters: 'SET_ACTIVE_RULES_FILTERS',
      toggleSimulation: 'TOGGLE_REDIRECTION_SIMULATION_MODE'
    }),

    formatDate (date) {
      return Utils.formatDate(date, true)
    },

    toggleRule (rule) {
      if (this.selectedRuleId === rule.id) {
        this.selectedRuleId = null
      } else {
        this.selectedRuleId = rule.id
      }
    },

    startRule (rule) {
      this.$store.dispatch('toggleRedirectionRule', rule)
    },

    pauseRule (rule) {
      this.$store.dispatch('toggleRedirectionRule', rule)
    },

    createRule () {
      this.$router.push({ name: 'redirection.new' })
    },

    onSimulationEnd () {
      if (this.simulationResults.matched_rule) {
        this.selectedRuleId = this.simulationResults.matched_rule.id
      }
    },

    getRuleSimulationStatus (rule) {
      if (this.simulationResults.matched_rule) {
        if (rule.id === this.simulationResults.matched_rule.id) {
          return 'success'
        }
        if (rule.position > this.simulationResults.matched_rule.position) {
          return 'fail'
        }
      } else if (this.simulationResults.is_fallback) {
        return 'fail'
      }
    },

    getRuleStatus (rule) {
      const currentDate = new Date()

      if (rule.is_archived) {
        return 'archived'
      }
      if (rule.is_paused) {
        return 'paused'
      }

      if (rule.active_from) {
        const startDate = new Date(rule.active_from)
        if (currentDate < startDate) {
          return 'planned'
        }

        if (rule.active_to) {
          const endDate = new Date(rule.active_to)
          if (currentDate > endDate) {
            return 'expired'
          }

          if (currentDate >= startDate && currentDate <= endDate) {
            return 'live'
          }
        } else {
          return 'live'
        }
      }

      // isn't paused/archived, and doesn't have start date
      return 'live'
    },

    getCampaign (campaign) {
      return this.campaigns.find((c) => {
        return c.id === campaign
      })
    },

    editRule (rule) {
      this.$router.push({ name: 'redirection.edit', params: { id: rule.id, rule: rule } })
    },

    onRuleCreated () {
      this.ruleDialog = false
      this.$router.push({ name: 'redirection' })
    },

    handleCloseDialog () {
      this.ruleDialog = false
      this.$router.push({ name: 'redirection' })
    },

    toggleArchiveRule (rule) {
      if (rule.is_archived) {
        this.$store.dispatch('unarchiveRedirectionRule', rule.id)
      } else {
        this.$store.dispatch('archiveRedirectionRule', rule.id)
      }
    },

    highlightDestination (id) {
      setTimeout(() => {
        const found = this.redirectionRules.find((rule) => {
          return rule.destinations.find((destination) => {
            return destination.id === id
          })
        })
        if (found && found.id) {
          this.selectedRuleId = found.id
          document.getElementById(`rule-${this.selectedRuleId}`).scrollIntoView()
        } else if (this.ruleActiveFilters.is_archived === false) {
          this.setFilters({ ...this.ruleActiveFilters, is_archived: null })
          setTimeout(() => {
            this.highlightDestination(id)
          }, 2000)
        }
      }, 2000)
    }

  },
  async created () {
    if (!this.redirectionVariables.length) {
      this.$store.dispatch('loadRedirectionVariables')
    }

    // Maybe move this loading somewhere else
    if (!this.campaigns.length) {
      console.log('Load campaign in product tab')
      await this.$store.dispatch('loadCampaigns')
    }

    if (!this.landingPages.length && !this.landingPagesLoaded) {
      await this.$store.dispatch('loadLandingPages')
    }

    await this.$store.dispatch('loadRedirectionRules')
    this.loaded = true

    if (this.campaigns.length <= 5) {
      this.$store.dispatch('loadRulesScmFields', this.campaigns)
    }

    if (this.$route.name === 'redirection.edit' || this.$route.name === 'redirection.new') {
      this.ruleDialog = true
    }

    if (this.$route.query.destination) {
      this.highlightDestination(parseInt(this.$route.query.destination))
    }
  }
}

</script>

<style lang="sass" scoped>

.flex-column
  display: flex
  flex-direction: column

.no-rule-ctn
  margin-left: auto
  margin-right: auto
  padding: 20px 0px 10px 0px
  text-align: center

.rules-filter-ctn
  max-width: 250px

.simulator-ctn
  max-width: 280px
  background: #f4f4f4
  padding: 10px

.rule-wrapper
  min-height: -webkit-min-content
  overflow: auto
  height: 100vh
  width: 100%
  margin-left: auto
  margin-right: auto

.rules-ctn
  height: 100%
  width: 80%
  margin-left: auto
  margin-right: auto

.redirection
  // height: calc(100vh - 63px)
  height: 100vh
  overflow: hidden
  margin-left: auto
  margin-right: auto

.loaded-ctn
  height: 100vh

.rule
  cursor: pointer
  position: relative
  border-radius: 6px
  border: solid 1px rgba(0, 0, 0, 0.12)
  box-shadow: -1px 1px 1px 0px rgba(0,0,0,0.12)
  &.paused, &.planned
    background: #bababa
    border: solid 1px rgba(0, 0, 0, 0.12)
  &.expired, &.archived
    background: rgba(33, 33, 33, 0.08)
  &.expiring, &.live
    background: rgba(63, 193, 53, 0.8)
  &.selected
    border-bottom: none
    border-radius: 6px 6px 0px 0px
    box-shadow: none
  &.expanded
    border-bottom: 2px
    border-radius: 6px 6px 0px 0px
  &.selected:not(.expanded)
    border-bottom: 2px solid #77C61A
    border-radius: 6px

.connector
  bottom: 0px
  width: 2px
  height: 30px
  margin-left: auto
  margin-right: auto
  background: rgba(0, 0, 0, 0.12)
  content: ' '

.simulator
  .rule
    background: white

.grab
  cursor: grab

.new-rule-ctn, .default-rule-ctn
  margin-left: auto
  margin-right: auto
  text-align: center

.rule-content
  border-radius: 0px 0px 15px 15px
  &.selected
    border-top: none
    box-shadow: none

.default-rule-content
  padding: 12px
  margin-left: auto
  margin-right: auto
  border: 1px solid rgba(0, 0, 0, 0.12)
  border-radius: 0px 0px 10px 10px

.default-rule-header
  border-radius: 10px 10px 0px 0px
  background: rgba(63, 193, 53, 0.80)
  text-align: center
  font-family: Roboto
  font-size: 12px
  padding: 6px 10px 6px 0px
  font-style: normal
  font-weight: 500
  line-height: 16px
  letter-spacing: 1.5px
  text-transform: uppercase
  &.simulator
    background: white
    border: 1px solid rgba(0, 0, 0, 0.12)
    border-bottom: none
  &.selected
    background: rgba(63, 193, 53, 0.80)

.outlined-pill
  border-radius: 16px
  border: 1px solid rgba(0, 0, 0, 0.12)
  padding: 2px 12px

.rules-filter-ctn
  background: #f4f4f4
  padding: 10px

.hint
  color: #777777
  font-size: 14px
  text-align: left

.selected
  border: 2px solid #77C61A

</style>
