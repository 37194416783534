import * as types from '../mutations'
import lookupService from '@/services/lookup'
import regionService from '@/services/regions'
import campaignService from '@/services/campaigns'
import rulesService from '@/services/rules'
import Vue from 'vue'

const state = {
  variables: [],
  simulator: false,
  simulating: false,
  simulationCode: {},
  simulationResults: {},
  filters: {
    campaigns: [null],
    is_paused: null,
    is_archived: false
  },
  activationStatuses: [],
  computedAuthResults: [],
  scanResults: [],
  blacklistReasons: [],
  apps: [],
  regions: [],
  rules: [],
  scmFields: []
}

const getters = {
  simulating: (state) => state.simulating,
  simulationCode: (state) => state.simulationCode,
  simulationResults: (state) => state.simulationResults,
  redirectionSimulator: (state) => state.simulator,
  ruleActiveFilters: (state) => state.filters,
  rulesScmFields: (state) => state.scmFields,
  redirectionVariables: (state) => state.variables,
  computedAuthResults: (state) => state.computedAuthResults,
  activationStatuses: (state) => state.activationStatuses,
  scanReasons: (state) => {
    return [
      { value: 'other', description: 'Other' },
      { value: 'auth', description: 'Authentication' },
      { value: 'query', description: 'Query' },
      { value: 'serial', description: 'Serial Number Lookup' }
    ]
  },
  scanResults: (state) => state.scanResults,
  customRegions: (state) => state.regions,
  blacklistReasons: (state) => state.blacklistReasons,
  apps: (state) => state.apps,
  redirectionRules: (state, getters) => state.rules.sort((a, b) => {
    if (a.position < b.position) {
      return -1
    } else if (a.position > b.position) {
      return 1
    }
    return 0
  }).filter((rule) => {
    if (typeof getters.ruleActiveFilters.is_archived !== 'object') {
      return getters.ruleActiveFilters.is_archived === rule.is_archived
    }
    return true
  }),
  redirectionRuleDestinations: (state) => (id) => {
    if (id && typeof state.rules.find((rule) => rule.id === id).destinations === 'object') {
      return state.rules.find((rule) => rule.id === id).destinations
    }
    return []
  }
}

const actions = {

  async simulateRedirection ({ commit }, { params }) {
    commit(types.SET_SIMULATING, true)
    const res = await rulesService.simulateRedirection(params)
    res.params = params
    commit(types.SET_SIMULATION_RESULTS, res)
    commit(types.SET_SIMULATING, false)
    return res
  },

  async loadRulesScmFields ({ commit }, campaigns) {
    const promises = []
    campaigns.forEach((campaign) => {
      promises.push(campaignService.loadScmFields(campaign.id))
    })
    Promise.all(promises).then((res) => {
      let scmFields = []
      res.forEach((array) => {
        scmFields = scmFields.concat(array)
      })
      const filtered = []

      scmFields.forEach((scmField) => {
        const index = filtered.findIndex((item) => {
          return item.key === scmField.key
        })

        if (!~index) {
          filtered.push({ key: `scm_${scmField.key}`, description: scmField.name })
        }
      })
      commit(types.SET_RULES_SCM_FIELDS, filtered)
    })
  },

  async updateRulesDestinationPositions ({ commit }, { ruleId, destinations }) {
    const destinationsPositions = []
    destinations.forEach((destination, index) => {
      destination.position = index
      destinationsPositions.push({ id: destination.id, position: index })
    })
    commit(types.LOAD_RULE_DESTINATIONS, { ruleId, destinations })
    await rulesService.updateRulesDestinationPositions(ruleId, destinationsPositions)
  },

  async updateRulesPositions ({ commit }, { rules }) {
    const rulesPositions = []
    rules.forEach((rule, index) => {
      rule.position = index
      rulesPositions.push({ id: rule.id, position: index })
      // promises.push(rulesService.updateRule(rule))
    })
    commit(types.UPDATE_REDIRECTION_RULES, rules)
    await rulesService.updateRulesPositions(rulesPositions)
    // Promise.all(promises).then((data) => {
    //   commit(types.UPDATE_REDIRECTION_RULES, data)
    // })
  },

  async createRuleDestination ({ commit }, { ruleId, destination }) {
    const res = await rulesService.createRuleDestination(ruleId, destination)
    commit(types.ADD_REDIRECTION_RULE_DESTINATION, { ruleId, destination: res })
    return res
  },

  async updateRuleDestination ({ commit }, { ruleId, destination }) {
    const res = await rulesService.updateRuleDestination(ruleId, destination)
    commit(types.UPDATE_REDIRECTION_RULE_DESTINATION, { ruleId, destination: res })
    return res
  },

  async deleteRuleDestination ({ commit }, { ruleId, destinationId }) {
    await rulesService.deleteRuleDestination(ruleId, destinationId)
    commit(types.DELETE_REDIRECTION_RULE_DESTINATION, { ruleId, destinationId })
  },

  async loadRuleDestinations ({ commit }, { ruleId }) {
    const destinations = await rulesService.loadRuleDestinations(ruleId)
    const sorted = destinations.sort((a, b) => {
      if (a.position > b.position) {
        return 1
      } else if (a.position < b.position) {
        return -1
      }
      return 0
    })
    commit(types.LOAD_RULE_DESTINATIONS, { ruleId, destinations: sorted })
  },

  async loadDestination ({ commit }, { ruleId, destinationId }) {
    const destination = await rulesService.loadRuleDestination({ ruleId, destinationId })
    return destination
  },

  async toggleRedirectionRule ({ commit }, r) {
    const rule = await rulesService.switchRuleStatus(r)
    commit(types.UPDATE_REDIRECTION_RULE_STATUS, { id: rule.id, isPaused: rule.is_paused })
  },

  async archiveRedirectionRule ({ commit }, ruleId) {
    await rulesService.archiveRule(ruleId)
    commit(types.ARCHIVE_REDIRECTION_RULE, ruleId)
  },

  async unarchiveRedirectionRule ({ commit }, ruleId) {
    await rulesService.unarchiveRule(ruleId)
    commit(types.UNARCHIVE_REDIRECTION_RULE, ruleId)
  },

  async createRedirectionRule ({ commit }, ruleForm) {
    const rule = await rulesService.createRule(ruleForm)
    rule.destinations = []
    commit(types.ADD_REDIRECTION_RULE, rule)
    return rule
  },

  async updateRedirectionRule ({ commit }, { form, destinations }) {
    const rule = await rulesService.updateRule(form)
    if (destinations) {
      rule.destinations = destinations
    }
    commit(types.UPDATE_REDIRECTION_RULE, rule)
  },

  async loadRedirectionRules ({ commit, getters }) {
    const rules = await rulesService.loadRules(getters.ruleActiveFilters)
    commit(types.SET_REDIRECTION_RULES, rules)
  },

  async loadCustomRegions ({ commit }) {
    const regions = await regionService.loadCustomRegions()
    commit(types.SET_CUSTOM_REGIONS, regions)
  },

  async loadAppsList ({ commit }) {
    const apps = await lookupService.getAppList()
    commit(types.SET_APPS_LIST, apps)
  },

  async loadRedirectionVariables ({ commit }) {
    const variables = await lookupService.getLookupData('redirect-conditions')
    variables.push({ value: 'scm', description: 'SCM Field' })
    commit(types.SET_REDIRECTION_VARIABLES, variables)
  },

  async loadActivationStatuses ({ commit }) {
    const statuses = await lookupService.getLookupData('activation-status')
    commit(types.SET_ACTIVATION_STATUSES, statuses)
  },

  async loadScanResults ({ commit }) {
    const results = await lookupService.getLookupData('scan-result')
    commit(types.SET_SCAN_RESULTS, results)
  },

  async loadComputedAuthResults ({ commit }) {
    const results = await lookupService.getLookupData('calculated-auth-result')
    commit(types.SET_COMPUTED_AUTH_RESULTS, results)
  },

  async loadBlacklistReasons ({ commit }) {
    const reasons = await lookupService.getLookupData('blacklist-reason')
    commit(types.SET_BLACKLIST_REASONS, reasons)
  }
}

const mutations = {

  [types.SET_COMPUTED_AUTH_RESULTS] (state, res) {
    state.computedAuthResults = res
  },

  [types.SET_BLACKLIST_REASONS] (state, res) {
    state.blacklistReasons = res
  },

  [types.SET_ACTIVE_RULES_FILTERS] (state, filters) {
    state.filters = filters
  },

  [types.CLEAR_ACTIVE_RULES_FILTERS] (state) {
    state.filters = {
      campaigns: [null],
      is_paused: null,
      is_archived: false
    }
  },

  [types.SET_RULES_SCM_FIELDS] (state, scmFields) {
    state.scmFields = scmFields
  },

  [types.UPDATE_REDIRECTION_RULES] (state, rules) {
    state.rules = rules
  },

  [types.ADD_REDIRECTION_RULE] (state, rule) {
    state.rules.push(rule)
  },

  [types.LOAD_RULE_DESTINATIONS] (state, { ruleId, destinations }) {
    const index = state.rules.findIndex((r) => r.id === ruleId)
    if (~index) {
      Vue.set(state.rules[index], 'destinations', destinations)
    }
  },

  [types.ADD_REDIRECTION_RULE_DESTINATION] (state, { ruleId, destination }) {
    const index = state.rules.findIndex((r) => r.id === ruleId)
    console.log('ADDING RULE', ruleId, state.rules[index], destination)
    if (~index) {
      if (!state.rules[index].destinations) {
        state.rules[index].destinations = []
      }
      state.rules[index].destinations.push(destination)
    }
  },

  [types.UPDATE_REDIRECTION_RULE_DESTINATION] (state, { ruleId, destination }) {
    const index = state.rules.findIndex((r) => r.id === ruleId)
    if (~index) {
      const destinationIndex = state.rules[index].destinations.findIndex((d) => d.id === destination.id)
      if (~destinationIndex) {
        Vue.set(state.rules[index].destinations, destinationIndex, destination)
      }
    }
  },

  [types.DELETE_REDIRECTION_RULE_DESTINATION] (state, { ruleId, destinationId }) {
    const index = state.rules.findIndex((r) => r.id === ruleId)
    if (~index) {
      const destinationIndex = state.rules[index].destinations.findIndex((d) => d.id === destinationId)
      if (~destinationIndex) {
        state.rules[index].destinations.splice(destinationIndex, 1)
        // Vue.set(state.rules[index].destinations, destinationIndex, destination)
      }
    }
  },

  [types.UPDATE_REDIRECTION_RULE] (state, rule) {
    const index = state.rules.findIndex((r) => r.id === rule.id)
    if (~index) {
      Vue.set(state.rules, index, rule)
    }
  },

  [types.UPDATE_REDIRECTION_RULE_STATUS] (state, { id, isPaused }) {
    const index = state.rules.findIndex((r) => r.id === id)
    if (~index) {
      state.rules[index].is_paused = isPaused
    }
  },

  [types.ARCHIVE_REDIRECTION_RULE] (state, ruleId) {
    const index = state.rules.findIndex((r) => r.id === ruleId)
    if (~index) {
      state.rules[index].is_archived = true
    }
  },

  [types.UNARCHIVE_REDIRECTION_RULE] (state, ruleId) {
    const index = state.rules.findIndex((r) => r.id === ruleId)
    if (~index) {
      state.rules[index].is_archived = false
    }
  },

  [types.SET_REDIRECTION_RULES] (state, rules) {
    state.rules = []
    rules.forEach((rule) => {
      if (!rule.destinations) {
        rule.destinations = []
      }
      state.rules.push(rule)
    })
  },

  [types.SET_CUSTOM_REGIONS] (state, regions) {
    state.regions = regions
  },

  [types.SET_APPS_LIST] (state, apps) {
    state.apps = apps
  },

  [types.SET_REDIRECTION_VARIABLES] (state, variables) {
    state.variables = variables
  },

  [types.SET_ACTIVATION_STATUSES] (state, statuses) {
    state.activationStatuses = statuses
  },

  [types.SET_SCAN_RESULTS] (state, results) {
    state.scanResults = results
  },

  [types.TOGGLE_REDIRECTION_SIMULATION_MODE] (state) {
    state.simulator = !state.simulator
  },

  [types.SET_SIMULATING] (state, status) {
    state.simulating = status
  },

  [types.SET_SIMULATION_RESULTS] (state, results) {
    state.simulationResults = results
  },

  [types.SET_SIMULATION_CODE] (state, code) {
    state.simulationCode = code
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
