var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "logic-value-input"
  }, [_vm.type === 'product_id' ? _c('v-autocomplete', {
    attrs: {
      "append-icon": _vm.mode === 'preview' ? null : '$dropdown',
      "disabled": _vm.mode === 'preview',
      "item-value": "id",
      "item-text": "sku",
      "multiple": _vm.operator === 'in',
      "deletable-chips": "",
      "items": _vm.products,
      "search-input": _vm.search,
      "dense": "",
      "no-filter": "",
      "hide-details": "",
      "outlined": "",
      "persistent-placeholder": "",
      "placeholder": "Select a product"
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.search = $event;
      },
      "update:search-input": function ($event) {
        _vm.search = $event;
      },
      "change": function ($event) {
        _vm.search = '';
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function (_ref) {
        var item = _ref.item,
          on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-list-item', _vm._g(_vm._b({}, 'v-list-item', attrs, false), on), [_vm.operator === 'in' ? _c('v-checkbox', {
          model: {
            value: attrs.inputValue,
            callback: function ($$v) {
              _vm.$set(attrs, "inputValue", $$v);
            },
            expression: "attrs.inputValue"
          }
        }) : _vm._e(), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(item.sku) + " - " + _vm._s(item.name) + " ")])], 1)], 1)];
      }
    }, {
      key: "selection",
      fn: function (_ref2) {
        var item = _ref2.item,
          attrs = _ref2.attrs,
          selected = _ref2.selected,
          select = _ref2.select;
        return [_c('div', [_vm.operator !== 'in' ? _c('div', [_vm._v(" " + _vm._s(item.sku) + " - " + _vm._s(item.name) + " ")]) : _c('v-chip', _vm._b({
          attrs: {
            "disabled": _vm.mode === 'preview',
            "input-value": selected,
            "close": _vm.mode !== 'preview'
          },
          on: {
            "click": select,
            "click:close": function ($event) {
              return _vm.remove(item);
            }
          }
        }, 'v-chip', attrs, false), [_vm._v(" " + _vm._s(item.sku) + " - " + _vm._s(item.name) + " ")])], 1)];
      }
    }], null, false, 1546056741),
    model: {
      value: _vm.autocompleteValue,
      callback: function ($$v) {
        _vm.autocompleteValue = $$v;
      },
      expression: "autocompleteValue"
    }
  }) : _vm.type === 'brand_id' ? _c('v-autocomplete', {
    attrs: {
      "append-icon": _vm.mode === 'preview' ? null : '$dropdown',
      "disabled": _vm.mode === 'preview',
      "chips": _vm.operator === 'in',
      "multiple": _vm.operator === 'in',
      "deletable-chips": "",
      "items": _vm.brands,
      "search-input": _vm.search,
      "item-text": "name",
      "item-value": "id",
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "persistent-placeholder": "",
      "placeholder": "Select a brand"
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.search = $event;
      },
      "update:search-input": function ($event) {
        _vm.search = $event;
      },
      "change": function ($event) {
        _vm.search = '';
      }
    },
    model: {
      value: _vm.autocompleteValue,
      callback: function ($$v) {
        _vm.autocompleteValue = $$v;
      },
      expression: "autocompleteValue"
    }
  }) : _vm.type === 'location_country_code' ? _c('v-autocomplete', {
    attrs: {
      "append-icon": _vm.mode === 'preview' ? null : '$dropdown',
      "disabled": _vm.mode === 'preview',
      "chips": _vm.operator === 'in',
      "multiple": _vm.operator === 'in',
      "deletable-chips": "",
      "items": _vm.countries,
      "search-input": _vm.search,
      "item-text": "name",
      "item-value": "code",
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "persistent-placeholder": "",
      "placeholder": "Select a country"
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.search = $event;
      },
      "update:search-input": function ($event) {
        _vm.search = $event;
      },
      "change": function ($event) {
        _vm.search = '';
      }
    },
    model: {
      value: _vm.autocompleteValue,
      callback: function ($$v) {
        _vm.autocompleteValue = $$v;
      },
      expression: "autocompleteValue"
    }
  }) : _vm.type === 'device_language' ? _c('v-autocomplete', {
    attrs: {
      "append-icon": _vm.mode === 'preview' ? null : '$dropdown',
      "disabled": _vm.mode === 'preview',
      "chips": _vm.operator === 'in',
      "multiple": _vm.operator === 'in',
      "deletable-chips": "",
      "items": _vm.languages,
      "search-input": _vm.search,
      "item-text": "name",
      "item-value": "code",
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "persistent-placeholder": "",
      "placeholder": "Select a device language"
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.search = $event;
      },
      "update:search-input": function ($event) {
        _vm.search = $event;
      },
      "change": function ($event) {
        _vm.search = '';
      }
    },
    model: {
      value: _vm.autocompleteValue,
      callback: function ($$v) {
        _vm.autocompleteValue = $$v;
      },
      expression: "autocompleteValue"
    }
  }) : _vm.type === 'scan_activation_status' ? _c('v-autocomplete', {
    attrs: {
      "append-icon": _vm.mode === 'preview' ? null : '$dropdown',
      "disabled": _vm.mode === 'preview',
      "chips": _vm.operator === 'in',
      "multiple": _vm.operator === 'in',
      "deletable-chips": "",
      "items": _vm.activationStatuses,
      "search-input": _vm.search,
      "item-text": "description",
      "item-value": "value",
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "persistent-placeholder": "",
      "placeholder": "Select a scan status"
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.search = $event;
      },
      "update:search-input": function ($event) {
        _vm.search = $event;
      },
      "change": function ($event) {
        _vm.search = '';
      }
    },
    model: {
      value: _vm.autocompleteValue,
      callback: function ($$v) {
        _vm.autocompleteValue = $$v;
      },
      expression: "autocompleteValue"
    }
  }) : _vm.type === 'scan_result' ? _c('v-autocomplete', {
    attrs: {
      "disabled": _vm.mode === 'preview',
      "append-icon": _vm.mode === 'preview' ? null : '$dropdown',
      "chips": _vm.operator === 'in',
      "multiple": _vm.operator === 'in',
      "deletable-chips": "",
      "items": _vm.scanResults,
      "search-input": _vm.search,
      "item-text": "description",
      "item-value": "value",
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "persistent-placeholder": "",
      "placeholder": "Select a scan result"
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.search = $event;
      },
      "update:search-input": function ($event) {
        _vm.search = $event;
      },
      "change": function ($event) {
        _vm.search = '';
      }
    },
    model: {
      value: _vm.autocompleteValue,
      callback: function ($$v) {
        _vm.autocompleteValue = $$v;
      },
      expression: "autocompleteValue"
    }
  }) : _vm.type === 'calculated_auth_result' ? _c('v-autocomplete', {
    attrs: {
      "append-icon": _vm.mode === 'preview' ? null : '$dropdown',
      "disabled": _vm.mode === 'preview',
      "chips": _vm.operator === 'in',
      "multiple": _vm.operator === 'in',
      "deletable-chips": "",
      "items": _vm.computedAuthResults,
      "search-input": _vm.search,
      "item-text": "description",
      "item-value": "value",
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "persistent-placeholder": "",
      "placeholder": "Select a scan result"
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.search = $event;
      },
      "update:search-input": function ($event) {
        _vm.search = $event;
      },
      "change": function ($event) {
        _vm.search = '';
      }
    },
    model: {
      value: _vm.autocompleteValue,
      callback: function ($$v) {
        _vm.autocompleteValue = $$v;
      },
      expression: "autocompleteValue"
    }
  }) : _vm.type === 'scan_app_slug' ? _c('v-autocomplete', {
    attrs: {
      "append-icon": _vm.mode === 'preview' ? null : '$dropdown',
      "disabled": _vm.mode === 'preview',
      "chips": _vm.operator === 'in',
      "multiple": _vm.operator === 'in',
      "deletable-chips": "",
      "items": _vm.apps,
      "search-input": _vm.search,
      "item-text": "name",
      "item-value": "slug",
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "persistent-placeholder": "",
      "placeholder": "Select a scan application"
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.search = $event;
      },
      "update:search-input": function ($event) {
        _vm.search = $event;
      },
      "change": function ($event) {
        _vm.search = '';
      }
    },
    model: {
      value: _vm.autocompleteValue,
      callback: function ($$v) {
        _vm.autocompleteValue = $$v;
      },
      expression: "autocompleteValue"
    }
  }) : _vm.type === 'scan_reason' ? _c('v-autocomplete', {
    attrs: {
      "append-icon": _vm.mode === 'preview' ? null : '$dropdown',
      "disabled": _vm.mode === 'preview',
      "chips": _vm.operator === 'in',
      "multiple": _vm.operator === 'in',
      "deletable-chips": "",
      "items": _vm.scanReasons,
      "search-input": _vm.search,
      "item-text": "description",
      "item-value": "value",
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "persistent-placeholder": "",
      "placeholder": "Select a scan reason"
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.search = $event;
      },
      "update:search-input": function ($event) {
        _vm.search = $event;
      },
      "change": function ($event) {
        _vm.search = '';
      }
    },
    model: {
      value: _vm.autocompleteValue,
      callback: function ($$v) {
        _vm.autocompleteValue = $$v;
      },
      expression: "autocompleteValue"
    }
  }) : _vm.type === 'scan_region_key' ? _c('v-autocomplete', {
    attrs: {
      "disabled": _vm.mode === 'preview',
      "append-icon": _vm.mode === 'preview' ? null : '$dropdown',
      "chips": _vm.operator === 'in',
      "multiple": _vm.operator === 'in',
      "deletable-chips": "",
      "items": _vm.customRegions,
      "search-input": _vm.search,
      "item-text": "name",
      "item-value": "key",
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "persistent-placeholder": "",
      "placeholder": "Select a custom region"
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.search = $event;
      },
      "update:search-input": function ($event) {
        _vm.search = $event;
      },
      "change": function ($event) {
        _vm.search = '';
      }
    },
    model: {
      value: _vm.autocompleteValue,
      callback: function ($$v) {
        _vm.autocompleteValue = $$v;
      },
      expression: "autocompleteValue"
    }
  }) : _vm.type === 'scan_blacklist_reason' ? _c('v-autocomplete', {
    attrs: {
      "disabled": _vm.mode === 'preview',
      "append-icon": _vm.mode === 'preview' ? null : '$dropdown',
      "chips": _vm.operator === 'in',
      "multiple": _vm.operator === 'in',
      "deletable-chips": "",
      "items": _vm.blacklistReasons,
      "search-input": _vm.search,
      "item-text": "description",
      "item-value": "value",
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "persistent-placeholder": "",
      "placeholder": "Select a blacklist reason"
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.search = $event;
      },
      "update:search-input": function ($event) {
        _vm.search = $event;
      },
      "change": function ($event) {
        _vm.search = '';
      }
    },
    model: {
      value: _vm.autocompleteValue,
      callback: function ($$v) {
        _vm.autocompleteValue = $$v;
      },
      expression: "autocompleteValue"
    }
  }) : _vm.operator === 'in' ? _c('v-combobox', {
    attrs: {
      "disabled": _vm.mode === 'preview',
      "append-icon": _vm.mode === 'preview' ? null : '$dropdown',
      "value": this.value,
      "chips": "",
      "multiple": "",
      "deletable-chips": "",
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.onComboChange
    },
    model: {
      value: _vm.comboBoxValue,
      callback: function ($$v) {
        _vm.comboBoxValue = $$v;
      },
      expression: "comboBoxValue"
    }
  }) : _c('v-text-field', {
    attrs: {
      "disabled": _vm.mode === 'preview',
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "persistent-placeholder": "",
      "placeholder": "Enter a value",
      "value": this.value
    },
    on: {
      "input": function ($event) {
        return _vm.updateValue($event);
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }