import { default as axios } from '../utils/axios.instances' // eslint-disable-line

const axiosInstance = axios.getSTAxios() // eslint-disable-line

export default {

  async loadBrands (options) {
    const params = {
      limit: 500,
      offset: 0,
      is_archived: false,
      ...options
    }

    return new Promise((resolve, reject) => {
      axiosInstance.get('/brands/', { params }).then((res) => {
        resolve(res.data.results)
      }).catch((err) => {
        reject(err)
      })
    })
  }

}
