import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-codes"
  }, [_c('div', {
    staticClass: "d-flex align-center pt-5"
  }, [_c('router-link', {
    attrs: {
      "to": "/products"
    }
  }, [_c(VBtn, {
    staticClass: "ml-3",
    attrs: {
      "icon": ""
    }
  }, [_c(VIcon, {
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-arrow-left")])], 1)], 1), _c('div', {
    staticClass: "page-title mono pl-3"
  }, [_vm._v(_vm._s(_vm.$t('product_qr_codes')))]), _c(VSpacer), _c('codes-quota', {
    staticClass: "mr-8"
  })], 1), !_vm.loadingProduct ? _c('div', {
    staticClass: "product-card my-4 mx-8"
  }, [_c(VRow, {
    staticClass: "ma-0"
  }, [_c(VCol, {
    staticClass: "product-picture flex-grow-0 pa-0",
    style: {
      'background-image': 'url(' + _vm.getProductImage(_vm.product) + ')'
    }
  }), _c(VCol, {
    staticClass: "flex-grow-1 pa-0 pb-3 pl-3"
  }, [_c('div', {
    staticClass: "product-title font-weight-medium"
  }, [_vm._v(_vm._s(_vm.product.name))]), _c('div', {
    staticClass: "product-description"
  }, [_vm._v(_vm._s(_vm.$t('product_sku')) + ": " + _vm._s(_vm.product.sku))]), _c('div', {
    staticClass: "product-description"
  }, [_vm._v(_vm._s(_vm.$t('product_description')) + ": " + _vm._s(_vm.product.description || 'N/A'))]), _c('div', {
    staticClass: "product-description"
  }, [_vm._v(" " + _vm._s(_vm.$t('product_qr_links_to')) + ": "), _vm.campaignRedirectionMode === 'url' ? _c('span', {
    staticClass: "redirect-setting font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('url')) + " "), _c('a', {
    attrs: {
      "href": _vm.consumerOptions.consumer_url,
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.consumerOptions.consumer_url))])]) : _vm._e(), _vm.campaignRedirectionMode === 'stc' ? _c('span', {
    staticClass: "redirect-setting font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('landing_page')))]) : _vm._e(), _vm.campaignRedirectionMode === 'elabel' ? _c('span', {
    staticClass: "redirect-setting font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('elabel_landing_page')))]) : _vm._e(), _vm.campaignRedirectionMode === 'product' ? _c('span', {
    staticClass: "redirect-setting font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('product_url')) + " "), _c('a', {
    attrs: {
      "target": "_blank",
      "href": _vm.product.client_url
    }
  }, [_vm._v(_vm._s(_vm.product.client_url))])]) : _vm._e()]), _c('div', {
    staticClass: "product-description"
  }, [_vm._v(_vm._s(_vm.$t('product_update_redirection_settings')) + ", "), _c('span', {
    staticClass: "link",
    on: {
      "click": _vm.goToRedirectionSettings
    }
  }, [_vm._v(_vm._s(_vm.$t('click_here')))]), _vm._v(".")])]), _c('div', {
    staticClass: "d-flex align-center ml-auto pr-9"
  }, [_c(VIcon, {
    staticClass: "mr-2",
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-qrcode")]), _vm.codesLoaded ? _c('div', {
    staticClass: "code-count font-weight-light"
  }, [_vm._v("x " + _vm._s(_vm.filteredCodeCount))]) : _vm._e(), _c(VBtn, {
    staticClass: "ml-6",
    attrs: {
      "disabled": !_vm.inactiveCodesLoaded,
      "loading": _vm.isCreatingCode,
      "raised": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.handleDisplayQrCodeDialog(true);
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2"
  }, [_vm._v("mdi-plus")]), _vm._v(" " + _vm._s(_vm.$t('product_new_qr_code')))], 1)], 1)], 1)], 1) : _vm._e(), _vm.loadingProduct ? _c('div', {
    staticClass: "skeleton-ctn my-4 mx-8"
  }, [_c(VSkeletonLoader, {
    attrs: {
      "width": "100%",
      "type": "avatar, paragraph"
    }
  })], 1) : _vm._e(), _vm.sortedCodes.length && _vm.codesLoaded ? _c('div', {
    staticClass: "d-flex align-center justify-space-between selection-settings mx-8"
  }, [_c('div', {
    staticClass: "d-flex align-center flex-wrap py-1"
  }, [_c(VCheckbox, {
    attrs: {
      "hide-details": "",
      "value": _vm.selectAllEnabled
    },
    on: {
      "click": function click($event) {
        return _vm.toggleSelectAll();
      }
    }
  }), _c('div', {
    staticClass: "search-label mr-4"
  }, [_vm._v(_vm._s(_vm.$t('selected')) + ": " + _vm._s(_vm.selectAllEnabled ? _vm.filteredCodeCount : _vm.selectedCodesIndexes.length))]), _vm.codesLoaded === true ? _c('div', {
    staticClass: "d-flex align-center"
  }, [_c('div', {
    staticClass: "search-label mr-2"
  }, [_vm._v(_vm._s(_vm.$t('bulk_actions')) + ":")]), _c('code-tag-editor', {
    attrs: {
      "isDisabled": !_vm.selectedCodes.length,
      "count": _vm.filteredCodeCount,
      "tags": _vm.scmFields,
      "edit-mode": "bulk",
      "select-all": _vm.selectAllEnabled,
      "product": _vm.product.id,
      "codes": _vm.selectedCodes,
      "campaign": _vm.selectedCampaign.id
    },
    on: {
      "update-done": _vm.onUpdateDone
    }
  }), _c('code-change-product', {
    attrs: {
      "isDisabled": !_vm.selectedCodes.length,
      "mode": "bulk",
      "count": _vm.filteredCodeCount,
      "select-all": _vm.selectAllEnabled,
      "product": _vm.product.id,
      "campaign": _vm.selectedCampaign.id,
      "codes": _vm.selectedCodes
    },
    on: {
      "code-transfered": _vm.onCodeTransfered
    }
  }), _c('download', {
    attrs: {
      "enable-qr-text-settings": true,
      "elabel-data": _vm.elabelData,
      "isDisabled": !_vm.selectedCodes.length,
      "mode": "product",
      "codes": _vm.selectedCodes,
      "product": _vm.product.id,
      "select-all": _vm.selectAllEnabled,
      "count": _vm.filteredCodeCount
    }
  })], 1) : _vm._e()], 1), _c('button', {
    staticClass: "d-flex show-url-btn",
    on: {
      "click": function click($event) {
        _vm.showUrl = !_vm.showUrl;
      }
    }
  }, [_c(VImg, {
    attrs: {
      "src": _vm.visibilityImgUrl
    }
  }), _vm.showUrl ? _c('span', [_vm._v(_vm._s(_vm.$t('hide_url_prefixes')))]) : _vm._e(), !_vm.showUrl ? _c('span', [_vm._v(_vm._s(_vm.$t('show_url_prefixes')))]) : _vm._e()], 1)]) : _vm._e(), _c('div', {
    staticClass: "code-list-ctn mx-8",
    class: {
      'no-border': !_vm.sortedCodes.length && _vm.codesLoaded
    }
  }, [_c(VProgressLinear, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.codesLoaded,
      expression: "!codesLoaded"
    }],
    attrs: {
      "indeterminate": ""
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.codesLoaded,
      expression: "codesLoaded"
    }]
  }, _vm._l(_vm.sortedCodes, function (code, codeIndex) {
    return _c('div', {
      key: code.id,
      staticClass: "code-line py-1 d-flex align-center",
      class: {
        'flash': code.isNew
      }
    }, [_c('div', {
      staticClass: "d-flex flex-wrap align-center"
    }, [_c(VCheckbox, {
      attrs: {
        "hide-details": "",
        "color": "#15bbf0",
        "value": _vm.selectedCodesIndexes.indexOf(codeIndex) >= 0
      },
      on: {
        "click": function click($event) {
          return _vm.toggleSelectCode(codeIndex);
        }
      }
    }), _c('div', {
      staticClass: "mono"
    }, [_vm._v(_vm._s(_vm.showUrl ? code.scm_data.scantrust_url_prefix ? code.scm_data.scantrust_url_prefix : _vm.systemPrefix : '') + _vm._s(code.message))]), _vm._l(_vm.filterScmData(code.scm_data), function (value, name) {
      return _c(VChip, {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: value,
          expression: "value"
        }],
        key: "code-".concat(code.id, "-tags-").concat(name, "-").concat(value),
        staticClass: "ml-2",
        attrs: {
          "color": "#f1f1f1",
          "text-color": "#0789b2"
        }
      }, [_vm._v(_vm._s(value) + " ")]);
    })], 2), _c(VSpacer), _c('div', {
      staticClass: "d-flex flex-shrink-0 align-center"
    }, [_c('div', {
      staticClass: "date mono mr-2"
    }, [_vm._v(_vm._s(code.activated_at ? _vm.formatDate(code.activated_at) : _vm.formatDate(code.creation_date)))]), _c('div', {
      staticClass: "icons-wrapper d-flex justify-space-between"
    }, [_c('code-tag-editor', {
      attrs: {
        "tags": _vm.scmFields,
        "codes": [code],
        "campaign": _vm.selectedCampaign.id
      }
    }), _vm.isGS1Code(code.message) ? _c('code-edit-gs1-digital-link', {
      attrs: {
        "code": code
      },
      on: {
        "open-edit-dialog": _vm.handleDisplayEditModal
      }
    }) : _c('code-change-product', {
      attrs: {
        "codes": [code]
      },
      on: {
        "code-transfered": _vm.onCodeTransfered
      }
    }), _c('download', {
      attrs: {
        "enable-qr-text-settings": true,
        "elabel-data": _vm.elabelData,
        "mode": "single",
        "codes": [code]
      }
    })], 1)])], 1);
  }), 0), !_vm.sortedCodes.length && _vm.codesLoaded ? _c('div', {
    staticClass: "code-empty-state py-2"
  }, [_c('img', {
    staticClass: "guide-arrow rotate-180",
    attrs: {
      "src": require("@/assets/guide_arrow.svg")
    }
  }), _vm._v(" " + _vm._s(_vm.$t('product_no_codes_empty_state_1')) + " "), _c('span', {
    staticClass: "link font-weight-bold",
    on: {
      "click": function click($event) {
        return _vm.handleDisplayQrCodeDialog(true);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('product_no_codes_empty_state_2')))]), _vm._v(" " + _vm._s(_vm.$t('product_no_codes_empty_state_3')) + " ")]) : _vm._e(), _c('div', {
    staticClass: "pagination-ctn mt-5 mb-4"
  }, [_c(VPagination, {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.codesLoaded && _vm.filteredCodeCount,
      expression: "codesLoaded && filteredCodeCount"
    }],
    attrs: {
      "color": "primary",
      "total-visible": 7,
      "length": _vm.nbPages
    },
    model: {
      value: _vm.page,
      callback: function callback($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1), _c('generate-qr-code-dialog', {
    attrs: {
      "display-dialog": _vm.displayDialog,
      "selected-product": _vm.product
    },
    on: {
      "update:displayDialog": function updateDisplayDialog($event) {
        _vm.displayDialog = $event;
      },
      "update:display-dialog": function updateDisplayDialog($event) {
        _vm.displayDialog = $event;
      }
    }
  }), _c('edit-confirmation-modal', {
    attrs: {
      "display-edit-modal": _vm.displayEditModal,
      "selected-code": _vm.code
    },
    on: {
      "update:displayEditModal": function updateDisplayEditModal($event) {
        _vm.displayEditModal = $event;
      },
      "update:display-edit-modal": function updateDisplayEditModal($event) {
        _vm.displayEditModal = $event;
      },
      "open-dialog": _vm.handleDisplayQrCodeDialog
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }