var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tag-input d-flex align-center"
  }, [_c('div', {
    staticClass: "label font-weight-bold"
  }, [_vm._v(_vm._s(_vm.label))]), _c('v-text-field', {
    attrs: {
      "hide-details": _vm.mode != 'new' && _vm.tagValue && _vm.tagValue.length <= 50,
      "counter": _vm.rules ? 50 : null,
      "dense": "",
      "single-line": "",
      "outlined": "",
      "value": _vm.tagValue,
      "rules": _vm.rules
    },
    on: {
      "input": _vm.onInput
    }
  }), _c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "ml-3",
          attrs: {
            "icon": ""
          },
          on: {
            "click": _vm.deleteTag
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "color": "red"
          }
        }, [_vm._v("mdi-minus")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('delete')))])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }