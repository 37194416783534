var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "py-5"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t('text_around_qr_title')))]), _c('div', {
    staticClass: "description"
  }, [_vm._v(" " + _vm._s(_vm.$t('text_around_qr_hint')) + " "), _c('a', {
    attrs: {
      "href": "https://help.scantrust.com/hc/en-us/articles/11085111089180-e-label-Editor-QR-Codes-Downloading-e-label-QR-codes-in-Bulk-Enterprise",
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('learn_more')) + " ")])]), _vm.filteredTexts.length ? _c('div', [_c('div', {
    staticClass: "label font-weight-bold mt-2 pb-1"
  }, [_vm._v(_vm._s(_vm.$t('text_color')) + ":")]), _c('color-input', {
    staticClass: "px-1",
    attrs: {
      "color": _vm.textSettings.text.color
    },
    on: {
      "update:color": function ($event) {
        return _vm.$set(_vm.textSettings.text, "color", $event);
      }
    }
  })], 1) : _vm._e(), _c('div', {
    key: `renderKey-${_vm.renderKey}`
  }, _vm._l(_vm.filteredTexts, function (item) {
    return _c('v-row', {
      key: `textfield-${item.key}`,
      staticClass: "ma-0 mt-5 my-2"
    }, [_c('v-row', {
      staticClass: "text-row px-1 ma-0"
    }, [_c('v-col', {
      staticClass: "pa-0 mr-2",
      attrs: {
        "cols": "3"
      }
    }, [_c('v-select', {
      staticClass: "text-select",
      attrs: {
        "solo": "",
        "value": item.key,
        "items": _vm.textPositions,
        "label": _vm.$t('position'),
        "item-text": "description",
        "item-value": "key",
        "hide-details": "",
        "dense": ""
      },
      on: {
        "change": function ($event) {
          return _vm.onTextPositionChange($event, item.key);
        }
      }
    })], 1), _c('v-col', {
      staticClass: "pa-0 mr-2"
    }, [_c('v-combobox', {
      staticClass: "ma-0",
      attrs: {
        "maxlength": "33",
        "solo": "",
        "hide-details": "",
        "dense": "",
        "items": _vm.dataFields,
        "item-text": "description",
        "return-object": ""
      },
      on: {
        "input": function ($event) {
          return _vm.handleTextInput($event, item.key);
        }
      },
      model: {
        value: _vm.textSettings.selectedTexts[item.key],
        callback: function ($$v) {
          _vm.$set(_vm.textSettings.selectedTexts, item.key, $$v);
        },
        expression: "textSettings.selectedTexts[item.key]"
      }
    })], 1), _c('v-col', {
      staticClass: "pa-0",
      attrs: {
        "cols": "1"
      }
    }, [_c('v-btn', {
      attrs: {
        "color": "red",
        "icon": ""
      },
      on: {
        "click": function ($event) {
          return _vm.deleteTextField(item.key);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-close-circle")])], 1)], 1)], 1)], 1);
  }), 1), _vm.hasAvailableTextPosition ? _c('v-btn', {
    staticClass: "mt-3",
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.addTextField();
      }
    }
  }, [_c('v-icon', [_vm._v("$plus")]), _vm._v(" " + _vm._s(_vm.$t('add_data')) + " ")], 1) : _vm._e()], 1), _c('div', [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.$t('icon_options_title')))]), _c('div', {
    staticClass: "description"
  }, [_vm._v(_vm._s(_vm.$t('icon_options_hint')))]), _c('v-radio-group', {
    staticClass: "pa-2",
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.textSettings.iconSetting,
      callback: function ($$v) {
        _vm.$set(_vm.textSettings, "iconSetting", $$v);
      },
      expression: "textSettings.iconSetting"
    }
  }, [_c('v-radio', {
    attrs: {
      "value": "none",
      "label": _vm.$t('no_icon')
    }
  }), _c('v-radio', {
    attrs: {
      "value": "inside",
      "label": _vm.$t('icon_inside_of_qr')
    }
  }), _c('v-radio', {
    attrs: {
      "value": "outside",
      "label": _vm.$t('icon_outside_of_qr')
    }
  })], 1), _vm.textSettings.iconSetting === 'outside' ? _c('div', {
    staticClass: "outside-icon-options mt-2 ml-7 mb-5"
  }, [_c('v-select', {
    staticClass: "text-select",
    attrs: {
      "solo": "",
      "items": _vm.textPositions,
      "label": _vm.$t('position'),
      "item-text": "description",
      "item-value": "key",
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.iconPosition,
      callback: function ($$v) {
        _vm.iconPosition = $$v;
      },
      expression: "iconPosition"
    }
  })], 1) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }