<template>
  <div class="products">
    <v-row class="ma-0">
      <v-col v-show="campaigns && campaigns.length > 1" class="campaign-list flex-grow-0 flex-shrink-0">
        <div class="page-title flex-shrink-0 mono pl-10 pb-4">{{$t('campaigns')}}</div>
        <div v-show="!loadingCampaigns" class="campaign-item pl-10 pr-2 py-2" :class="{ 'selected': selectedCampaign.id == campaign.id }" @click="selectCampaign(campaign)" v-for="campaign in campaigns" :key="campaign.id">
          {{campaign.name}}
        </div>
        <div v-show="loadingCampaigns" class="campaign-skeleton-ctn">
          <v-skeleton-loader width="100%" type="list-item"></v-skeleton-loader>
        </div>
      </v-col>
      <v-col class="product-list flex-grow-1" :class="{ 'pl-2': campaigns.length == 1}">
        <div class="product-actions-ctn d-flex mb-2 pt-2 align-center flex-wrap flex-grow-1">
          <v-btn v-if="!isAvailable('qrmanager:product:csv')" @click="openCreateProductModal" class="md-raised primary mr-4">
            <v-icon class="mr-2">mdi-plus</v-icon>{{$t('new_product')}}
          </v-btn>
          <v-btn v-if="isAvailable('qrmanager:product:csv')" @click="manageProductDialog = true" class="md-raised primary mr-4">
            <v-icon class="mr-2" color="black">mdi-plus</v-icon>{{$t('new_product')}}
          </v-btn>
          <router-link v-if="isAvailable('qrmanager:codes:list') && products.length" to="/codes">
            <v-btn @click="goToManageAll()" text color="primary"><v-icon class="mr-2">mdi-qrcode</v-icon>{{$t('all_qr_codes')}}</v-btn>
          </router-link>
          <router-link v-if="isAvailable('qrmanager:elabel_bulk_upload')" to="/bulk-upload">
            <v-btn text color="primary"><v-icon class="mr-2">mdi-cloud-upload</v-icon>{{$t('upload_elabel_data')}}</v-btn>
          </router-link>
          <v-spacer></v-spacer>
          <codes-quota class="mr-3"></codes-quota>
        </div>

        <v-form v-show="filteredProductCount || (!filteredProductCount && search)" class="pt-2 pb-2" @submit="filterProducts">
          <v-text-field
            label="Search products"
            solo
            hide-details
            append-icon="mdi-magnify"
            v-model="search"
          >
          </v-text-field>
        </v-form>

        <div class="d-flex align-center total-ctn">
          <v-spacer></v-spacer>
          <div v-if="!loadingProducts && products.length" class="search-label pt-2 mono">{{$t('products')}}: {{filteredProductCount}}</div>
        </div>

        <div class="product-card mb-2" v-show="!loadingProducts" v-for="product in products" :key="product.id">
          <v-row class="ma-0">
            <div class="product-picture flex-grow-0 pa-0" :style="{'background-image': 'url(' + getProductImage(product) + ')'}"></div>
            <div class="flex-grow-1 pa-0 pl-3">
              <div class="d-flex align-center mt-2">
                <span class="product-title font-weight-medium pr-3">{{product.name}}</span>
                <!-- <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" @click="editProduct(product)" x-small class="ma-1 ml-3" icon>
                      <v-icon color="#15bbf0">mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('edit_product')}}</span>
                </v-tooltip> -->
                <v-menu offset-y v-if="(product.elabel && product?.elabel?.is_published && getELabelStatus(product) !== 'inactive') || (!product?.elabel?.is_published && getELabelStatus(product) !== 'inactive' && getELabelStatus(product) !== null)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item class="pointer">
                      <v-list-item-title @click="editProduct(product)">{{$t('edit_product')}}</v-list-item-title>
                    </v-list-item>
                    <v-list-item  v-if="product.elabel && product?.elabel?.is_published && getELabelStatus(product) !== 'inactive'" class="pointer">
                      <v-list-item-title @click="unpublishElabel(product)">{{$t('disable_elabel')}}</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="product.elabel && !product?.elabel?.is_published && getELabelStatus(product) !== 'inactive'" class="pointer">
                      <v-list-item-title @click="publishElabel(product)">{{$t('enable_elabel')}}</v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item v-if="!product.code?.message" class="pointer">
                      <v-list-item-title @click="archiveProduct(product)">{{$t('archive_product')}}</v-list-item-title>
                    </v-list-item> -->
                  </v-list>
                </v-menu>
                <v-tooltip top v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" @click="editProduct(product)" x-small class="ma-1 ml-3" icon>
                      <v-icon color="#15bbf0">mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('edit_product')}}</span>
                </v-tooltip>

              </div>
              <div class="product-description">{{$t('product_sku')}}: {{product.sku}}</div>
              <div class="product-description" v-if="product.brand">{{$t('brand')}}: [{{product.brand.reference}}] {{product.brand.name}}</div>
              <!-- <div class="product-description">Product URL: {{product.client_url ? product.client_url : 'N/A'}}</div> -->
              <div v-if="isAvailable('qrmanager:elabel')" class="d-flex elabel-status align-center">
                <span>{{$t('elabel_status')}}:</span>
                <span class="status-pill mx-2" :class="`${getELabelStatus(product)}`"></span>
                <span v-if="getELabelStatus(product) === 'published'">{{$t('published')}}</span>
                <span v-else-if="getELabelStatus(product) === 'draft'">{{$t('draft')}}</span>
                <span v-else>{{$t('not_created')}}</span>
              </div>
            </div>
            <div class="d-flex align-center ml-auto">
              <v-btn v-if="isAvailable('qrmanager:elabel')" class="mr-3" @click="goToElabel(product)" raised>{{$t('edit_elabel_data')}}</v-btn>

              <div @click="manageCodes(product)" class="get-code-ctn pa-2">
                <div class="d-flex justify-center pt-4">
                  <v-icon color="black" class="mr-2">mdi-qrcode</v-icon>
                  <div class="code-count font-weight-light">x {{product.code_count ? product.code_count : 0}}</div>
                  <!-- <div class="d-flex align-center label font-weight-bold pl-4">Get QR codes <v-icon class="pl-2" color="primary">mdi-arrow-right</v-icon></div> -->
                </div>
                <v-btn class="mt-1" color="primary" small text>{{$t('get_qr_codes')}}</v-btn>
              </div>
            </div>
          </v-row>
        </div>
        <div v-if="loadingProducts" class="skeleton-ctn">
          <v-skeleton-loader width="100%" type="avatar, paragraph"></v-skeleton-loader>
        </div>
        <div class="empty-state-ctn" v-if="!loadingProducts && !products.length">
          <img class="guide-arrow" src="@/assets/guide_arrow.svg">
          <img class="product-cta" src="@/assets/product-cta.jpeg">
          <div class="py-4 font-weight-medium">
            {{$t('product_empty_state_1')}}<br>
            {{$t('product_empty_state_2')}}
          </div>
        </div>
        <div class="pagination-ctn mt-5 mb-4">
          <v-pagination v-show="!loadingProducts && filteredProductCount > 0" color="primary" :total-visible="7" v-model="page" :length="nbPages">
          </v-pagination>
        </div>
      </v-col>
    </v-row>
    <!--  -->
    <v-dialog v-model="productDialog" width="900" :persistent="true">
      <product-form
        :campaign="this.selectedCampaign.id"
        :product="this.$route.params && this.$route.params.product ? this.$route.params.product : {}"
        :product-id="this.$route.params && this.$route.params.id ? this.$route.params.id : null"
        @on-product-created="onProductCreated"
        @on-close-dialog="handleCloseDialog()">
      </product-form>
    </v-dialog>

    <v-dialog v-model="manageProductDialog" width="600">
      <v-card>
        <v-card-title class="headline">{{$t('product_create_update')}}</v-card-title>
        <div class="pt-4">
          <v-row class="ma-0 pa-0" align="center" justitfy="center">
            <v-col class="text-center">
              <v-btn @click="openCreateProductModal" fab>
                <v-icon color="primary">
                  mdi-plus
                </v-icon>
              </v-btn>
              <div  class="pt-4">{{$t('new_product')}}</div>
            </v-col>
            <v-col class="text-center">
              <product-csv @open-dialog="manageProductDialog = false" @upload-complete="loadProducts"></product-csv>
            </v-col>
          </v-row>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="manageProductDialog = false" color="primary" text>{{$t('close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="elabelUnpublishDialog" width="600">
      <v-card>
        <v-card-title class="headline">{{ $t('elabel_unpublish_title') }}</v-card-title>
        <div v-if="getRedirectionWebsite(productToUpdate)" class="pa-4">
          <span v-if="productToUpdate?.client_url">
            {{ $t('elabel_unpublish_description_product_url', { website: getRedirectionWebsite(productToUpdate) })}}<br><br>
          </span>
          <span v-else>
            {{ $t('elabel_unpublish_description', { website: getRedirectionWebsite(productToUpdate) })}}<br><br>
            <span v-html="$t('elabel_edit_website')"></span><br><br>
          </span>
          {{ $t('elabel_unpublish_confirm')}}
        </div>

        <div v-if="!getRedirectionWebsite(productToUpdate)" class="pa-4">
          {{ $t('elabel_unpublish_no_website')}}<br><br>
          <span v-html="$t('elabel_unpublish_no_website_2')"></span>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeElabelUnpublishDialog()" color="primary" text>{{$t('close')}}</v-btn>
          <v-btn v-if="getRedirectionWebsite(productToUpdate)" @click="unpublishElabel()" :loading="unpublishing" color="primary">{{$t('unpublish')}}</v-btn>
          <v-btn v-if="!getRedirectionWebsite(productToUpdate)" @click="goToProfile()" color="primary">{{$t('go_to_profile')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

  </div>
</template>

<script>

import ProductForm from '@/components/forms/Product'
import ProductCSV from '@/components/ProductCSV'
import CodesQuota from '@/components/CodesQuota'
import { mapGetters, mapMutations } from 'vuex'
import amplitude from 'amplitude-js'

export default {
  name: 'products',
  components: {
    'product-form': ProductForm,
    'product-csv': ProductCSV,
    CodesQuota
  },
  data () {
    return {
      manageProductDialog: false,
      // archivedDialog: false,
      // elabelUnpublishDialog: false,
      // productToUpdate: {},
      unpublishing: false,
      productDialog: false,
      pageSize: 10,
      page: 1,
      search: ''
    }
  },
  watch: {
    '$route.params' (newVal) {
      if (this.$route.name === 'products.edit' || this.$route.name === 'products.new') {
        this.productDialog = true
      }
    },

    $route (newVal, oldVal) {
      if (oldVal.name === 'products.elabel' && newVal.name === 'products' && oldVal.params.id) {
        this.loadProduct(oldVal.params.id)
      }
    },

    page (newVal) {
      this.loadProducts(newVal - 1)
    }
  },
  computed: {
    ...mapGetters(['campaigns', 'products', 'selectedCampaign', 'loadingProducts', 'loadingCampaigns', 'prefixesLoaded', 'brands', 'filteredProductCount', 'isAvailable', 'totalCodes', 'totalActiveCodesCount', 'inactiveCodesLoaded']),

    nbPages () {
      return Math.ceil(this.filteredProductCount / this.pageSize)
    }
  },
  methods: {
    ...mapMutations({
      setSelectedCampaign: 'SET_SELECTED_CAMPAIGN',
      setScmLoaded: 'SET_SCM_LOADED',
      setTotalActiveCount: 'SET_TOTAL_ACTIVE_CODES_COUNT'
    }),

    getELabelStatus (product) {
      if (!product || !product.elabel) {
        return 'null'
      } else if (product.elabel.is_published) {
        return 'published'
      } else {
        return 'draft'
      }
    },

    async publishElabel (product) {
      await this.$store.dispatch('publishElabel', { product: product })
    },

    async unpublishElabel (product) {
      this.unpublishing = true
      await this.$store.dispatch('unpublishElabel', { product: product })
      this.unpublishing = false
      this.closeElabelUnpublishDialog()
    },

    // openElabelUnpublishDialog (product) {
    //   this.productToUpdate = product
    //   this.elabelUnpublishDialog = true
    // },

    // closeElabelUnpublishDialog () {
    //   this.productToUpdate = null
    //   this.elabelUnpublishDialog = false
    // },

    // getRedirectionWebsite (product) {
    //   if (product?.client_url) {
    //     return product.client_url
    //   } else if (product?.brand?.client_url) {
    //     return product.brand.client_url
    //   } else if (this.profile?.company) {
    //     return this.profile.company.website
    //   }
    // },

    // goToProfile () {
    //   parent.postMessage({ type: 'redirection', data: '#/profile' }, '*')
    //   this.elabelUnpublishDialog = false
    // },

    goToElabel (product) {
      console.log('go To elabel', product)
      this.$router.push({ name: 'products.elabel', params: { id: product.id, product: product } })
    },

    filterProducts (event) {
      event.preventDefault()
      this.loadProducts()
    },

    openProductCSVModal () {
    },

    openCreateProductModal () {
      this.manageProductDialog = false
      this.$router.push({ name: 'products.new', params: { campaign: this.selectedCampaign.id } })
    },

    goToManageAll () {
      amplitude.getInstance().logEvent('click manage all qr codes')
    },

    manageCodes (product) {
      amplitude.getInstance().logEvent('click get qr codes button')
      this.$router.push({ name: 'products.codes', params: { id: product.id, product: product } })
    },

    async loadProduct (id) {
      await this.$store.dispatch('loadProduct', id)
      await this.$store.dispatch('loadProductCodeCount', { product: id })
    },

    async loadProducts (page) {
      await this.$store.dispatch('loadProducts', { campaign: this.selectedCampaign.id, offset: page ? page * this.pageSize : 0, limit: this.pageSize, search: this.search })

      if (this.products && this.products.length) {
        this.products.forEach((product) => {
          this.$store.dispatch('loadProductCodeCount', { product: product.id })
        })
      }
    },

    async onProductCreated (product) {
      if (this.totalCodes - this.totalActiveCodesCount) {
        await this.$store.dispatch('activateAndAssignCode', { product: product })
        this.setTotalActiveCount(parseInt(this.totalActiveCodesCount) + 1)
      }
    },

    editProduct (product) {
      this.$router.push({ name: 'products.edit', params: { id: product.id, product: product } })
    },

    handleCloseDialog () {
      console.log('handle close?')
      this.productDialog = false
      this.$router.push({ name: 'products' })
    },

    getProductImage (product) {
      return product.image ? product.image : product.brand && product.brand.image ? product.brand.image : require('@/assets/product-placeholder.png')
    },

    selectCampaign (campaign) {
      amplitude.getInstance().logEvent('select campaign')
      this.setSelectedCampaign(campaign)
      this.setScmLoaded(false)
      this.loadProducts()
      this.$store.dispatch('loadSelectedCampaignConsumerOptions', campaign.id)
    }
  },
  async created () {
    if (!this.campaigns.length) {
      console.log('Load campaign in product tab')
      const campaign = await this.$store.dispatch('loadCampaigns')
      this.selectCampaign(campaign)
    } else {
      this.selectCampaign(this.campaigns[0])
    }

    if (!this.prefixesLoaded) {
      this.$store.dispatch('loadPrefixes')
    }

    if (!this.inactiveCodesLoaded) {
      this.$store.dispatch('loadInactiveCodes')
    }

    if (!this.brands.length) {
      console.log('load brands')
      this.$store.dispatch('loadBrands')
    }

    if (this.$route.name === 'products.edit' || this.$route.name === 'products.new') {
      this.productDialog = true
    }
  }
}

</script>

<style lang="sass">

@import "@/variables.sass"

.pointer
  cursor: pointer

.elabel-status
  .status-pill
    display: inline-block
    width: 16px
    height: 16px
    margin-right: 4px
    border-radius: 50%
    background-color: #D9D9D9
    &.draft
      background-color: #F5A623
    &.published
      background-color: #3FC135

.empty-state-ctn
  padding-left: 200px
  position: relative
  .guide-arrow
    position: absolute
    left: 50px
    margin-left: auto
    margin-right: auto

.products
  margin-right: auto
  @include xl
    margin-left: 10%

  .link
    cursor: pointer
    color: $primary

  .product-cta
    width: 350px

.total-ctn
  padding-bottom: 15px
  .search-label
    font-size: 16px
    color: #222222
    opacity: 0.5
    text-transform: uppercase

.sidebar-btns
  width: 100%
  position: absolute
  bottom: 10px

.campaign-item
  cursor: pointer
  &.selected
    background-color: rgba($primary, 0.3) !important

.edit-title
  color: #333333
  font-weight: bold
  font-size: 17px

.product-picture
  min-width: 100px
  min-height: 100px
  background-size: contain
  background-position: center

.product-title
  font-size: 16px
  letter-spacing: 0.15px
  line-height: 1.5
  color: rgba($black, 0.87)

.product-description
  font-size: 14px
  color: rgba($black, 0.6)

.product-list
  padding-top: 14px
  padding-left: 25px
  overflow-y: auto
  // height: calc(100vh - 63px)
  height: 100vh
  @include xl
    padding-right: 10%

.product-card
  margin-bottom: 2px
  background: white
  .code-count
    font-size: 20px

  .get-code-ctn
    cursor: pointer
    text-align: left
    min-width: 100px
    height: 100%
    background: #F3F3F3
    text-align: center
    .label
      font-size: 13px
      text-transform: uppercase
      color: $primary

.campaign-skeleton-ctn
  .v-skeleton-loader__list-item
    width: 100%
    background-color: rgba($primary, 0.3) !important
    border-radius: 0px

.campaign-list
  position: relative
  padding: 0px
  border-right: 1px solid rgba($black, 0.3)
  min-width: 230px
  padding-top: 15px
  // height: calc(100vh - 63px)
  height: 100vh
  overflow: auto

.v-skeleton-loader
  display: flex
  max-width: 800px

.v-skeleton-loader__paragraph
  background: white
  .v-skeleton-loader__text
    max-width: 100% !important
    &:nth-child(1)
      width: 20%
      height: 16px
      margin-bottom: 20px
    &:nth-child(2)
      width: 80%
    &:nth-child(3)
      width: 60%

.v-skeleton-loader__avatar
  width: 100px !important
  height: 100px !important
  border-radius: 0% !important

.v-skeleton-loader__paragraph
  padding: 10px

</style>
