var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "scan-by-time-card",
    attrs: {
      "height": "300px"
    }
  }, [_c('card-label', {
    attrs: {
      "color": "00aeef",
      "content": "scan by hour"
    }
  }), _c('apexchart', {
    attrs: {
      "width": "100%",
      "height": "270px",
      "type": "heatmap",
      "options": _vm.options,
      "series": _vm.series
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }