export default {
  debounce: (callback, wait, immediate = false) => {
    let timeout = null

    return function () {
      const callNow = immediate && !timeout
      const next = () => callback.apply(this, arguments)

      clearTimeout(timeout)
      timeout = setTimeout(next, wait)
      if (callNow) {
        next()
      }
    }
  },

  formatDate: (string, short) => {
    let date
    if (!string) {
      date = new Date()
    } else {
      date = new Date(string)
    }

    const minutes = date.getMinutes() <= 9 ? '0' + date.getMinutes() : date.getMinutes()
    const days = date.getDate() <= 9 ? '0' + date.getDate() : date.getDate()
    const months = (date.getMonth() + 1) <= 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1

    if (short) {
      return `${date.getFullYear()}-${months}-${days}`
    } else {
      return `${date.getFullYear()}-${months}-${days} ${date.getHours()}:${minutes}`
    }
  },

  deepCompare: function (a, b) {
    if (a === b) return true
    if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime()
    if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) return a === b
    if (a === null || a === undefined || b === null || b === undefined) return false
    if (a.prototype !== b.prototype) return false
    const keys = Object.keys(a)
    if (keys.length !== Object.keys(b).length) return false
    return keys.every((k) => {
      if (a[k] !== undefined && b[k] !== undefined) {
        return this.deepCompare(JSON.parse(JSON.stringify(a[k])), JSON.parse(JSON.stringify(b[k])))
      } else {
        return false
      }
    })
  },

  isObject: function (item) {
    return (item && typeof item === 'object' && !Array.isArray(item))
  },

  mergeDeep: function (target, ...sources) {
    if (!sources.length) return target
    const source = sources.shift()

    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) Object.assign(target, { [key]: {} })
          this.mergeDeep(target[key], source[key])
        } else {
          if ((source[key] && source[key].length) || typeof source[key] === 'boolean' || typeof source[key] === 'number') {
            Object.assign(target, { [key]: source[key] })
          } else {
            Object.assign(target, { [key]: target[key] })
          }
        }
      }
    }

    return this.mergeDeep(target, ...sources)
  },

  CODE_TYPE: {
    GS1: 'gs1',
    DEFAULT: 'default'
  },

  isAlphaNumeric: (value) => {
    return /^[a-zA-Z0-9]+$/.test(value)
  },

  generateLotNumber: () => {
    const regexPattern = /^[a-zA-Z0-9]+$/
    let lotNumber = ''

    while (lotNumber.length < 6) {
      const randChar = String.fromCharCode(Math.floor(Math.random() * 123))
      if (regexPattern.test(randChar)) lotNumber += randChar
    }

    return lotNumber
  },

  AI: {
    GTIN: '01',
    LOT_BATCH_NUMBER: '10',
    CPV: '22'
  },

  isGS1Identifier: (message) => {
    const regex = /\//

    return message && regex.test(message)
  },

  excludedTagRegEx: /^(scantrust_.*|cpv|lot|gtin|gs1_.*)$/

}
