var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "codes"
  }, [_c('div', {
    staticClass: "d-flex align-center pt-5"
  }, [_c('router-link', {
    attrs: {
      "to": "/products"
    }
  }, [_c('v-btn', {
    staticClass: "ml-3",
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-arrow-left")])], 1)], 1), _c('div', {
    staticClass: "page-title mono pl-3"
  }, [_vm._v(_vm._s(_vm.$t('codes_all_qrs')))]), _c('codes-csv', {
    ref: "csv-add-codes",
    staticClass: "ml-4",
    on: {
      "codes-activated": _vm.resetBulkSelection
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-center pt-5 mx-8"
  }, [_c('codes-search', {
    ref: "codeSearch",
    staticClass: "flex-grow-1 mr-4",
    attrs: {
      "campaign": _vm.selectedCampaign.id
    }
  }), _c('download', {
    attrs: {
      "mode": "codes",
      "count": _vm.selectAllEnabled ? _vm.filteredCodeCount : _vm.selectedCodesIndexes.length,
      "select-all": _vm.selectAllEnabled,
      "codes": _vm.selectedCodes,
      "campaign": _vm.selectedCampaign.id,
      "product": _vm.selectedCodes.length === 1 ? _vm.selectedCodes[0].product.id : undefined,
      "enable-qr-text-settings": _vm.selectedCodes.length === 1 ? true : false
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.filteredProductCount,
      expression: "filteredProductCount"
    }],
    staticClass: "d-flex align-center justify-space-between search-settings mx-8"
  }, [_c('div', {
    staticClass: "d-flex align-center flex-wrap"
  }, [_c('v-checkbox', {
    attrs: {
      "hide-details": "",
      "value": _vm.selectAllEnabled
    },
    on: {
      "click": function ($event) {
        return _vm.toggleSelectAll();
      }
    }
  }), _c('div', {
    staticClass: "search-label mr-4"
  }, [_vm._v(_vm._s(_vm.$t('codes_selected')) + ": " + _vm._s(_vm.selectAllEnabled ? _vm.filteredCodeCount : _vm.selectedCodesIndexes.length))]), _vm.activeCodesFilters.length ? _c('div', {
    staticClass: "search-label mr-4"
  }, [_vm._v(_vm._s(_vm.$t('codes_results')) + ": " + _vm._s(_vm.filteredCodeCount))]) : _vm._e(), _c('div', {
    staticClass: "search-label mr-2"
  }, [_vm._v(_vm._s(_vm.$t('filters')) + ":")]), !_vm.activeCodesFilters.length ? _c('div', {
    staticClass: "search-label"
  }, [_vm._v(_vm._s(_vm.$t('none')))]) : _vm._e(), _vm._l(_vm.activeCodesFilters, function (filter, index) {
    return _c('v-chip', {
      key: `active-filters-${index}`,
      staticClass: "ml-2",
      attrs: {
        "close": "",
        "color": "#f1f1f1",
        "text-color": "#0789b2"
      },
      on: {
        "click": function ($event) {
          return _vm.editActiveFilter(filter);
        },
        "click:close": function ($event) {
          return _vm.removeFilter(filter);
        }
      }
    }, [_vm._v(" " + _vm._s(filter.name) + ": " + _vm._s(filter.value) + " ")]);
  })], 2), _c('v-spacer'), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.codesLoaded,
      expression: "codesLoaded"
    }],
    staticClass: "search-label mr-7 flex-shrink-0 mono"
  }, [_vm._v(_vm._s(_vm.$t('total')) + ": " + _vm._s(_vm.filteredCodeCount))]), _c('button', {
    staticClass: "d-flex show-url-btn",
    on: {
      "click": function ($event) {
        _vm.showUrl = !_vm.showUrl;
      }
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.visibilityImgUrl
    }
  }), _vm.showUrl ? _c('span', [_vm._v(_vm._s(_vm.$t('hide_url_prefixes')))]) : _vm._e(), !_vm.showUrl ? _c('span', [_vm._v(_vm._s(_vm.$t('show_url_prefixes')))]) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "code-list-ctn mx-8"
  }, [_c('v-progress-linear', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.codesLoaded,
      expression: "!codesLoaded"
    }],
    attrs: {
      "indeterminate": ""
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.codesLoaded,
      expression: "codesLoaded"
    }]
  }, _vm._l(_vm.codes, function (code, codeIndex) {
    return _c('div', {
      key: code.id,
      staticClass: "code-line py-1 d-flex align-center",
      class: {
        'selected': _vm.selectedCodesIndexes.indexOf(codeIndex) >= 0
      }
    }, [_c('div', {
      staticClass: "d-flex flex-wrap align-center"
    }, [_c('v-checkbox', {
      attrs: {
        "hide-details": "",
        "color": "#15bbf0",
        "value": _vm.selectedCodesIndexes.indexOf(codeIndex) >= 0
      },
      on: {
        "click": function ($event) {
          return _vm.toggleSelectCode(codeIndex);
        }
      }
    }), _c('div', {
      staticClass: "mono"
    }, [_vm._v(_vm._s(_vm.showUrl ? code.scm_data.scantrust_url_prefix ? code.scm_data.scantrust_url_prefix : _vm.systemPrefix : '') + _vm._s(code.message) + " /")]), _c('div', {
      staticClass: "url product-name pl-2",
      on: {
        "click": function ($event) {
          return _vm.addProductFilter(code.product);
        }
      }
    }, [_vm._v(" " + _vm._s(code.product.name))]), _vm._l(_vm.filterScmData(code.scm_data), function (value, name) {
      return _c('v-chip', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: value,
          expression: "value"
        }],
        key: `code-${code.id}-tags-${name}-${value}`,
        staticClass: "ml-2",
        attrs: {
          "color": "#f1f1f1",
          "text-color": "#0789b2"
        },
        on: {
          "click": function ($event) {
            return _vm.addFilter(name, value);
          }
        }
      }, [_vm._v(" " + _vm._s(name === 'scantrust_upload_batch' ? _vm.$t('upload_batch') : name.startsWith('gs1_') ? name.split('gs1_')[1] : name) + ": " + _vm._s(value) + " ")]);
    })], 2), _c('v-spacer'), _c('div', {
      staticClass: "d-flex flex-shrink-0 align-center"
    }, [_c('div', {
      staticClass: "date mono mr-2"
    }, [_vm._v(_vm._s(code.activated_at ? _vm.formatDate(code.activated_at) : _vm.formatDate(code.creation_date)))]), _c('code-tag-editor', {
      attrs: {
        "tags": _vm.scmFields,
        "codes": [code],
        "campaign": _vm.selectedCampaign.id
      }
    }), _c('code-change-product', {
      attrs: {
        "codes": [code]
      },
      on: {
        "code-transfered": _vm.loadCodes
      }
    })], 1)], 1);
  }), 0), _vm.codesLoaded && !_vm.codes.length ? _c('div', {
    staticClass: "pa-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('codes_not_found')) + " ")]) : _vm._e(), _c('div', {
    staticClass: "pagination-ctn mt-5 mb-4"
  }, [_c('v-pagination', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.codesLoaded && _vm.codes.length,
      expression: "codesLoaded && codes.length"
    }],
    staticClass: "flex-grow-0",
    attrs: {
      "color": "primary",
      "total-visible": 7,
      "length": _vm.nbPages
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }