var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-tooltip', {
    attrs: {
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "color": "primary",
            "icon": ""
          },
          on: {
            "click": _vm.openDialog
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("$gs1-edit-icon")])], 1)];
      }
    }])
  }, [_vm._v(" " + _vm._s(_vm.$t('qrcode_edit_gs1_link')) + " ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }