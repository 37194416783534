<template>
  <div class="rule-filters">
    <div class="mb-6 d-flex align-center justify-space-between">
      <span class="font-weight-bold">Filters</span>
      <v-btn @click="clearFilters" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <v-autocomplete
      v-model="ruleActiveFilters.campaigns"
      chips
      multiple
      deletable-chips
      :items="allCampaigns"
      :search-input.sync="search"
      item-text="name"
      item-value="id"
      hide-details
      outlined
      persistent-placeholder
      label="Campaigns"
    ></v-autocomplete>

    <v-select
      dense
      outlined
      class="my-4"
      hide-details
      v-model="ruleActiveFilters.is_paused"
      label="Status"
      :items="statusDropdown"
      item-text="description"
      item-value="key"
    >
    </v-select>

    <v-select
      dense
      outlined
      class="my-4"
      hide-details
      label="Archived"
      v-model="ruleActiveFilters.is_archived"
      :items="yesNoDropdown"
      item-text="description"
      item-value="key"
    >
    </v-select>
  </div>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex'
import Utils from '@/utils/utils'

export default {
  name: 'RuleFilters',
  computed: {
    ...mapGetters(['campaigns']),

    allCampaigns () {
      return [{ id: null, name: 'All Campaigns' }].concat(this.campaigns)
    },

    ruleActiveFilters: {
      get () {
        return this.$store.getters.ruleActiveFilters
      },
      set (value) {
        this.setFilters(value)
      }
    }
  },
  watch: {
    'ruleActiveFilters.campaigns' (newVal, oldVal) {
      this.search = ''
      const allIndex = newVal.indexOf(null)
      if (!newVal.length || (~allIndex && newVal.length > 1 && !~oldVal.indexOf(null))) {
        this.ruleActiveFilters.campaigns = [null]
      } else if (~allIndex && newVal.length > 1) {
        this.ruleActiveFilters.campaigns.splice(allIndex, 1)
      }
    },

    ruleActiveFilters: {
      deep: true,
      handler () {
        this.debounceSearch()
      }
    }
  },
  data () {
    return {
      search: '',
      debounceSearch: null,
      yesNoDropdown: [
        { key: null, description: 'All' },
        { key: false, description: 'No' },
        { key: true, description: 'Yes' }
      ],
      // paused
      // all
      statusDropdown: [
        { key: null, description: 'All' },
        { key: false, description: 'Active' },
        { key: true, description: 'Paused' }
      ]
    }
  },
  methods: {

    ...mapMutations({
      clearFilters: 'CLEAR_ACTIVE_RULES_FILTERS',
      setFilters: 'SET_ACTIVE_RULES_FILTERS'
    }),

    searchRules () {
      this.$store.dispatch('loadRedirectionRules')
    }

  },
  created () {
    this.debounceSearch = Utils.debounce(() => {
      this.searchRules()
    }, 400, true)
  }
}

</script>

<style lang="sass" scoped>

</style>
