import { default as Axios } from '../utils/axios.instances' // eslint-disable-line

const axiosInstance = Axios.getSTAxios() // eslint-disable-line

export default {

  async findCode (id) {
    return new Promise((resolve, reject) => {
      axiosInstance.get('/codes/', { params: { extended_id: id } }).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async loadCodes (options) {
    const params = {
      limit: options.limit ? options.limit : 20,
      offset: options.offset ? options.offset : 0,
      is_sid: true
    }

    if (options.filters) {
      options.filters.forEach((filter) => {
        params[filter.searchKey] = filter.searchValue
      })
    }

    if (options.activation_status >= 0) {
      params.status = options.activation_status
    }

    if (options.campaign) {
      params.campaign = options.campaign
    }

    if (options.product) {
      params.products = options.product
    }

    if (options.no_product) {
      params.no_product = options.no_product
    }

    return new Promise((resolve, reject) => {
      axiosInstance.get('/codes/', { params: params, cancelToken: options.cancelToken ? options.cancelToken.token : null }).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async activateCode (options) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch(`/codes/${options.code.id}/activate/`).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async scmUpload ({ key, items }) {
    return new Promise((resolve, reject) => {
      axiosInstance.post('/scm/upload/', { data_key: key, items: items }).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async scmUploadAsync (params) {
    return new Promise((resolve, reject) => {
      axiosInstance.post('/scm/upload/async/', params).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async updateScmValues (options) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch(`/scm/set/code/${options.code.message}/`, { scm_data: options.tags }).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async createScmField (options) {
    return new Promise((resolve, reject) => {
      axiosInstance.post('/scm/fields/', options).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async updateScmField (options) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch(`/scm/fields/${options.id}`, options).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async loadQrTemplates () {
    return new Promise((resolve, reject) => {
      axiosInstance.get('/qr-templates/').then((res) => {
        resolve(res.data.results)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async createQrTemplate (template) {
    return new Promise((resolve, reject) => {
      template.quiet_zone = 1
      template.scale = template.scale || 10
      template.error_correction = 'Q'
      if (template.image && template.image.indexOf('data') === 0) {
        template.image = template.image.split(',')[1]
      } else if (!template.image) {
        delete template.image
      }
      axiosInstance.post('/qr-templates/', template).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async deleteQrTemplate (id) {
    return new Promise((resolve, reject) => {
      axiosInstance.delete(`/qr-templates/${id}/`).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async updateQrTemplate (id, template) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch(`/qr-templates/${id}/`, template).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  async renameCode (params) {
    return new Promise((resolve, reject) => {
      axiosInstance.post('/scm/run/code/rename/', params).then((res) => {
        resolve(res.data)
      }).catch((err) => {
        reject(err)
      })
    })
  }

}
