import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(VCard, {
    staticClass: "no-radius"
  }, [_c(VCardText, {
    staticClass: "pa-2 ma-0"
  }, [_c('div', {
    staticClass: "mt-3 mb-5"
  }, [_c('div', {
    staticClass: "section-title"
  }, [_c(VIcon, {
    staticClass: "icon flex-grow-0"
  }, [_vm._v("$bullseye")]), _c('span', [_vm._v("Active for campaigns:")])], 1), _c('div', [!_vm.rule.campaigns.length ? _c('div', [_vm._v("All Campaigns")]) : _vm._e(), _vm._l(_vm.rule.campaigns, function (campaign, index) {
    var _vm$getCampaign, _vm$getCampaign2;
    return _c('span', {
      key: campaign,
      class: {
        'text-red': _vm.redirectionSimulator && !_vm.hasMatchingCampaign(_vm.rule)
      }
    }, [_vm._v(" " + _vm._s((_vm$getCampaign = _vm.getCampaign(campaign)) !== null && _vm$getCampaign !== void 0 && _vm$getCampaign.name ? (_vm$getCampaign2 = _vm.getCampaign(campaign)) === null || _vm$getCampaign2 === void 0 ? void 0 : _vm$getCampaign2.name : "Archived Campaign (ID ".concat(campaign, ")")) + _vm._s(index < _vm.rule.campaigns.length - 1 ? ', ' : '') + " ")]);
  })], 2)]), _c(VDivider), _c('div', {
    staticClass: "my-3"
  }, [_c('div', {
    staticClass: "section-title"
  }, [_c(VIcon, {
    staticClass: "icon flex-grow-0"
  }, [_vm._v("$calendar")]), _c('span', [_vm._v("Active period:")])], 1), _c('div', {
    class: {
      'text-red': _vm.redirectionSimulator && _vm.getRuleStatus(_vm.rule) !== 'live'
    }
  }, [_vm.rule.active_from && _vm.rule.active_to ? _c('span', [_vm._v(" Start: " + _vm._s(_vm.showLocalTime(_vm.rule.active_from))), _c('br'), _vm._v("End: " + _vm._s(_vm.showLocalTime(_vm.rule.active_to)) + " ")]) : _vm.rule.active_from ? _c('span', [_vm._v(" Active since " + _vm._s(_vm.showLocalTime(_vm.rule.active_from)) + ". ")]) : _vm.rule.active_to && _vm.getRuleStatus(_vm.rule) == 'expiring' ? _c('span', [_vm._v(" Expiring on " + _vm._s(_vm.showLocalTime(_vm.rule.active_from)) + ". ")]) : _vm.rule.active_to && (_vm.getRuleStatus(_vm.rule) == 'expired' || _vm.status == 'paused') ? _c('span', [_vm._v(" Expired on " + _vm._s(_vm.showLocalTime(_vm.rule.active_from)) + ". ")]) : _c('span', [_vm._v(" Active all the time. ")]), _vm.rule.active_from ? _c('div', [_vm._v(" Timezone: " + _vm._s(_vm.getTimezone(_vm.rule.timezone).label) + " ")]) : _vm._e()])]), _c(VDivider), _c('div', {
    staticClass: "destinations-ctn my-3"
  }, [_c('div', {
    staticClass: "section-title"
  }, [_vm._v("Destinations:")]), !_vm.destinations || !_vm.destinations.length ? _c('div', {
    staticClass: "call-to-action"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("Action required:")]), _c('div', {
    staticClass: "description"
  }, [_vm._v(" A functioning rule must define at least 1 Scan Destination. You can add multiple destinations here for the scans that meet with different conditions. ")]), _c(VBtn, {
    staticClass: "my-2",
    attrs: {
      "color": "primary",
      "text": "",
      "large": ""
    },
    on: {
      "click": function click($event) {
        return _vm.newDestination();
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-plus-circle-outline")]), _vm._v("Add Destination")], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "destination-list"
  }, [_c('draggable', {
    staticClass: "drop-area",
    attrs: {
      "group": "a"
    },
    model: {
      value: _vm.destinations,
      callback: function callback($$v) {
        _vm.destinations = $$v;
      },
      expression: "destinations"
    }
  }, _vm._l(_vm.destinations, function (destination) {
    return _c('div', {
      key: destination.id
    }, [_c('destination-card', {
      staticClass: "my-4",
      attrs: {
        "selected": _vm.isSelected(destination.id),
        "rule": _vm.rule,
        "destination": destination,
        "is-only-destination": _vm.destinations && _vm.destinations.length == 1
      }
    })], 1);
  }), 0), _vm.destinations && _vm.destinations.length ? _c(VBtn, {
    staticClass: "my-4",
    attrs: {
      "color": "primary",
      "text": "",
      "large": ""
    },
    on: {
      "click": function click($event) {
        return _vm.newDestination();
      }
    }
  }, [_c(VIcon, {
    staticClass: "mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-plus-circle-outline")]), _vm._v("Add Destination")], 1) : _vm._e()], 1)])], 1), _c(VDialog, {
    attrs: {
      "width": "900",
      "scrollable": "",
      "persistent": true
    },
    model: {
      value: _vm.destinationDialog,
      callback: function callback($$v) {
        _vm.destinationDialog = $$v;
      },
      expression: "destinationDialog"
    }
  }, [_c('destination-logic', {
    attrs: {
      "rule": _vm.rule,
      "destination": {}
    },
    on: {
      "cancel": _vm.onDestinationCancel,
      "destination-created": _vm.onDestinationCreated
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }