var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app-bar', {
    attrs: {
      "absolute": "",
      "color": "#ffffff"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "stretch",
      "justify": "space-between"
    }
  }, [_c('v-img', {
    staticClass: "ml-5",
    attrs: {
      "src": require('../assets/st-logo.svg'),
      "max-width": "170px",
      "contain": ""
    }
  }), _vm.currentPlan ? _c('v-row', {
    staticClass: "justify-end align-center ma-0"
  }, [_c('nav', [_c('ul', {
    staticClass: "nav-list font-weight-medium"
  }, [_c('li', {
    staticClass: "nav-item",
    class: {
      'active': this.active == 'DASHBOARD'
    },
    on: {
      "click": function ($event) {
        return _vm.sendNavigationEvent('dashboard');
      }
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('navigation_dashboard')) + " ")])], 1), _c('li', {
    staticClass: "nav-item",
    class: {
      'active': this.active == 'PRODUCTS'
    },
    on: {
      "click": function ($event) {
        return _vm.sendNavigationEvent('products');
      }
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/products"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('navigation_products')) + " ")])], 1), _c('li', {
    staticClass: "nav-item",
    class: {
      'active': this.active == 'LANDING-PAGE'
    },
    on: {
      "click": function ($event) {
        return _vm.sendNavigationEvent('landing-page');
      }
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/landing-page"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('navigation_landing_page')) + " ")])], 1), _c('li', {
    staticClass: "nav-item",
    class: {
      'active': this.active == 'UPGRADE'
    },
    on: {
      "click": function ($event) {
        return _vm.sendNavigationEvent('upgrade');
      }
    }
  }, [_c('router-link', {
    attrs: {
      "to": "/upgrade"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('navigation_upgrade')) + " ")])], 1)])]), _c('v-menu', {
    attrs: {
      "full-width": "",
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('div', {
          staticClass: "account-icon pr-2",
          class: {
            'pr-5': _vm.currentPlan.id == 'epac-connect-premium'
          }
        }, [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-account-circle")])], 1), _c('div', {
          staticClass: "plan-pill"
        }, [_vm.currentPlan.id == 'epac-connect' ? _c('v-img', {
          attrs: {
            "src": require('../assets/free.svg')
          }
        }) : _vm._e(), _vm.currentPlan.id == 'epac-connect-plus' ? _c('v-img', {
          attrs: {
            "src": require('../assets/plus.svg')
          }
        }) : _vm._e(), _vm.currentPlan.id == 'epac-connect-premium' ? _c('v-img', {
          attrs: {
            "src": require('../assets/premium.svg')
          }
        }) : _vm._e()], 1)], 1)];
      }
    }], null, false, 3084360993)
  }, [_c('v-list', [_c('v-list-item', {
    staticClass: "menu-link"
  }, [_c('router-link', {
    attrs: {
      "to": "/upgrade"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold mr-5 navigation-menu-label uppercase"
  }, [_vm._v(_vm._s(_vm.$t('current_plan')) + ":")]), _c('br'), _c('v-row', {
    staticClass: "ma-0",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "flex-grow-0 pa-0 mr-3"
  }, [_vm.currentPlan.id == 'epac-connect' ? _c('v-img', {
    staticClass: "pill",
    attrs: {
      "src": require('../assets/free.svg')
    }
  }) : _vm._e(), _vm.currentPlan.id == 'epac-connect-plus' ? _c('v-img', {
    staticClass: "pill",
    attrs: {
      "src": require('../assets/plus.svg')
    }
  }) : _vm._e(), _vm.currentPlan.id == 'epac-connect-premium' ? _c('v-img', {
    staticClass: "pill",
    attrs: {
      "src": require('../assets/premium.svg')
    }
  }) : _vm._e()], 1), _c('v-col', {
    staticClass: "pa-0"
  }, [_vm._v(" " + _vm._s(_vm.planName) + " ")])], 1)], 1)], 1), _c('v-list-item', {
    on: {
      "click": _vm.logout
    }
  }, [_c('v-icon', {
    staticClass: "mr-3"
  }, [_vm._v("mdi-logout")]), _vm._v(" " + _vm._s(_vm.$t('logout')) + " ")], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }